import React, { Component } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { UserOutlined  } from '@ant-design/icons';

import { getLogoWithTitle } from "./../common/uiUtils";

import i18n from "./../common/i18n";

class ForgotPasswordDialog extends Component {
    constructor(props) {
        super(props);
        this.state = this.getEmptyState();
    }

    getEmptyState() {
        return {
            login: "",
            error: "",
            errorField: "",
        };
    }

    updateState(key, value) {
        if (key === this.state.errorField) {
            this.setState({ ...this.state, [key]: value, errorField: "", error: "" });
        } else {
            this.setState({ ...this.state, [key]: value });
        }
    }

    onOk() {
        let login = this.state.login.trim();
        if (login === "") {
            this.setState({
                ...this.state,
                error: i18n.t("login.errors.loginEmpty"),
                errorField: "login",
            });
            return;
        }

        this.props.onResetButtonClick(login);

        this.setState(this.getEmptyState());
    }

    onCancel() {
        this.props.onCancelButtonClick();
        this.setState(this.getEmptyState());
    }

    render() {
        return (
            <div>
                <Modal
                    title={getLogoWithTitle(i18n.t("forgotPassword.title"))}
                    visible={this.props.isVisible}
                    onOk={() => {
                        this.onOk();
                    }}
                    onCancel={() => {
                        this.onCancel();
                    }}
                    okText={i18n.t("common.okLabel")}
                    cancelText={i18n.t("common.cancelLabel")}
                    maskClosable={false}
                    width={360}
                >
                    <Form layout="vertical">
                        <Form.Item style={{ padding: 10 }}>{i18n.t("forgotPassword.fields.loginLabel")}</Form.Item>
                        <Form.Item
                            validateStatus={this.state.errorField === "login" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "login"}
                            help={this.state.errorField === "login" ? this.state.error : ""}
                        >
                            <Input
                                value={this.state.login}
                                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("login", event.target.value);
                                }}
                                placeholder={i18n.t("login.fields.loginPlaceholder")}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default ForgotPasswordDialog;
