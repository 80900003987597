import React, { Component } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Alert from "antd/lib/alert";
import Checkbox from "antd/lib/checkbox";
import FacebookLogin from "react-facebook-login";
import { UserOutlined, LockOutlined  } from '@ant-design/icons';

import { getLogoWithTitle } from "./../common/uiUtils";
import { hashPassword } from "./../common/utils";

import i18n from "./../common/i18n";

class LoginDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            remember: false,
        };
    }

    updateState(key, value) {
        if (key === this.state.errorField) {
            this.setState({ [key]: value, errorField: "", error: "" });
        } else {
            this.setState({ [key]: value });
        }
    }

    onOk() {
        let login = this.state.login.trim();
        let password = this.state.password.trim();

        if (login === "") {
            this.setState({
                login: "",
                password: password,
                error: i18n.t("login.errors.loginEmpty"),
                errorField: "login",
            });
            return;
        }

        if (password === "") {
            this.setState({
                login: login,
                password: "",
                error: i18n.t("login.errors.passwordEmpty"),
                errorField: "password",
            });
            return;
        }

        this.props.onLogin({
            login: login,
            password: hashPassword(password),
            remember: this.state.remember,
        });

        this.setState({ ...this.state, login: login, password: "" });
    }

    onCancel() {
        this.props.onCancelButtonClick();

        this.setState({
            login: "",
            password: "",
            remember: false,
        });
    }

    onSuccessFacebookLogin = (response) => {
        //console.log(response);

        this.props.onLogin({
            login: response.email,
            password: response.signedRequest,
            authProvider: "facebook",
            remember: this.state.remember,
        });
    }

    onFailureFacebookLogin = (response) => {
        console.log(response);

        this.setState({
            error: i18n.t("login.errors.facebookFailed"),
            errorField: "",
        });
    }

    render() {
        let error;
        if (this.props.error !== "" || (this.state.error !== "" && this.state.errorField === "")) {
            error = (
                <>
                    <Alert message={this.props.error || this.state.error} type="error" />
                    <br />
                </>
            );
        }

        return (
            <>
                <Modal
                    title={getLogoWithTitle(i18n.t("login.title"))}
                    visible={this.props.isVisible}
                    onOk={() => {
                        this.onOk();
                    }}
                    okText={i18n.t("common.loginLabel")}
                    cancelText={i18n.t("common.cancelLabel")}
                    confirmLoading={this.props.isLoading}
                    onCancel={() => {
                        this.onCancel();
                    }}
                    maskClosable={false}
                    width={360}
                >
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "32px" }}>
                        <FacebookLogin
                            appId="590214278275013"
                            fields="name,email"
                            scope="public_profile"
                            size="small"
                            callback={this.onSuccessFacebookLogin}
                            onFailure={this.onFailureFacebookLogin}
                        />
                    </div>
                    {error}
                    <Form layout="vertical">
                        <Form.Item
                            validateStatus={this.state.errorField === "login" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "login"}
                            help={this.state.errorField === "login" ? this.state.error : ""}
                        >
                            <Input
                                value={this.state.login}
                                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("login", event.target.value);
                                }}
                                placeholder={i18n.t("login.fields.loginPlaceholder")}
                                onPressEnter={() => this.passwordInput && this.passwordInput.focus()}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={this.state.errorField === "password" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "password"}
                            help={this.state.errorField === "password" ? this.state.error : ""}
                        >
                            <Input
                                type="password"
                                value={this.state.password}
                                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("password", event.target.value);
                                }}
                                placeholder={i18n.t("login.fields.passwordPlaceholder")}
                                onPressEnter={() => {
                                    this.onOk();
                                }}
                                ref={(ele) => (this.passwordInput = ele)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                                checked={this.state.remember}
                                onChange={(event) => {
                                    this.updateState("remember", event.target.checked);
                                }}
                            >
                                {i18n.t("login.fields.rememberMeLabel")}
                            </Checkbox>
                            <a
                                style={{ float: "right", paddingRight: 10 }}
                                onClick={() => this.props.onForgotPasswordButtonClick()}
                                href="#forgot"
                            >
                                {i18n.t("login.fields.forgotPasswordLabel")}
                            </a>
                        </Form.Item>
                        <Form.Item>
                            {i18n.t("login.fields.registrationStartText")}
                            <a onClick={() => this.props.onRegistrationButtonClick()} href="#registration">
                                {i18n.t("login.fields.registration")}
                            </a>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default LoginDialog;
