import React, { Component } from "react";
import Spin from "antd/lib/spin";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"; //TODO: review import

import i18n from "./../common/i18n";

class Chart extends Component {
    render() {
        let content = i18n.t("common.noData");
        let leftYAxisName = this.props.isBtcBased ? "btc" : "usd";
        let leftYAxis = (
            <Bar
                yAxisId="left"
                dataKey={leftYAxisName}
                name={i18n.t("menu." + leftYAxisName)}
                fill="rgba(85, 96, 123, 0.5)"
                maxBarSize={100}
            />
        );
        if (this.props.data.length > 0) {
            content = (
                <BarChart
                    width={this.props.width}
                    height={this.props.height}
                    data={this.props.data}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="rgba(85, 96, 123, 0.7)" />
                    <YAxis yAxisId="right" orientation="right" stroke="rgba(54, 162, 235, 1)" />
                    <CartesianGrid stroke="rgba(85, 96, 123, 0.10)" />
                    <Tooltip />
                    <Legend />
                    {leftYAxis}
                    <Bar
                        yAxisId="right"
                        dataKey="percentage"
                        name={i18n.t("menu.percentage")}
                        fill="rgba(54, 162, 235, 0.7)"
                        maxBarSize={100}
                    />
                </BarChart>
            );
        }

        return (
            <Spin spinning={this.props.isLoading} size="large">
                {content}
            </Spin>
        );
    }
}

export default Chart;
