import axios from "axios";

import {
    calculatePrice,
} from "./dataUtils";

import {
    prepareDepositPrice,
    prepareDepositsData,
} from "./../dataUtils";

import { BACKEND_URL } from "./../../common/settings";

export const loadDeposits = (userId) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/depositHistory/bittrex", {
            params: { userId: userId },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.success) {
                let deposits = response.data.result.map((item) => ({
                    coin: item.Currency,
                    datasource: "bittrex",
                    amount: item.Amount,
                    timestamp: item.LastUpdated,
                }));

                let depositsData = prepareDepositsData(getState().account.deposits, deposits, true);

                dispatch({
                    type: "LOAD_DEPOSITS_SUCCEEDED",
                    data: depositsData,
                });

                return dispatch(getDepositsPrices(response.data.result, dispatch));
            } else {
                console.log(response.data.message);
                return Promise.reject(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const loadWithdrawals = (userId) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/withdrawalHistory/bittrex", {
            params: { userId: userId },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.success) {
                let withdrawals = response.data.result.map((item) => ({
                    coin: item.Currency,
                    datasource: "bittrex",
                    amount: item.Amount,
                    timestamp: item.LastUpdated,
                }));

                let withdrawalsData = prepareDepositsData(getState().account.deposits, withdrawals, false);

                dispatch({
                    type: "LOAD_WITHDRAWALS_SUCCEEDED",
                    data: withdrawalsData,
                });

                return dispatch(getDepositsPrices(response.data.result, dispatch));
            } else {
                console.log(response.data.message);
                return Promise.reject(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

const getDepositsPrices = (data) => (dispatch) => {
    let syncArr = [];
    for (let item of data) {
        syncArr.push(dispatch(getDepositPrice(item.Currency, "USDT", "USDT-BTC", item.Amount, item.LastUpdated)));

        if (item.Currency !== "BTC" && item.Currency !== "USDT") {
            syncArr.push(
                dispatch(getDepositPrice(item.Currency, "BTC", "BTC-" + item.Currency, item.Amount, item.LastUpdated))
            );
        }
    }
    return Promise.all(syncArr);
};

const getDepositPrice = (coin, coinPair, symbol, quantity, timestamp) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/price/history/bittrex/" + symbol, {
            params: {
                symbol: symbol,
                timestamp: timestamp,
            },
        })
        .then((response) => {
            if (response.data.success) {
                if (response.data.result !== undefined) {
                    //console.log(coin, coinPair, symbol, timestamp, response.data.result);

                    let price = calculatePrice(response.data.result);

                    let updatedPrices = prepareDepositPrice(
                        "bittrex",
                        coin,
                        coinPair,
                        symbol,
                        quantity,
                        timestamp,
                        price,
                        getState().account.depositPrices
                    );

                    dispatch({
                        type: "LOAD_DEPOSIT_PRICE_SUCCEEDED",
                        data: updatedPrices,
                    });
                }
            } else {
                console.log(response.data.message);
                return Promise.reject(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const updateBtcUsdPrice = () => (dispatch) => {
    return axios
        .get(BACKEND_URL + "/prices/BTC-USD")
        .then((response) => {
            //console.log(response.data);

            if (response.data.success) {
                dispatch({
                    type: "UPDATE_BTCUSD_PRICE_SUCCEEDED",
                    price: response.data.result.bpi.USD.rate_float,
                });
            } else {
                console.log(response.data.message);
                return Promise.reject(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
