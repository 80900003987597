import React, { Component } from "react";
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { getContextForPluralNumbers, formatCustomCoin, formatBtc, formatUsd } from "./../common/utils";
import { getCoinLogo } from "./../common/uiUtils";
import { DATASOURCE_NAMES_STYLED } from "./../common/constants";
import i18n from "./../common/i18n";

import CoinsTable from "./CoinsTable";

const BTC_COLUMN_NAMES = new Set(["depositsInBtcFormatted", "withdrawalsInBtcFormatted", "diffInBtcFormatted"]);

const USD_COLUMN_NAMES = new Set(["depositsInUsdFormatted", "withdrawalsInUsdFormatted", "diffInUsdFormatted"]);

class InAndOutTable extends Component {
    generateColumnsMetadata() {
        return [
            {
                title: i18n.t("deposits.table.headers.coin"),
                dataIndex: "coin",
                render: (text, record, index) => {
                    let additionalInfo = "";

                    if (record.isSubItem) {
                        let tradeDateTime = new Date(record.timestamp);
                        let localTradeDate = tradeDateTime.toLocaleDateString(i18n.t("locale"));
                        let localTradeTime = tradeDateTime.toLocaleTimeString(i18n.t("locale"));
                        additionalInfo = (
                            <div className="coinCellAdditionalInfo">
                                {localTradeDate}
                                <br />
                                {localTradeTime}
                            </div>
                        );
                    } else if (record.children != null) {
                        additionalInfo = (
                            <div className="coinCellAdditionalInfo">
                                {record.children.length}
                                &nbsp;
                                {i18n.t("deposits.table.numOperationsLabel", {
                                    context: getContextForPluralNumbers(record.children.length),
                                })}
                            </div>
                        );
                    }

                    return (
                        <span style={{ fontSize: record.isSubItem ? 14 : 16, color: "darkblue" }}>
                            {getCoinLogo(text)}
                            {text}
                            {additionalInfo}
                        </span>
                    );
                },
            },
            {
                title: i18n.t("deposits.table.headers.datasource"),
                dataIndex: "datasource",
                render: (text, record, index) => {
                    if (record.isSubItem) {
                        let icon = "";
                        if (record.isDeposit) {
                            icon = (
                                <PlusCircleOutlined style={{ fontSize: 14, fontWeight: 900, color: "green" }} />
                            );
                        } else {
                            icon = (
                                <MinusCircleOutlined style={{ fontSize: 14, fontWeight: 900, color: "red" }} />
                            );
                        }

                        let label = i18n.t(
                            record.isDeposit ? "deposits.table.depositLabel" : "deposits.table.withdrawalLabel"
                        );

                        return (
                            <span style={{ fontSize: 12 }}>
                                {icon} {label}
                            </span>
                        );
                    }
                    return DATASOURCE_NAMES_STYLED[record.datasource];
                },
            },
            {
                title: i18n.t("deposits.table.headers.deposits"),
                dataIndex: "depositsFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.withdrawals"),
                dataIndex: "withdrawalsFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.diff"),
                dataIndex: "diffFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.depositsInBtc"),
                dataIndex: "depositsInBtcFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.withdrawalsInBtc"),
                dataIndex: "withdrawalsInBtcFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.diffInBtc"),
                dataIndex: "diffInBtcFormatted",
                render: (text, record, index) => <div className="boldText mediumBlueText">{text}</div>,
            },
            {
                title: i18n.t("deposits.table.headers.depositsInUsd"),
                dataIndex: "depositsInUsdFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.withdrawalsInUsd"),
                dataIndex: "withdrawalsInUsdFormatted",
                render: (text, record, index) => <span style={{ fontSize: record.isSubItem ? 12 : 14 }}>{text}</span>,
            },
            {
                title: i18n.t("deposits.table.headers.diffInUsd"),
                dataIndex: "diffInUsdFormatted",
                render: (text, record, index) => <div className="boldText mediumBlueText">{text}</div>,
            },
        ];
    }

    formatData() {
        if (this.props.data == null) {
            return [];
        }

        return this.props.data.map((item) =>
            Object.assign(item, {
                depositsFormatted: item.deposits == null ? null : formatCustomCoin(item.deposits, item.coin),
                withdrawalsFormatted: item.withdrawals == null ? null : formatCustomCoin(item.withdrawals, item.coin),
                diffFormatted: item.diff == null ? null : formatCustomCoin(item.diff, item.coin),
                depositsInBtcFormatted: item.depositsInBtc == null ? null : formatBtc(item.depositsInBtc),
                withdrawalsInBtcFormatted: item.withdrawalsInBtc == null ? null : formatBtc(item.withdrawalsInBtc),
                diffInBtcFormatted: item.diffInBtc == null ? null : formatBtc(item.diffInBtc),
                depositsInUsdFormatted: item.depositsInUsd == null ? null : formatUsd(item.depositsInUsd),
                withdrawalsInUsdFormatted: item.withdrawalsInUsd == null ? null : formatUsd(item.withdrawalsInUsd),
                diffInUsdFormatted: item.diffInUsd == null ? null : formatUsd(item.diffInUsd),
                children:
                    item.inAndOuts == null || item.inAndOuts.length === 0
                        ? null
                        : item.inAndOuts.map((subItem) =>
                              Object.assign(subItem, {
                                  coin: item.coin,
                                  isSubItem: true,
                                  isDeposit: subItem.isDeposit,
                                  depositsFormatted: subItem.isDeposit
                                      ? formatCustomCoin(subItem.quantity, item.coin)
                                      : "",
                                  withdrawalsFormatted: subItem.isDeposit
                                      ? ""
                                      : formatCustomCoin(subItem.quantity, item.coin),
                                  depositsInBtcFormatted: subItem.isDeposit ? formatBtc(subItem.priceInBtc) : "",
                                  withdrawalsInBtcFormatted: subItem.isDeposit ? "" : formatBtc(subItem.priceInBtc),
                                  depositsInUsdFormatted: subItem.isDeposit ? formatUsd(subItem.priceInUsd) : "",
                                  withdrawalsInUsdFormatted: subItem.isDeposit ? "" : formatUsd(subItem.priceInUsd),
                              })
                          ),
            })
        );
    }

    render() {
        return (
            <CoinsTable
                columns={this.generateColumnsMetadata()}
                btcColumnNames={BTC_COLUMN_NAMES}
                usdColumnNames={USD_COLUMN_NAMES}
                data={this.formatData()}
                datasources={this.props.datasources}
                isBtcBased={this.props.isBtcBased}
                isLoading={this.props.isLoading}
            />
        );
    }
}

export default InAndOutTable;
