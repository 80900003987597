import React, { Component } from "react";
import Table from "antd/lib/table";
import Spin from "antd/lib/spin";

import {
    formatBtc,
    formatBtcWithSign,
    formatBtcLocale,
    formatUsd,
    formatUsdWithSign,
    formatUsdLocale,
    formatPercent,
    formatPercentWithSign,
} from "./../common/utils";
import { get2Prices, getPercent, get2PricesWithPercent, getCoinLogo } from "./../common/uiUtils";
import { DATASOURCE_NAMES_STYLED_MINI, PROFIT_COLORS } from "./../common/constants";
import i18n from "./../common/i18n";

class TopTenTable extends Component {
    getColumnsMetadata() {
        return [
            {
                title: i18n.t("portfolio.table.headers.coin"),
                dataIndex: "coin",
                render: (text, record, index) => {
                    let additionalData = "";

                    if (record.datasource !== undefined) {
                        additionalData = DATASOURCE_NAMES_STYLED_MINI[record.datasource](
                            i18n.t("overview.table.multiple")
                        );
                    }

                    if (record.rank !== undefined) {
                        additionalData = <span style={{ fontSize: 10 }}># {record.rank}</span>;
                    }

                    return (
                        <div>
                            <div style={{ fontSize: 16, color: "darkblue" }}>
                                {getCoinLogo(text)}
                                {text}
                            </div>
                            {additionalData}
                        </div>
                    );
                },
            },
            {
                title: i18n.t("portfolio.table.headers.valueInBtc"),
                dataIndex: "valueInBtcFormatted",
                render: (text, record, index) => get2Prices(record.valueInBtcFormatted, record.valueInUsdFormatted),
            },
            {
                title: i18n.t("portfolio.table.headers.valueInUsd"),
                dataIndex: "valueInUsdFormatted",
                render: (text, record, index) => get2Prices(record.valueInUsdFormatted, record.valueInBtcFormatted),
            },
            {
                title: i18n.t("portfolio.table.headers.averageBuyPrice"),
                dataIndex: "averageBuyPriceFormatted",
                render: (text, record, index) => (
                    <span style={{ fontSize: 14 }}>{record.averageBuyPriceFormatted}</span>
                ),
            },
            {
                title: i18n.t("portfolio.table.headers.averageBuyPriceInUsd"),
                dataIndex: "averageBuyPriceInUsdFormatted",
                render: (text, record, index) => (
                    <span style={{ fontSize: 14 }}>{record.averageBuyPriceInUsdFormatted}</span>
                ),
            },
            {
                title: i18n.t("overview.table.headers.volume"),
                dataIndex: "volumeFormatted",
            },
            {
                title: i18n.t("overview.table.headers.volume"),
                dataIndex: "volumeInUsdFormatted",
            },
            {
                title: i18n.t("portfolio.table.headers.currentPrice"),
                dataIndex: "currentPriceFormatted",
                render: (text, record, index) => {
                    return get2PricesWithPercent(
                        record.currentPriceFormatted,
                        record.currentPriceInUsdFormatted,
                        getPercent(record.profit24h, record.profit24hFormatted)
                    );
                },
            },
            {
                title: i18n.t("portfolio.table.headers.currentPriceInUsd"),
                dataIndex: "currentPriceInUsdFormatted",
                render: (text, record, index) => {
                    return get2PricesWithPercent(
                        record.currentPriceInUsdFormatted,
                        record.currentPriceFormatted,
                        getPercent(record.profit24hForUsd, record.profit24hForUsdFormatted)
                    );
                },
            },
            {
                title: i18n.t("portfolio.table.headers.profit"),
                dataIndex: "profitFormatted",
                render: (text, record, index) => {
                    if (record.profit === undefined) {
                        return;
                    }

                    let color = PROFIT_COLORS.profit;
                    if (record.profit < 0) {
                        color = PROFIT_COLORS.loss;
                    }

                    //TODO: why it is calculated here?
                    let absoluteProfitInBtc = record.valueInBtc - record.averageBuyPrice * record.totalBalance;
                    let absoluteProfitInBtcFormatted = formatBtcWithSign(absoluteProfitInBtc);

                    return (
                        <div style={{ color: color, fontWeight: "bold" }}>
                            {text}
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>({absoluteProfitInBtcFormatted})</div>
                        </div>
                    );
                },
            },
            {
                title: i18n.t("portfolio.table.headers.profitForUsd"),
                dataIndex: "profitForUsdFormatted",
                render: (text, record, index) => {
                    if (record.profitForUsd === undefined) {
                        return;
                    }

                    let color = PROFIT_COLORS.profit;
                    if (record.profitForUsd < 0) {
                        color = PROFIT_COLORS.loss;
                    }

                    //TODO: why it is calculated here?
                    let absoluteProfitInUsd = record.valueInUsd - record.averageBuyPriceInUsd * record.totalBalance;
                    let absoluteProfitInUsdFormatted = formatUsdWithSign(absoluteProfitInUsd);

                    return (
                        <div style={{ color: color, fontWeight: "bold" }}>
                            {text}
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>({absoluteProfitInUsdFormatted})</div>
                        </div>
                    );
                },
            },
            {
                title: i18n.t("portfolio.table.headers.percentage"),
                dataIndex: "percentageFormatted",
                render: (text, record, index) => (
                    <div className="mediumBlueText" style={{ fontWeight: "bold" }}>
                        {text}
                    </div>
                ),
            },
            {
                title: i18n.t("overview.table.headers.marketCap"),
                dataIndex: "marketCapFormatted",
            },
            {
                title: i18n.t("overview.table.headers.marketCap"),
                dataIndex: "marketCapInUsdFormatted",
            },
        ];
    }

    getFilteredColumns() {
        return this.getColumnsMetadata().filter((item) => {
            if (!this.props.columns.has(item.dataIndex)) {
                return false;
            }

            if (
                this.props.isBtcBased &&
                (item.dataIndex === "averageBuyPriceInUsdFormatted" ||
                    item.dataIndex === "profitForUsdFormatted" ||
                    item.dataIndex === "valueInUsdFormatted" ||
                    item.dataIndex === "currentPriceInUsdFormatted" ||
                    item.dataIndex === "marketCapInUsdFormatted" ||
                    item.dataIndex === "volumeInUsdFormatted")
            ) {
                return false;
            }

            if (
                !this.props.isBtcBased &&
                (item.dataIndex === "averageBuyPriceFormatted" ||
                    item.dataIndex === "profitFormatted" ||
                    item.dataIndex === "valueInBtcFormatted" ||
                    item.dataIndex === "currentPriceFormatted" ||
                    item.dataIndex === "marketCapFormatted" ||
                    item.dataIndex === "volumeFormatted")
            ) {
                return false;
            }

            return true;
        });
    }

    getFormattedData() {
        if (this.props.data == null) {
            return this.props.data;
        }

        let formattedData = [];

        if (this.props.isBtcBased) {
            formattedData = this.props.data.map((item) => ({
                ...item,
                isTrade: false,
                valueInBtcFormatted: item.valueInBtc == null ? null : formatBtc(item.valueInBtc),
                valueInUsdFormatted: item.valueInUsd == null ? null : formatUsd(item.valueInUsd),
                averageBuyPriceFormatted: item.averageBuyPrice == null ? null : formatBtc(item.averageBuyPrice),
                currentPriceFormatted: item.currentPrice == null ? null : formatBtc(item.currentPrice),
                currentPriceInUsdFormatted: item.currentPriceInUsd == null ? null : formatUsd(item.currentPriceInUsd),
                profitFormatted: item.profit == null ? null : formatPercentWithSign(item.profit),
                profit24hFormatted: item.profit24h == null ? null : formatPercentWithSign(item.profit24h),
                percentageFormatted: item.percentage == null ? null : formatPercent(item.percentage),
                marketCapFormatted: item.marketCap == null ? null : formatBtcLocale(item.marketCap),
                volumeFormatted: item.volume == null ? null : formatBtcLocale(item.volume),
                children: null,
            }));
        } else {
            formattedData = this.props.data.map((item) => ({
                ...item,
                isTrade: false,
                valueInBtcFormatted: item.valueInBtc == null ? null : formatBtc(item.valueInBtc),
                valueInUsdFormatted: item.valueInUsd == null ? null : formatUsd(item.valueInUsd),
                averageBuyPriceInUsdFormatted:
                    item.averageBuyPriceInUsd == null ? null : formatUsd(item.averageBuyPriceInUsd),
                currentPriceFormatted: item.currentPrice == null ? null : formatBtc(item.currentPrice),
                currentPriceInUsdFormatted: item.currentPriceInUsd == null ? null : formatUsd(item.currentPriceInUsd),
                profitForUsdFormatted: item.profitForUsd == null ? null : formatPercentWithSign(item.profitForUsd),
                profit24hForUsdFormatted:
                    item.profit24hForUsd == null ? null : formatPercentWithSign(item.profit24hForUsd),
                percentageFormatted: item.percentage == null ? null : formatPercent(item.percentage),
                marketCapInUsdFormatted: item.marketCapInUsd == null ? null : formatUsdLocale(item.marketCapInUsd),
                volumeInUsdFormatted: item.volumeInUsd == null ? null : formatUsdLocale(item.volumeInUsd),
                children: null,
            }));
        }

        return formattedData;
    }

    render() {
        return (
            <Spin spinning={this.props.isLoading} size="large">
                <Table
                    className="top10Table"
                    rowKey="uid"
                    columns={this.getFilteredColumns()}
                    dataSource={this.getFormattedData()}
                    size="small"
                    pagination={false}
                    locale={{
                        filterReset: i18n.t("common.resetLabel"),
                        emptyText: i18n.t("common.noData"),
                    }}
                />
            </Spin>
        );
    }
}

export default TopTenTable;
