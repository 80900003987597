import React from "react";

export const FAQ_RUSSIAN = {
    GENERAL: (
        <div>
            <h3>Общие вопросы по продукту</h3>
            <br />
            <h4>Зачем мне CryptoACE?</h4>
            <p>Для начала проведем мини-опрос:</p>
            <ul>
                <li>Знаете ли Вы о существовании криптовалют?</li>
                <li>Есть ли у Вас аккаунт на одной или нескольких криптобиржах?</li>
                <li>Хотите ли Вы видеть информацию по портфелю по каждой бирже?</li>
            </ul>
            <p>Если Вы на все вопросы ответили утвердительно, тогда милости просим!</p>
            <br />
            <h4>Чем вы можете мне помочь?</h4>
            <p>CryptoACE однозначно будет интересен Вам по следующим факторам:</p>
            <ul>
                <li>
                    Вам НЕ НУЖНО вводить все балансы, сделки, вводы/выводы в/из криптобирж вручную. Это все загружается
                    автоматически.
                </li>
                <li>Вы можете отслеживать всю информацию по любой из Ваших монет в разрезе каждой криптобиржи.</li>
                <li>У Вас есть возможность просматривать все вводы/выводы монет по каждой из криптобирж.</li>
                <li>Мы постарались сделать наш сервис максимально удобным в использовании.</li>
                <li>Мы постоянно совершенствуем продукт, добавляя новые возможности.</li>
            </ul>
            <p>И, кроме того, целый месяц бесплатного использования! Попробуйте, вы не разочаруетесь.</p>
            <br />
            <h4>Как работает синхронизация?</h4>
            <p>
                Для того, чтобы получить информацию о сделках на Вашей криптобирже, нам нужен доступ. Для получения
                доступа используются API ключи. Такие ключи Вы можете создать на любой криптобирже.
                <br />В разделе "Настройка API ключей" для Вашего удобства приведены инструкции для создания этих ключей
                на разных криптобиржах и дальнейшей настройке в CryptoACE.
                <br />
                Стоит отметить, что для работы с нашим приложением, мы запрашиваем ключи с доступом только на “чтение”.
                Имея такие ключи, мы не можем совершить какие-либо действия в Вашей учетной записи, поэтому Ваши монеты
                в безопасности.
            </p>
            <br />
            <h4>Могу ли я изменить язык интерфейса и установить его по умолчанию?</h4>
            <p>
                Нажмите на значок учетной записи и выберите пункт меню “Настройки”. Вкладка “Персонализация” позволяет
                Вам установить язык интерфейса и валюту по умолчанию. Выберите желаемые опции и нажмите на кнопку
                “Сохранить изменения”.{" "}
            </p>
            <br />
            <h4>Я хочу сменить пароль. Что мне делать?</h4>
            <ol>
                <li>Нажмите на значок учетной записи и выберите пункт меню “Настройки”.</li>
                <li>Перейдите на вкладку “Изменить пароль”. Здесь вы можете изменить свой пароль.</li>
                <li>Нажмите на кнопку “Изменить пароль” и укажите свой текущий и новый пароль дважды.</li>
                <li>Если вся информация указана корректно, то все сохранение пройдет успешно.</li>
            </ol>
            <br />
            <h4>Как происходит обновление информации?</h4>
            <p>
                Вся информация обновляется по Вашему требованию. Для этого нужно перейти на нужную страницу, выбрать все
                интересующие Вас криптобиржи в специальном поле и нажать на кнопку “Загрузить данные”.{" "}
            </p>
            <br />
            <h4>Откуда вы берете данные по ценам?</h4>
            <p>
                Курс Биткоина к Доллару США мы получаем с криптобиржи Bittrex. Все цены по сделкам мы получаем с
                криптобиржи, на которой эта сделка была совершена в момент ее заключения.{" "}
            </p>
            <br />
            <h4>Почему есть разница в цене Биткоина между ценой в CryptoACE и на моей криптобирже? </h4>
            <p>
                К сожалению или к счастью, существует различия в цене Биткоина среди всех криптобирж. Отчасти, причиной
                тому является разный уровень ликвидности и риска, обусловленный разным уровнем спроса и предложения на
                разных криптобиржах. Проще говоря, нам нужно показывать одно значение цены. Мы выбрали криптобиржу
                Bittrex, как достаточно давно существующую и надежную. В будущем мы планируем предоставить
                дополнительную опцию с выбором криптобиржи для отображения этой котировки.
            </p>
            <br />
            <h4>Есть ли мобильное приложение CryptoACE?</h4>
            <p>
                Пока нет. Это есть у нас в планах. Мы понимаем насколько это необходимо в свете современного образа
                жизни. Но мы так спешили выпустить первую версию в web и тщательно ее шлифовали, что мобильная версия
                отложена до следующего этапа. Следите за нашими новостями.
            </p>
            <br />
            <h4>Почему у вас нет XYZ возможности?</h4>
            <p>
                Да просто потому, что либо она в разработке, либо мы не знаем, что Вы этого хотите!
                <br />
                Пишите нам на <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>! Мы рады любым отзывам. Хорошим,
                конечно, больше :)
            </p>
        </div>
    ),
    SETUP: (
        <div>
            <h3>Настройка API ключей</h3>
            <br />
            <h4>Добавить новый источник данных (API ключ криптобиржи)</h4>
            <ol>
                <li>Перейдите в меню “Настройки”.</li>
                <li>Чтобы добавить новый источник данных, нажмите кнопку “Добавить”. </li>
                <li>Появится окно “Добавить источник данных”.</li>
                <li>Выберите нужную биржу, укажите API и Секретный ключ. Нажмите кнопку “ОК”.</li>
                <li>
                    Если вся информация указана корректно, то форма закроется и новая биржа будет добавлена в список.
                </li>
                <li>
                    После этого Вы можете пройти на страницу Обзор, Портфель или Ввод/Вывод. Нажмите на кнопку
                    “Загрузить все данные” и наслаждаться данными статистики.
                </li>
            </ol>
            <b>Binance:</b>
            <br />
            Где мне получить эти ключи?
            <ol>
                <li>
                    Перейдите на страницу{" "}
                    <a href="https://www.binance.com/userCenter/createApi.html" rel="external">
                        {" "}
                        Binance API{" "}
                    </a>
                </li>
                <li>
                    Введите название для набора ключей в поле, находящееся в верху страницы и нажмите “Создать Новый
                    Ключ”.
                </li>
                <li>Следуйте инструкциям Binance для создания ключей.</li>
                <li>После того, как набор ключей создан, обратите внимание, что “Read Info” отмечен.</li>
                <li>Скопируйте API key и Secret в буфер обмена.</li>
                <li>Вернитесь в CryptoACE и вставьте их в соответствующие поля.</li>
            </ol>
            <b>Bittrex:</b>
            <br />
            Где мне получить эти ключи?
            <ol>
                <li>
                    Перейдите на страницу{" "}
                    <a href="https://bittrex.com/Manage#sectionApi" rel="external">
                        {" "}
                        Bittrex API{" "}
                    </a>
                </li>
                <li>Нажмите на кнопку “Add New Key”.</li>
                <li>Отметьте флажок “Read Info” для нового ключа.</li>
                <li>Если необходимо, пройдите двухфакторную идентификацию.</li>
                <li>Нажмите на кнопку “Update Keys”.</li>
                <li>Скопируйте Key и Secret ключ.</li>
                <li>Вернитесь в CryptoACE и вставьте их в соответствующие поля.</li>
            </ol>
            <b>KuCoin:</b>
            <br />
            Где мне получить эти ключи?
            <ol>
                <li>
                    Перейдите на страницу{" "}
                    <a href="https://www.kucoin.com/#/user/setting/api" rel="external">
                        {" "}
                        Kucoin API{" "}
                    </a>
                </li>
                <li>Нажмите на кнопку “Create”.</li>
                <li>Скопируйте Key и Secret ключ.</li>
                <li>Вернитесь в CryptoACE и вставьте их в соответствующие поля.</li>
            </ol>
            <br />
            <h4>Редактировать источник данных</h4>
            <ol>
                <li>Для того, чтобы отредактировать запись с источником данных, нажмите на кнопку “Редактировать”.</li>
                <li>Введите необходимые изменения и нажмите на кнопку “ОК”.</li>
                <li>Если вся информация указана корректно, то все данные будут сохранены и окно закроется.</li>
            </ol>
            <br />
            <h4>Удалить источник данных</h4>
            <p>Для того, чтобы удалить источник данных, нажмите на кнопку “Удалить” рядом с нужной записью.</p>
        </div>
    ),
    PRIVACY: (
        <div>
            <h3>Приватность и безопасность</h3>
            <br />
            <h4>Как насчет безопасности моих финансовых данных?</h4>
            <p>Согласны, безопасность прежде всего. А все вот почему:</p>
            <ul>
                <li>У нас нет доступа к Вашим криптобиржам. Все потому, что ключи с доступом ТОЛЬКО на “чтение”.</li>
                <li>Соответственно, мы не можем продать или передать Ваши данные третьим лицам.</li>
                <li>Наш веб-сайт использует защищенный SSL протокол (https).</li>
                <li>
                    Мы используем фильтры для предупреждения SQL-инъекций и проверяем все запросы, которые меняют данные
                    для предотвращения CSRF атак.
                </li>
                <li>Мы не храним пароли у себя в базе данных в чистом виде. Мы их хэшируем специальной функцией.</li>
                <li>
                    Вы можете удалить всю информацию в своем аккаунте в любое время и по любой причине. При этом
                    удаляется вся Ваша информация безвозвратно.
                </li>
            </ul>
            <br />
            <h4>Это скам? Вы собираетесь украсть мои криптоденьги? </h4>
            <p>
                Нет. CryptoACE нужны ключи только с доступом на “чтение”, поэтому у нас нет доступа ни к торговле, ни к
                выводу Ваших криптоденег.
            </p>
            <br />
            <h4>Как мне удалить свою учетную запись?</h4>
            <p>
                Вы можете удалить свою учетную запись, зайдя в Настройки. Выберите пункт “Настройки безопасности” и
                нажмите на кнопку “Удалить учетную запись”.
            </p>
        </div>
    ),
    PRICING: (
        <div>
            <h3>Стоимость использования продукта</h3>
            <br />
            <h4>Это бесплатно?</h4>
            <p>
                Сейчас продукт находится в стадии открытого бета-тестирования и его можно использовать абсолютно
                бесплатно.
                <br />
                Также Вы можете поддержать разработчиков (детали в разделе "Поддержать проект").
            </p>
        </div>
    ),
    FEEDBACK: (
        <div>
            <h3>Обратная связь</h3>
            <br />
            <h4>Что-то не работает.</h4>
            <p>
                Нам очень интересно что именно. Пожалуйста, напишите нам на{" "}
                <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>.
            </p>
            <br />
            <h4>Мне не хватает кое-чего в продукте.</h4>
            <p>
                Срочно пишите чего именно на <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>. Мы открыты для
                предложений.
            </p>
            <br />
            <h4>Ваш сервис просто находка! Я хочу об этом всем рассказать.</h4>
            <p>
                Отлично! Вы можете это сделать у нас на странице в <a href="https://fb.me/cryptoace.stats">Facebook</a>,{" "}
                <a href="https://twitter.com/cryptoace_stats">Twitter</a>,{" "}
                <a href="https://vk.com/cryptoace">Vkontakte</a>.<br />
                Также вы можете добавить к записи наш хэштег <b>#cryptoacestats</b>.
            </p>
            <br />
            <h4>У меня все равно есть вопрос. Что мне делать?</h4>
            <p>
                Пишите нам на <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>.
            </p>
        </div>
    ),
    DONATE: (
        <div>
            <h3>Поддержать проект</h3>
            <br />
            <h4>Мне нравится пользоваться CryptoACE и бы хотел Вам помочь с разработкой. Как я могу это сделать?</h4>
            <p>
                Нам очень приятно это слышать. Все добровольные пожертвования можно смело переводить на:
                <br />
                <br />
                <b>Bitcoin (BTC) адрес:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>1Ak8FcfHXLDjqMj3T6SmxfRe49BrnUt84i</h3>
                <br />
                <b>Ethereum (ETH) адрес:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>0x883596978ffe5af007ac725cf7a176e6dfb4a3e1</h3>
                <br />
                <b>Litecoin (LTC) адрес:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>Lc21cZ8ePtDYs7cmGf3TqT5WKTHTfrYUU3</h3>
                <br />
                <b>Ethereum Classic (ETC) адрес:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>0x883596978ffe5af007ac725cf7a176e6dfb4a3e1</h3>
            </p>
        </div>
    ),
};
