// @flow
import { sha256 } from "js-sha256";

export const getContextForPluralNumbers = (number: number): string => {
    if (number === undefined || number === null) {
        return "invalid";
    }

    if (number === 0) {
        return "zero";
    }

    if (number === 1) {
        return "one";
    }

    if (number > 1 && number < 5) {
        return "few";
    }

    if (number >= 5) {
        return "many";
    }

    return "negative";
};

export const hashPassword = (password: string): string => {
    return sha256(password).toUpperCase();
};

//const EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const EMAIL_VALIDATION_PATTERN = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const isEmailValid = (email: string): boolean => {
    return EMAIL_VALIDATION_PATTERN.test(email);
};

export const USERNAME_VALIDATION_PATTERN = /^[a-zA-Z0-9.\-_$@*!]{3,30}$/;

export const isUsernameValid = (username: string): boolean => {
    return USERNAME_VALIDATION_PATTERN.test(username);
};

export const PASSWORD_MEDIUM_VALIDATION_PATTERN = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);

export const isPasswordMediumValid = (username: string): boolean => {
    return PASSWORD_MEDIUM_VALIDATION_PATTERN.test(username);
};

/*export const PASSWORD_STRONG_VALIDATION_PATTERN = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

export const isPasswordStrongValid = (username) => {
    return PASSWORD_STRONG_VALIDATION_PATTERN.test(username);
};*/

/**
 * Convert a `Map` to a standard
 * JS object recursively.
 *
 * @param {Map} map to convert.
 * @returns {Object} converted object.
 */
export const mapToObject = (map: Map<any, any>): { isMap: true } => {
    const result = Object.create({ isMap: true });

    map.forEach((value, key) => {
        if (value.constructor === Map) {
            result[key] = mapToObject(value);
        } else {
            result[key] = value;
        }
    });

    return result;
};

export const objectToMap = (obj: { isMap?: boolean }): Map<any, any> => {
    let result = new Map<any, any>();

    Object.keys(obj).forEach((key) => {
        if (key !== "isMap") {
            if ("isMap" in obj[key]) {
                result.set(key, objectToMap(obj[key]));
            } else {
                result.set(key, obj[key]);
            }
        }
    });

    return result;
};

export const formatCustomCoin = (amount: number, coin: string): string => {
    return parseFloat(amount.toFixed(8)) + " " + coin;
};

export const formatBtc = (amount: number): string => {
    return amount.toFixed(8) + " BTC";
};

export const formatBtcWithSign = (amount: number): string => {
    return (amount > 0 ? "+" : "") + amount.toFixed(8) + " BTC";
};

export const formatBtcLocale = (amount: number): string => {
    return amount.toLocaleString() + " BTC";
};

export const formatUsd = (amount: number): string => {
    return amount.toFixed(2) + " USD";
};

export const formatUsdWithSign = (amount: number): string => {
    return (amount > 0 ? "+" : "") + amount.toFixed(2) + " USD";
};

export const formatUsdLocale = (amount: number): string => {
    return amount.toLocaleString() + " USD";
};

export const formatPercent = (amount: number): string => {
    return amount.toFixed(2) + "%";
};

export const formatPercentWithSign = (amount: number): string => {
    return (amount > 0 ? "+" : "") + amount.toFixed(2) + "%";
};
