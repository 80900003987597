// @flow
import React, { PureComponent } from "react";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import { SearchOutlined } from '@ant-design/icons';

import { withTranslation } from "react-i18next";

import { getDatasourcesFilter } from "./../common/uiUtils";
import { getRowsFilteredByCoin } from "./../common/tableUtils";

import type { CoinsTableColumnMetadata, CoinsTableData, Datasource } from "./../common/types";

type Props = {
    columns: Array<CoinsTableColumnMetadata>,
    btcColumnNames: Set<string>,
    usdColumnNames: Set<string>,
    data: Array<CoinsTableData>,
    datasources: Array<Datasource>,
    isBtcBased: boolean,
    isLoading: boolean,
    t: Function,
};

type State = {
    filterDropdownVisible: boolean,
    searchText: string,
    filtered: boolean,
    lastFilteredText: string,
};

class CoinsTable extends PureComponent<Props, State> {
    searchInput: ?HTMLInputElement;

    constructor(props: Props) {
        super(props);
        this.state = {
            filterDropdownVisible: false,
            searchText: "",
            filtered: false,
            lastFilteredText: "",
        };
    }

    onCoinFilterUpdate = (e: Event) => {
        if (e.target instanceof HTMLInputElement) {
            this.setState({ searchText: e.target.value });
        }
    };

    onFilterCoinButtonClick = () => {
        this.setState({
            filterDropdownVisible: false,
            filtered: !!this.state.searchText,
            lastFilteredText: this.state.searchText,
        });
    };

    prepareData(): Array<CoinsTableData> {
        return getRowsFilteredByCoin(this.props.data, this.state.searchText);
    }

    getVisibleColumns(): Array<CoinsTableColumnMetadata> {
        return this.props.columns.filter((item) => {
            if (this.props.isBtcBased && this.props.usdColumnNames.has(item.dataIndex)) {
                return false;
            }

            if (!this.props.isBtcBased && this.props.btcColumnNames.has(item.dataIndex)) {
                return false;
            }

            return true;
        });
    }

    generateColumnsData(): Array<CoinsTableColumnMetadata> {
        const { datasources, t } = this.props;

        return this.getVisibleColumns().map((item) => {
            switch (item.dataIndex) {
                case "coin":
                    const filterCoinProps = {
                        filterIcon: <SearchOutlined style={{ color: this.state.filtered ? "#108ee9" : "#aaa" }} />,
                        filterDropdown: (
                            <div
                                style={{
                                    padding: "8px",
                                    borderRadius: "6px",
                                    background: "#fff",
                                    boxShadow: "0 1px 6px rgba(0, 0, 0, .2)",
                                }}
                            >
                                <Input
                                    style={{
                                        width: "180px",
                                        marginRight: "8px",
                                    }}
                                    ref={(element) => (this.searchInput = element)}
                                    placeholder={t("common.coinFilterPlaceholder")}
                                    value={this.state.searchText}
                                    onChange={this.onCoinFilterUpdate}
                                    onPressEnter={this.onFilterCoinButtonClick}
                                />
                                <Button type="primary" onClick={this.onFilterCoinButtonClick}>
                                    {t("common.filterLabel")}
                                </Button>
                            </div>
                        ),
                        filterDropdownVisible: this.state.filterDropdownVisible,
                        onFilterDropdownVisibleChange: (visible) => {
                            if (visible) {
                                this.setState(
                                    {
                                        filterDropdownVisible: visible,
                                    },
                                    () => this.searchInput && this.searchInput.focus()
                                );
                            } else {
                                this.setState({
                                    filterDropdownVisible: visible,
                                    searchText: this.state.filtered ? this.state.lastFilteredText : "",
                                });
                            }
                        },
                    };
                    return Object.assign(item, filterCoinProps);

                case "datasource":
                    const filterDatasourceProps = {
                        filters: getDatasourcesFilter(datasources.map((item) => item.name)),
                        onFilter: (value: string, record: CoinsTableData) => record.datasource === value,
                    };
                    return Object.assign(item, filterDatasourceProps);

                default:
                    return item;
            }
        });
    }

    render() {
        const { isLoading, t } = this.props;

        return (
            <Spin spinning={isLoading} size="large">
                <Table
                    rowKey="uid"
                    columns={this.generateColumnsData()}
                    dataSource={this.prepareData()}
                    pagination={{ pageSize: 100, hideOnSinglePage: true }}
                    size="middle"
                    locale={{
                        filterReset: t("common.resetLabel"),
                        emptyText: t("common.noData"),
                    }}
                    indentSize={0}
                    scroll={{ y: 500 }}
                />
            </Spin>
        );
    }
}

export default withTranslation()(CoinsTable);
