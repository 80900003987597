import { DEFAULT_USER_PREFERENCES } from "./../common/constants";

const initialState = { data: {}, preferences: DEFAULT_USER_PREFERENCES };

export default function processUserEvents(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            return { ...state, data: action.data };

        case "UPDATE_USER_PREFERENCES":
            return { ...state, preferences: action.data };

        default:
            break;
    }

    return state;
}
