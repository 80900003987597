import React, { Component } from "react";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Tabs from "antd/lib/tabs";

import TopTenTable from "./../components/TopTenTable";
import Chart from "./../components/Chart";

import i18n from "./../common/i18n";

class DashboardCards extends Component {
    prepareBarDataByDatasources(data, totalPortfolioPriceInBtc) {
        let result = [];

        if (data === undefined || totalPortfolioPriceInBtc === undefined) {
            return result;
        }

        let datasourcesData = new Map();
        for (let item of data) {
            let datasourceData = datasourcesData.get(item.datasource);
            if (datasourceData === undefined) {
                datasourceData = {
                    valueInBtc: 0.0,
                    valueInUsd: 0.0,
                    percentage: 0.0,
                };
            }

            datasourceData.valueInBtc += item.valueInBtc;
            datasourceData.valueInUsd += item.valueInUsd;
            datasourceData.percentage = (datasourceData.valueInBtc / totalPortfolioPriceInBtc) * 100;

            datasourcesData.set(item.datasource, datasourceData);
        }

        datasourcesData.forEach((value, key, map) => {
            result.push({
                name: key.toUpperCase(),
                btc: parseFloat(value.valueInBtc.toFixed(8)),
                usd: parseFloat(value.valueInUsd.toFixed(2)),
                percentage: parseFloat(value.percentage.toFixed(2)),
            });
        });

        return result;
    }

    getTitleForMode(mode) {
        switch (mode) {
            case "top10Portfolio":
                return i18n.t("overview.cards.top10Portfolio");

            case "top10Gainers":
                return i18n.t("overview.cards.top10Gainers");

            case "top10Loosers":
                return i18n.t("overview.cards.top10Loosers");

            case "top10CoinMarketCap":
                return i18n.t("overview.cards.top10CoinMarketCap");

            case "top10CoinMarketCapGainers":
                return i18n.t("overview.cards.top10CoinMarketCapGainers");

            case "top10CoinMarketCapLoosers":
                return i18n.t("overview.cards.top10CoinMarketCapLoosers");

            case "barsByCoin":
                return i18n.t("overview.cards.barsByCoin");

            case "barsByDatasource":
                return i18n.t("overview.cards.barsByDatasource");

            default:
                console.log("Mode not supported.");
                break;
        }

        return i18n.t("common.noData");
    }

    getTableColumnsForMode(mode) {
        switch (mode) {
            case "top10Portfolio":
                return new Set([
                    "coin",
                    "valueInBtcFormatted",
                    "valueInUsdFormatted",
                    "profitFormatted",
                    "profitForUsdFormatted",
                    "percentageFormatted",
                ]);

            case "top10Gainers":
                return new Set([
                    "coin",
                    "valueInBtcFormatted",
                    "valueInUsdFormatted",
                    "currentPriceFormatted",
                    "currentPriceInUsdFormatted",
                ]);

            case "top10Loosers":
                return new Set([
                    "coin",
                    "valueInBtcFormatted",
                    "valueInUsdFormatted",
                    "currentPriceFormatted",
                    "currentPriceInUsdFormatted",
                ]);

            case "top10CoinMarketCap":
                return new Set([
                    "coin",
                    "currentPriceFormatted",
                    "currentPriceInUsdFormatted",
                    "marketCapFormatted",
                    "marketCapInUsdFormatted",
                ]);

            case "top10CoinMarketCapGainers":
                return new Set([
                    "coin",
                    "volumeFormatted",
                    "volumeInUsdFormatted",
                    "currentPriceFormatted",
                    "currentPriceInUsdFormatted",
                ]);

            case "top10CoinMarketCapLoosers":
                return new Set([
                    "coin",
                    "volumeFormatted",
                    "volumeInUsdFormatted",
                    "currentPriceFormatted",
                    "currentPriceInUsdFormatted",
                ]);

            default:
                console.log("Mode not supported.");
                break;
        }

        return new Set();
    }

    getDataForMode(data, mode, totalPortfolioPriceInBtc) {
        switch (mode) {
            case "top10Portfolio":
                data.sort(function(a, b) {
                    return b.percentage - a.percentage;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "top10Gainers":
                data.sort(function(a, b) {
                    return b.profit24h - a.profit24h;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "top10Loosers":
                data.sort(function(a, b) {
                    return a.profit24h - b.profit24h;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "top10CoinMarketCap":
                data.sort(function(a, b) {
                    return a.rank - b.rank;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "top10CoinMarketCapGainers":
                data.sort(function(a, b) {
                    return b.profit24h - a.profit24h;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "top10CoinMarketCapLoosers":
                data.sort(function(a, b) {
                    return a.profit24h - b.profit24h;
                });
                return data.length > 10 ? data.slice(0, 10) : data.slice(0);

            case "barsByCoin":
                data.sort(function(a, b) {
                    return b.percentage - a.percentage;
                });
                return data.map((item) => ({
                    name: item.coin,
                    btc: parseFloat(item.valueInBtc.toFixed(8)),
                    usd: parseFloat(item.valueInUsd.toFixed(2)),
                    percentage: parseFloat(item.percentage.toFixed(2)),
                }));

            case "barsByDatasource":
                let barsByDatasources = this.prepareBarDataByDatasources(data, totalPortfolioPriceInBtc);
                barsByDatasources.sort(function(a, b) {
                    return b.btc - a.btc;
                });
                return barsByDatasources;

            default:
                console.log("Mode not supported.");
                break;
        }

        return data;
    }

    mergeCoinsForPortfolio(data) {
        let coinsInfo = new Map();

        for (let item of data) {
            let coinInfo = item;
            if (coinsInfo.has(item.coin)) {
                coinInfo = Object.assign({}, coinsInfo.get(item.coin));
                coinInfo.datasource = "multiple";
                coinInfo.percentage += item.percentage;
                coinInfo.valueInBtc += item.valueInBtc;
                coinInfo.valueInUsd += item.valueInUsd;

                coinInfo.averageBuyPrice =
                    (coinInfo.totalBalance * coinInfo.averageBuyPrice + item.totalBalance * item.averageBuyPrice) /
                    (coinInfo.totalBalance + item.totalBalance);

                coinInfo.averageBuyPriceInUsd =
                    (coinInfo.totalBalance * coinInfo.averageBuyPriceInUsd +
                        item.totalBalance * item.averageBuyPriceInUsd) /
                    (coinInfo.totalBalance + item.totalBalance);

                coinInfo.profit = ((coinInfo.currentPrice - coinInfo.averageBuyPrice) / coinInfo.averageBuyPrice) * 100;

                coinInfo.profitForUsd =
                    ((coinInfo.currentPriceInUsd - coinInfo.averageBuyPriceInUsd) / coinInfo.averageBuyPriceInUsd) *
                    100;

                coinInfo.totalBalance += item.totalBalance;
            }

            coinsInfo.set(item.coin, coinInfo);
        }

        let result = [];
        coinsInfo.forEach((value, key, map) => {
            result.push(value);
        });

        return result;
    }

    render() {
        let mergedCoinsPortfolioData = this.mergeCoinsForPortfolio(this.props.data.portfolio);
        let top10PortfolioTable = (
            <TopTenTable
                title={this.getTitleForMode("top10Portfolio")}
                columns={this.getTableColumnsForMode("top10Portfolio")}
                data={this.getDataForMode(mergedCoinsPortfolioData, "top10Portfolio")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isPortfolioTableLoading}
            />
        );

        let top10GainersTable = (
            <TopTenTable
                title={this.getTitleForMode("top10Gainers")}
                columns={this.getTableColumnsForMode("top10Gainers")}
                data={this.getDataForMode(this.props.data.portfolio, "top10Gainers")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isPortfolioTableLoading}
            />
        );

        let top10LoosersTable = (
            <TopTenTable
                title={this.getTitleForMode("top10Loosers")}
                columns={this.getTableColumnsForMode("top10Loosers")}
                data={this.getDataForMode(this.props.data.portfolio, "top10Loosers")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isPortfolioTableLoading}
            />
        );

        let top10CoinMarketCapTable = (
            <TopTenTable
                title={this.getTitleForMode("top10CoinMarketCap")}
                columns={this.getTableColumnsForMode("top10CoinMarketCap")}
                data={this.getDataForMode(this.props.data.coinMarketCap, "top10CoinMarketCap")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isCoinmarketcapTableLoading}
            />
        );

        let top10CoinMarketCapGainersTable = (
            <TopTenTable
                title={this.getTitleForMode("top10CoinMarketCapGainers")}
                columns={this.getTableColumnsForMode("top10CoinMarketCapGainers")}
                data={this.getDataForMode(this.props.data.coinMarketCap, "top10CoinMarketCapGainers")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isCoinmarketcapTableLoading}
            />
        );

        let top10CoinMarketCapLoosersTable = (
            <TopTenTable
                title={this.getTitleForMode("top10CoinMarketCapLoosers")}
                columns={this.getTableColumnsForMode("top10CoinMarketCapLoosers")}
                data={this.getDataForMode(this.props.data.coinMarketCap, "top10CoinMarketCapLoosers")}
                isBtcBased={this.props.data.isBtcBased}
                isLoading={this.props.data.isCoinmarketcapTableLoading}
            />
        );

        let barsByCoinChart = (
            <Chart
                data={this.getDataForMode(mergedCoinsPortfolioData, "barsByCoin")}
                width={600}
                height={300}
                isLoading={this.props.data.isPortfolioTableLoading}
                isBtcBased={this.props.data.isBtcBased}
            />
        );

        let totalPortfolioPriceInBtc = 1;
        if (
            this.props.data.totalPortfolioInfo !== undefined &&
            this.props.data.totalPortfolioInfo.get("total") !== undefined
        ) {
            totalPortfolioPriceInBtc = this.props.data.totalPortfolioInfo.get("total").valueInBtc;
        }

        let barsByDatasourceChart = (
            <Chart
                data={this.getDataForMode(this.props.data.portfolio, "barsByDatasource", totalPortfolioPriceInBtc)}
                width={450}
                height={300}
                isLoading={this.props.data.isPortfolioTableLoading}
                isBtcBased={this.props.data.isBtcBased}
            />
        );

        return (
            <Tabs defaultActiveKey="bars" size="large" centered>
                <Tabs.TabPane tab={i18n.t("overview.tabs.bars")} key="bars">
                    <Row type="flex" justify="center">
                        <Col>
                            <Card type="inner" title={this.getTitleForMode("barsByCoin")}>
                                {barsByCoinChart}
                            </Card>
                        </Col>
                        <Col offset={1}>
                            <Card type="inner" title={this.getTitleForMode("barsByDatasource")}>
                                {barsByDatasourceChart}
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("overview.tabs.top10")} key="top10">
                    <Row type="flex" justify="center">
                        <Col>
                            <Card type="inner" title={this.getTitleForMode("top10Portfolio")}>
                                {top10PortfolioTable}
                            </Card>
                        </Col>
                        <Col offset={1}>
                            <Card type="inner" title={this.getTitleForMode("top10CoinMarketCap")}>
                                {top10CoinMarketCapTable}
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("overview.tabs.topGainers")} key="topGainers">
                    <Row type="flex" justify="center">
                        <Col>
                            <Card type="inner" title={this.getTitleForMode("top10Gainers")}>
                                {top10GainersTable}
                            </Card>
                        </Col>
                        <Col offset={1}>
                            <Card type="inner" title={this.getTitleForMode("top10CoinMarketCapGainers")}>
                                {top10CoinMarketCapGainersTable}
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("overview.tabs.topLoosers")} key="topLoosers">
                    <Row type="flex" justify="center">
                        <Col>
                            <Card type="inner" title={this.getTitleForMode("top10Loosers")}>
                                {top10LoosersTable}
                            </Card>
                        </Col>
                        <Col offset={1}>
                            <Card type="inner" title={this.getTitleForMode("top10CoinMarketCapLoosers")}>
                                {top10CoinMarketCapLoosersTable}
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

export default DashboardCards;
