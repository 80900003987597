// @flow
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import {
    getContextForPluralNumbers,
    formatCustomCoin,
    formatBtc,
    formatBtcWithSign,
    formatUsd,
    formatUsdWithSign,
    formatPercent,
    formatPercentWithSign,
} from "./../common/utils";
import { get2Prices, getPercent, get2PricesWithPercent, getCoinLogo } from "./../common/uiUtils";
import { DATASOURCE_NAMES_STYLED, PROFIT_COLORS, TRADING_URLS } from "./../common/constants";

import CoinsTable from "./CoinsTable";

import type { CoinsTableColumnMetadata, CoinsTableData, Trade, Datasource } from "./../common/types";

const BTC_COLUMN_NAMES = new Set([
    "averageBuyPriceFormatted",
    "profitFormatted",
    "valueInBtcFormatted",
    "currentPriceFormatted",
]);

const USD_COLUMN_NAMES = new Set([
    "averageBuyPriceInUsdFormatted",
    "profitForUsdFormatted",
    "valueInUsdFormatted",
    "currentPriceInUsdFormatted",
]);

type Props = {
    data: Array<CoinsTableData>,
    datasources: Array<Datasource>,
    isBtcBased: boolean,
    isLoading: boolean,
    t: Function,
};

class BalancesTable extends PureComponent<Props> {
    generateColumnsMetadata(): Array<CoinsTableColumnMetadata> {
        const { t } = this.props;
        return [
            {
                title: t("portfolio.table.headers.coin"),
                dataIndex: "coin",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    let coinLogo = record.isTrade ? "" : getCoinLogo(text);
                    let additionalInfo = "";

                    if (record.isTrade) {
                        let tradeDateTime = new Date(record.timestamp);
                        let localTradeDate = tradeDateTime.toLocaleDateString(t("locale"));
                        let localTradeTime = tradeDateTime.toLocaleTimeString(t("locale"));
                        additionalInfo = (
                            <div className="coinCellAdditionalInfo">
                                {localTradeDate}
                                <br />
                                {localTradeTime}
                            </div>
                        );
                    } else if (record.children != null) {
                        additionalInfo = (
                            <div className="coinCellAdditionalInfo">
                                {record.children.length}
                                &nbsp;
                                {t("portfolio.table.numTradesLabel", {
                                    context: getContextForPluralNumbers(record.children.length),
                                })}
                            </div>
                        );
                    }

                    return (
                        <span style={{ fontSize: record.isTrade ? 14 : 16, color: "darkblue" }}>
                            {coinLogo}
                            {text}
                            {additionalInfo}
                        </span>
                    );
                },
            },
            {
                title: t("portfolio.table.headers.datasource"),
                dataIndex: "datasource",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.isTrade) {
                        let label = "";

                        switch (record.datasource) {
                            case "TRADE":
                                label = t("portfolio.table.tradeLabel");
                                break;

                            case "DEPOSIT":
                                label = t("portfolio.table.depositLabel");
                                break;

                            default:
                                break;
                        }

                        return <span style={{ fontSize: 12 }}>{label}</span>;
                    }
                    return DATASOURCE_NAMES_STYLED[record.datasource];
                },
            },
            {
                title: t("portfolio.table.headers.valueInBtc"),
                dataIndex: "valueInBtcFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    const additionalPrices = record.isTrade
                        ? ""
                        : get2Prices(record.valueInBtcFormatted, record.valueInUsdFormatted);

                    return (
                        <div>
                            <span style={{ color: "darkblue", fontSize: record.isTrade ? 12 : 14 }}>
                                {record.totalBalanceFormatted}
                            </span>
                            {additionalPrices}
                        </div>
                    );
                },
            },
            {
                title: t("portfolio.table.headers.valueInUsd"),
                dataIndex: "valueInUsdFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    const additionalPrices = record.isTrade
                        ? ""
                        : get2Prices(record.valueInUsdFormatted, record.valueInBtcFormatted);

                    return (
                        <div>
                            <span style={{ color: "darkblue", fontSize: record.isTrade ? 12 : 14 }}>
                                {record.totalBalanceFormatted}
                            </span>
                            {additionalPrices}
                        </div>
                    );
                },
            },
            {
                title: t("portfolio.table.headers.percentage"),
                dataIndex: "percentageFormatted",
                defaultSortOrder: "descend",
                sorter: (a: CoinsTableData, b: CoinsTableData) => a.percentage - b.percentage,
                render: (text: string, record: CoinsTableData | Trade, index: number) => (
                    <div className="mediumBlueText" style={{ fontWeight: "bold" }}>
                        {text}
                    </div>
                ),
            },
            {
                title: t("portfolio.table.headers.averageBuyPrice"),
                dataIndex: "averageBuyPriceFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => (
                    <span style={{ fontSize: record.isTrade ? 12 : 14 }}>{record.averageBuyPriceFormatted}</span>
                ),
            },
            {
                title: t("portfolio.table.headers.averageBuyPriceInUsd"),
                dataIndex: "averageBuyPriceInUsdFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => (
                    <span style={{ fontSize: record.isTrade ? 12 : 14 }}>{record.averageBuyPriceInUsdFormatted}</span>
                ),
            },
            {
                title: t("portfolio.table.headers.currentPrice"),
                dataIndex: "currentPriceFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.isTrade) {
                        return <span style={{ fontSize: 12 }}>{record.currentPriceFormatted}</span>;
                    }
                    return get2PricesWithPercent(
                        record.currentPriceFormatted,
                        record.currentPriceInUsdFormatted,
                        getPercent(record.profit24h, record.profit24hFormatted)
                    );
                },
            },
            {
                title: t("portfolio.table.headers.currentPriceInUsd"),
                dataIndex: "currentPriceInUsdFormatted",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.isTrade) {
                        return <span style={{ fontSize: 12 }}>{record.currentPriceInUsdFormatted}</span>;
                    }
                    return get2PricesWithPercent(
                        record.currentPriceInUsdFormatted,
                        record.currentPriceFormatted,
                        getPercent(record.profit24hForUsd, record.profit24hForUsdFormatted)
                    );
                },
            },
            {
                title: t("portfolio.table.headers.profit"),
                dataIndex: "profitFormatted",
                sorter: (a: CoinsTableData, b: CoinsTableData) => parseFloat(a.profit) - parseFloat(b.profit),
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.profit === undefined) {
                        return;
                    }

                    let color = PROFIT_COLORS.profit;
                    if (record.profit < 0) {
                        color = PROFIT_COLORS.loss;
                    }

                    if (record.isTrade) {
                        return (
                            <span style={{ color: color, fontWeight: "normal", fontSize: 12 }}>
                                {record.profitFormatted}
                            </span>
                        );
                    }

                    //TODO: why it is calculated here?
                    let absoluteProfitInBtc = record.valueInBtc - record.averageBuyPrice * record.totalBalance;
                    let absoluteProfitInBtcFormatted = formatBtcWithSign(absoluteProfitInBtc);

                    return (
                        <div style={{ color: color, fontWeight: "bold" }}>
                            {text}
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>({absoluteProfitInBtcFormatted})</div>
                        </div>
                    );
                },
            },
            {
                title: t("portfolio.table.headers.profitForUsd"),
                dataIndex: "profitForUsdFormatted",
                sorter: (a: CoinsTableData, b: CoinsTableData) =>
                    parseFloat(a.profitForUsd) - parseFloat(b.profitForUsd),
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.profitForUsd === undefined) {
                        return;
                    }

                    let color = PROFIT_COLORS.profit;
                    if (record.profitForUsd < 0) {
                        color = PROFIT_COLORS.loss;
                    }

                    if (record.isTrade) {
                        return (
                            <span style={{ color: color, fontWeight: "normal", fontSize: 12 }}>
                                {record.profitForUsdFormatted}
                            </span>
                        );
                    }

                    //TODO: why it is calculated here?
                    let absoluteProfitInUsd = record.valueInUsd - record.averageBuyPriceInUsd * record.totalBalance;
                    let absoluteProfitInUsdFormatted = formatUsdWithSign(absoluteProfitInUsd);

                    return (
                        <div style={{ color: color, fontWeight: "bold" }}>
                            {text}
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>({absoluteProfitInUsdFormatted})</div>
                        </div>
                    );
                },
            },
            {
                title: t("portfolio.table.headers.actions"),
                dataIndex: "actions",
                render: (text: string, record: CoinsTableData | Trade, index: number) => {
                    if (record.isTrade) {
                        return;
                    }
                    let url = "#";
                    let symbol = "";
                    if (record.datasource === "binance") {
                        symbol = record.coin + "_BTC";
                        if (record.coin === "BTC" || record.coin === "USDT") {
                            symbol = "BTC_USDT";
                        }
                        url = TRADING_URLS.binance + symbol;
                    } else if (record.datasource === "kucoin") {
                        symbol = record.coin + "-BTC";
                        if (record.coin === "BTC" || record.coin === "USDT") {
                            symbol = "BTC-USDT";
                        }
                        url = TRADING_URLS.kucoin + symbol;
                    } else if (record.datasource === "bittrex") {
                        symbol = "BTC-" + record.coin;
                        if (record.coin === "BTC" || record.coin === "USDT") {
                            symbol = "USDT-BTC";
                        }
                        url = TRADING_URLS.bittrex + symbol;
                    }

                    symbol = symbol.replace("_", "-");

                    return (
                        <a type="dashed" href={url} target="_blank" rel="noopener noreferrer">
                            {t("portfolio.table.tradeButtonLabel")}
                        </a>
                    );
                },
            },
        ];
    }

    formatData(): Array<CoinsTableData> {
        if (this.props.data == null) {
            return [];
        }

        return this.props.data.map((item) =>
            Object.assign(item, {
                isTrade: false,
                totalBalanceFormatted: formatCustomCoin(item.totalBalance, item.coin),
                valueInBtcFormatted: item.valueInBtc == null ? null : formatBtc(item.valueInBtc),
                valueInUsdFormatted: item.valueInUsd == null ? null : formatUsd(item.valueInUsd),
                averageBuyPriceFormatted: item.averageBuyPrice == null ? null : formatBtc(item.averageBuyPrice),
                averageBuyPriceInUsdFormatted:
                    item.averageBuyPriceInUsd == null ? null : formatUsd(item.averageBuyPriceInUsd),
                currentPriceFormatted: item.currentPrice == null ? null : formatBtc(item.currentPrice),
                currentPriceInUsdFormatted: item.currentPriceInUsd == null ? null : formatUsd(item.currentPriceInUsd),
                profitFormatted: item.profit == null ? null : formatPercentWithSign(item.profit),
                profitForUsdFormatted: item.profitForUsd == null ? null : formatPercentWithSign(item.profitForUsd),
                profit24hFormatted: item.profit24h == null ? null : formatPercentWithSign(item.profit24h),
                profit24hForUsdFormatted:
                    item.profit24hForUsd == null ? null : formatPercentWithSign(item.profit24hForUsd),
                percentageFormatted: item.percentage == null ? null : formatPercent(item.percentage),
                children:
                    item.trades == null || item.trades.length === 0
                        ? null
                        : item.trades.map((tradeItem) =>
                              Object.assign(tradeItem, {
                                  isTrade: true,
                                  totalBalanceFormatted: formatCustomCoin(tradeItem.totalBalance, item.coin),
                                  averageBuyPriceFormatted: formatBtc(tradeItem.averageBuyPrice),
                                  averageBuyPriceInUsdFormatted: formatUsd(tradeItem.averageBuyPriceInUsd),
                                  currentPriceFormatted: formatBtc(tradeItem.currentPrice),
                                  currentPriceInUsdFormatted: formatUsd(tradeItem.currentPriceInUsd),
                                  profitFormatted: formatPercentWithSign(tradeItem.profit),
                                  profitForUsdFormatted: formatPercentWithSign(tradeItem.profitForUsd),
                              })
                          ),
            })
        );
    }

    render() {
        return (
            <CoinsTable
                columns={this.generateColumnsMetadata()}
                btcColumnNames={BTC_COLUMN_NAMES}
                usdColumnNames={USD_COLUMN_NAMES}
                data={this.formatData()}
                datasources={this.props.datasources}
                isBtcBased={this.props.isBtcBased}
                isLoading={this.props.isLoading}
            />
        );
    }
}

export default withTranslation()(BalancesTable);
