import axios from "axios";

import {
    loadDeposits as loadBinanceDeposits,
    loadWithdrawals as loadBinanceWithdrawals,
} from "./../actions/binance/account";

import {
    //loadDeposits as loadKuCoinDeposits,
    //loadWithdrawals as loadKuCoinWithdrawals,
} from "./../actions/kucoin/account";

import {
    loadDeposits as loadBittrexDeposits,
    loadWithdrawals as loadBittrexWithdrawals,
} from "./../actions/bittrex/account";


import { calculateDepositsData } from "./dataUtils";

import { objectToMap } from "./../common/utils";

import { BACKEND_URL } from "./../common/settings";

export const clearPortfolio = () => (dispatch) => {
    return dispatch({ type: "CLEAR_PORTFOLIO_DATA" });
};

export const clearDeposits = () => (dispatch) => {
    return dispatch({ type: "CLEAR_DEPOSITS_DATA" });
};

export const loadDeposits = (userId, selectedDatasources) => (dispatch) => {
    let syncArr = [];

    if (selectedDatasources.has("binance")) {
        syncArr.push(dispatch(loadBinanceDeposits(userId)));
        syncArr.push(dispatch(loadBinanceWithdrawals(userId)));
    }

    if (selectedDatasources.has("kucoin")) {
        //TODO: Other coins
        console.log("KuCoin: Disabled Load Deposits/Withdrawals");
        /*syncArr.push(dispatch(loadKuCoinDeposits(userId, "LTC")));
        syncArr.push(dispatch(loadKuCoinDeposits(userId, "ETC")));
        syncArr.push(dispatch(loadKuCoinDeposits(userId, "ETH")));
        syncArr.push(dispatch(loadKuCoinDeposits(userId, "ZPT")));
        syncArr.push(dispatch(loadKuCoinWithdrawals(userId, "LTC")));*/
    }

    if (selectedDatasources.has("bittrex")) {
        syncArr.push(dispatch(loadBittrexDeposits(userId)));
        syncArr.push(dispatch(loadBittrexWithdrawals(userId)));
    }

    return Promise.all(syncArr);
};

export const calculateDeposits = () => (dispatch, getState) => {
    console.log("CALCULATE_DEPOSITS_DATA");
    dispatch({
        type: "CALCULATE_DEPOSITS_DATA",
        data: calculateDepositsData(getState().account),
    });
};

export const loadPortfolioAndPrices = (userId, shouldDoFullUpdate) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/portfolio", {
            params: {
                userId: userId,
                shouldDoFullUpdate: shouldDoFullUpdate ? "true" : "false",
                selectedDatasources: Array.from(getState().uiControl.datasources),
            },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.result === true) {
                dispatch({
                    type: "UPDATE_STATE_WITH_THE_LAST_ONE",
                    balances: response.data.balances,
                    totalInfo: new Map([["total", response.data.totalInfo]]),
                    tradePrices: objectToMap(response.data.tradePrices),
                    timestamp: response.data.timestamp,
                });

                dispatch({
                    type: "UPDATE_UI_CONTROLS_WITH_THE_LAST_ONE",
                    selectedDatasources: new Set(response.data.selectedDatasources),
                    enableUpdatePrices: response.data.selectedDatasources.length > 0,
                });
            } else {
                return Promise.reject("Invalid Data.");
            }

            return response.data.result;
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};

export const loadPortfolioHistory = (userId) => (dispatch) => {
    return axios
        .get(BACKEND_URL + "/v2/history/portfolio", {
            params: {
                userId: userId,
            },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.result) {
                dispatch({
                    type: "LOAD_PORTFOLIO_HISTORY",
                    data: response.data.data
                });
            } else {
                return Promise.reject(response.data.message);
            }

            return response.data.result;
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};
