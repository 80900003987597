import React, { Component } from "react";
import Col from "antd/lib/col";
import Tabs from "antd/lib/tabs";

import i18n from "./../common/i18n";

import { FAQ_ENGLISH } from "./../localization/FAQEnglish";
import { FAQ_RUSSIAN } from "./../localization/FAQRussian";

class FAQ extends Component {
    getContent(faqName, key) {
        switch (faqName) {
            case "FAQ_ENGLISH":
                return FAQ_ENGLISH[key];

            case "FAQ_RUSSIAN":
                return FAQ_RUSSIAN[key];

            default:
                console.log("FAQ with this name is unavailable.");
                break;
        }

        return i18n.t("common.noData");
    }

    render() {
        return (
            <Tabs defaultActiveKey="GENERAL" size="large" tabPosition="left">
                <Tabs.TabPane tab={i18n.t("help.tabs.general")} key="GENERAL">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "GENERAL")}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("help.tabs.setup")} key="SETUP">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "SETUP")}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("help.tabs.privacy")} key="PRIVACY">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "PRIVACY")}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("help.tabs.pricing")} key="PRICING">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "PRICING")}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("help.tabs.feedback")} key="FEEDBACK">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "FEEDBACK")}
                    </Col>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("help.tabs.donate")} key="DONATE">
                    <Col offset={2} span={16} style={{ textAlign: "left" }}>
                        {this.getContent(i18n.t("help.faqName"), "DONATE")}
                    </Col>
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

export default FAQ;
