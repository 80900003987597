const initialState = {
    loadAccount: {
        isLoading: false,
        isDisabled: false,
    },
    updatePrices: {
        isLoading: false,
        isDisabled: true,
    },
    datasourcesTable: {
        isLoading: false,
    },
    depositsTable: {
        isLoading: false,
    },
    portfolioTables: {
        isLoading: false,
    },
    coinmarketcapTables: {
        isLoading: false,
    },
    globalSpinner: {
        isLoading: false,
        text: "Loading...",
    },
    datasoucesSelect: {
        isDisabled: false,
    },
    baseCoin: "btc",
    language: "english",
};

export default function processUiEvents(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_UI_CONTROLS_WITH_THE_LAST_ONE":
            return {
                ...state,
                datasources: action.selectedDatasources,
                updatePrices: {
                    isLoading: state.updatePrices.isLoading,
                    isDisabled: !action.enableUpdatePrices,
                },
            };

        case "UPDATE_DATASOURCES":
            return { ...state, datasources: action.data };

        case "UPDATE_BASE_COIN":
            return { ...state, baseCoin: action.baseCoin };

        case "UPDATE_LANGUAGE":
            return { ...state, language: action.language };

        case "UPDATE_LOAD_ACCOUNT_BUTTON":
            return { ...state, loadAccount: action.data };

        case "UPDATE_UPDATE_PRICES_BUTTON":
            return { ...state, updatePrices: action.data };

        case "UPDATE_GLOBAL_SPINNER":
            return { ...state, globalSpinner: action.data };

        case "UPDATE_DATASOURCES_TABLE_SPINNER":
            return { ...state, datasourcesTable: action.data };

        case "UPDATE_DEPOSITS_TABLE_SPINNER":
            return { ...state, depositsTable: action.data };

        case "UPDATE_PORTFOLIO_TABLES_SPINNER":
            return { ...state, portfolioTables: action.data };

        case "UPDATE_COINMARKETCAP_TABLES_SPINNER":
            return { ...state, coinmarketcapTables: action.data };

        case "UPDATE_DATASOURCES_SELECT":
            return { ...state, datasoucesSelect: action.data };

        default:
            break;
    }

    return state;
}
