import React, { Component } from "react";
import { connect } from "react-redux";

import DashboardCards from "./../components/DashboardCards";

import { processCoinMarketCap } from "./../actions/processingHelper";

class Overview extends Component {
    constructor(props) {
        super(props);

        props.loadCoinMarketCapInfo();
    }

    render() {
        return <DashboardCards data={this.props.data} />;
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        isPortfolioTableLoading: state.uiControl.portfolioTables.isLoading,
        isCoinmarketcapTableLoading: state.uiControl.coinmarketcapTables.isLoading,
        portfolio: state.account.totalInfo === undefined ? [] : state.account.balances,
        totalPortfolioInfo: state.account.totalInfo,
        coinMarketCap: state.account.coinMarketCapData === undefined ? [] : state.account.coinMarketCapData,
        isBtcBased: state.uiControl.baseCoin === "btc",
    },
    ownProps,
});

const mapDispatchToProps = (dispatch) => ({
    loadCoinMarketCapInfo: () => {
        dispatch(processCoinMarketCap());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview);
