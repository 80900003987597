// @flow
import type { CoinsTableData } from "./../common/types";

export const getRowsFilteredByCoin = (data: Array<CoinsTableData>, searchText: string): Array<CoinsTableData> => {
    if (data == null) {
        return [];
    }

    return data.filter((record) => searchText === "" || record.coin.includes(searchText.toUpperCase()));
};
