import React, { Component } from "react";
import { connect } from "react-redux";
import notification from "antd/lib/notification";

import { login, updateUserId, processForgotPassword, processNewPassword, registerUser } from "./../actions/user";
import {
    //updateGlobalSpinner,
    updateLanguage,
} from "./../actions/uiControl";

import Landing from "./../components/Landing";
import LoginDialog from "./../components/LoginDialog";
import RegistrationForm from "./../components/RegistrationForm";
import ForgotPasswordDialog from "./../components/ForgotPasswordDialog";
import NewPasswordDialog from "./../components/NewPasswordDialog";

import i18n from "./../common/i18n";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            isLoginLoading: false,
            showRegistration: false,
            showForgotPassword: false,
            showNewPassword: true,
            error: "",
        };
    }

    //TODO: review this code
    UNSAFE_componentWillMount() {
        let userId = localStorage.getItem("userId");
        if (userId !== null) {
            this.props.setUserId(userId);
        }
    }

    onLanguageUpdate = (newLanguage) => {
        this.props.updateLanguage(newLanguage);
    };

    onProcessLoginButtonClick = (loginData) => {
        this.setState({
            ...this.state,
            isLoginLoading: true,
        });

        this.props.processLogin(loginData, this.onLoginError);
    };

    onLoginError = (errorMessage) => {
        console.log(errorMessage);
        this.setState({
            ...this.state,
            isLoginLoading: false,
            error: errorMessage,
        });
    };

    onRegistrationButtonClick = () => {
        this.setState({
            ...this.state,
            showLogin: false,
            showRegistration: true,
            error: "",
        });
    };

    onSubmitRegistrationInfo = (data) => {
        this.setState({
            ...this.state,
            isSubmitLoading: true,
        });

        this.props.processRegistration(data, this.onRegistrationProcessed);
    };

    onRegistrationProcessed = (error) => {
        if (error === undefined) {
            this.setState({
                ...this.state,
                showLogin: true,
                showRegistration: false,
                isSubmitLoading: false,
                error: "",
            });
        } else {
            this.setState({
                ...this.state,
                isSubmitLoading: false,
                error: error,
            });
        }
    };

    onLoginButtonClick = () => {
        this.setState({
            ...this.state,
            showLogin: true,
            error: "",
        });
    };

    onCancelLoginButtonClick = () => {
        this.setState({
            ...this.state,
            showLogin: false,
            error: "",
        });
    };

    onCancelRegistrationButtonClick = () => {
        this.setState({
            ...this.state,
            showLogin: true,
            showRegistration: false,
            error: "",
        });
    };

    onForgotPasswordButtonClick = () => {
        this.setState({
            ...this.state,
            showLogin: false,
            showForgotPassword: true,
            error: "",
        });
    };

    onResetPasswordButtonClick = (username) => {
        this.setState({
            ...this.state,
            showLogin: true,
            showForgotPassword: false,
            error: "",
        });

        this.props.processForgotPassword(username);
    };

    onCancelResetPasswordButtonClick = (username) => {
        this.setState({
            ...this.state,
            showLogin: true,
            showForgotPassword: false,
            error: "",
        });
    };

    onConfirmChangePasswordButtonClick = (newPassword) => {
        this.setState({
            ...this.state,
            showLogin: true,
            showNewPassword: false,
            error: "",
        });

        this.props.processNewPassword(this.props.newPasswordId, newPassword);

        this.props.navigateBack();
    };

    onCancelNewPasswordButtonClick = (username) => {
        this.setState({
            ...this.state,
            showLogin: true,
            showNewPassword: false,
            error: "",
        });

        this.props.navigateBack();
    };

    render() {
        if (this.props.newPasswordId !== undefined && this.state.showNewPassword) {
            return (
                <div>
                    <NewPasswordDialog
                        isVisible={this.state.showNewPassword}
                        onOkButtonClick={this.onConfirmChangePasswordButtonClick}
                        onCancelButtonClick={this.onCancelNewPasswordButtonClick}
                    />
                </div>
            );
        }

        if (this.state.showRegistration) {
            return (
                <div>
                    <RegistrationForm
                        isVisible={this.state.showRegistration}
                        isLoading={this.state.isSubmitLoading}
                        error={this.state.error}
                        onSubmitButtonClick={this.onSubmitRegistrationInfo}
                        onCancelButtonClick={this.onCancelRegistrationButtonClick}
                    />
                </div>
            );
        }

        if (this.state.showForgotPassword) {
            return (
                <div>
                    <ForgotPasswordDialog
                        isVisible={this.state.showForgotPassword}
                        error={this.state.error}
                        onResetButtonClick={this.onResetPasswordButtonClick}
                        onCancelButtonClick={this.onCancelResetPasswordButtonClick}
                    />
                </div>
            );
        }

        return (
            <div>
                <Landing
                    language={this.props.data.language}
                    onLanguageUpdate={this.onLanguageUpdate}
                    onLoginButtonClick={this.onLoginButtonClick}
                    onRegistrationButtonClick={this.onRegistrationButtonClick}
                />
                <LoginDialog
                    isVisible={this.state.showLogin}
                    isLoading={this.state.isLoginLoading}
                    error={this.state.error}
                    onLogin={this.onProcessLoginButtonClick}
                    onRegistrationButtonClick={this.onRegistrationButtonClick}
                    onForgotPasswordButtonClick={this.onForgotPasswordButtonClick}
                    onCancelButtonClick={this.onCancelLoginButtonClick}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        language: state.uiControl.language,
    },
    ownProps,
});

const mapDispatchToProps = (dispatch) => ({
    processLogin: (loginData, errorHandler) => {
        //dispatch(updateGlobalSpinner(true, i18n.t('progress.settingsLoading')));

        Promise.resolve(dispatch(login(loginData)))
            .then(() => {
                //dispatch(updateGlobalSpinner(false));
                /*notification["success"]({
                message: i18n.t('notifications.loginSuccess.title'),
                description: i18n.t('notifications.loginSuccess.message'),
                placement: "bottomRight",
            });*/
            })
            .catch((err) => {
                //dispatch(updateGlobalSpinner(false));
                errorHandler(err.toString());
            });
    },
    setUserId: (userId) => {
        dispatch(updateUserId(userId));
    },
    processForgotPassword: (username) => {
        dispatch(processForgotPassword(username));
    },
    processNewPassword: (newPasswordId, newPassword) => {
        Promise.resolve(dispatch(processNewPassword(newPasswordId, newPassword)))
            .then(() => {
                notification["success"]({
                    message: i18n.t("notifications.newPasswordSuccess.title"),
                    description: i18n.t("notifications.newPasswordSuccess.message"),
                    placement: "bottomRight",
                });
            })
            .catch((err) => {
                notification["error"]({
                    message: i18n.t("notifications.newPasswordFailed.title"),
                    description: i18n.t("notifications.newPasswordFailed.message"),
                    placement: "bottomRight",
                });
            });
    },
    processRegistration: (data, updateStateFunc) => {
        Promise.resolve(dispatch(registerUser(data)))
            .then(() => {
                updateStateFunc();
                notification["success"]({
                    message: i18n.t("notifications.registrationSuccess.title"),
                    description: i18n.t("notifications.registrationSuccess.message"),
                    placement: "bottomRight",
                });
            })
            .catch((err) => {
                updateStateFunc(err.toString());
            });
    },
    updateLanguage: (value) => {
        dispatch(updateLanguage(value));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
