export const processData = (data) => {
    if (!data && !data.length) {
        return [];
    }

    let btc24hChange = 0;
    let btcUsdPrice = 1;
    if (data[0].symbol === "BTC") {
        btc24hChange = parseFloat(data[0].quote.USD.percent_change_24h);
        btcUsdPrice = parseFloat(data[0].quote.USD.price);
    }

    return data.map((item) => ({
        uid: item.id + "coinmarketcap",
        coin: item.symbol,
        currentPrice: parseFloat(item.quote.USD.price) / btcUsdPrice,
        currentPriceInUsd: parseFloat(item.quote.USD.price),
        profit24h: ((parseFloat(item.quote.USD.percent_change_24h) - btc24hChange) / (100 + btc24hChange)) * 100,
        profit24hForUsd: parseFloat(item.quote.USD.percent_change_24h),
        marketCap: parseFloat(item.quote.USD.market_cap) / btcUsdPrice,
        marketCapInUsd: parseFloat(item.quote.USD.market_cap),
        supply: parseFloat(item.total_supply),
        volume: parseFloat(item.quote.USD.volume_24h) / btcUsdPrice,
        volumeInUsd: parseFloat(item.quote.USD.volume_24h),
        rank: parseFloat(item.cmc_rank),
        item: item,
    }));
};
