import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import DepositsHeader from "./../components/DepositsHeader";
import InAndOutTable from "./../components/InAndOutTable";

class Deposits extends Component {
    render() {
        return (
            <Fragment>
                <DepositsHeader data={this.props.data.totalInfo} />
                <InAndOutTable
                    data={this.props.data.deposits}
                    datasources={this.props.data.availableDatasources}
                    isBtcBased={this.props.data.isBtcBased}
                    isLoading={this.props.data.isTableLoading}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        isTableLoading: state.uiControl.depositsTable.isLoading,
        deposits:
            state.account.deposits === undefined
                ? null
                : [...state.account.deposits].map((item) => {
                      return item[1];
                  }),
        totalInfo: state.account.totalDepositsData,
        isBtcBased: state.uiControl.baseCoin === "btc",
        availableDatasources: state.datasources.list === undefined ? [] : state.datasources.list,
    },
    ownProps,
});

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Deposits);
