const initialState = { tradePrices: new Map(), currentPrices: new Map(), portfolioHistory: [] };

export default function processAccountEvents(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_STATE_WITH_THE_LAST_ONE":
            const lastState = {
                timestamp: Date.now(),
                isLastState: true,
                totalInfo: action.totalInfo.get("total")
            }

            if (state.portfolioHistory.length &&
                state.portfolioHistory[state.portfolioHistory.length - 1].isLastState) {
                state.portfolioHistory[state.portfolioHistory.length - 1] = lastState;
            } else {
                state.portfolioHistory.push(lastState);
            }
            return {
                ...state,
                balances: action.balances,
                totalInfo: action.totalInfo,
                tradePrices: action.tradePrices,
                portfolioHistory: [...state.portfolioHistory]
            };

        case "CLEAR_PORTFOLIO_DATA":
            return { ...state, balances: [], tradePrices: new Map(), totalInfo: undefined };

        case "LOAD_ACCOUNT_SUCCEEDED":
            if (state.balances === undefined) {
                return { ...state, balances: action.data };
            }
            return { ...state, balances: action.data.concat(state.balances) };

        case "LOAD_TRADE_PRICE_SUCCEEDED":
            let tradePrices = new Map(state.tradePrices);
            tradePrices.set(action.datasource, action.prices);
            return { ...state, tradePrices: tradePrices };

        case "LOAD_PRICES_SUCCEEDED":
            let currentPrices = new Map(state.currentPrices);
            currentPrices.set(action.datasource, {
                currentPrices: action.currentPrices,
                prices24h: action.prices24h,
            });
            return { ...state, currentPrices: currentPrices };

        case "CALCULATE_ACCOUNT_DATA":
            return action.data;

        case "CLEAR_DEPOSITS_DATA":
            return { ...state, deposits: new Map(), depositPrices: new Map() };

        case "LOAD_DEPOSITS_SUCCEEDED":
            return { ...state, deposits: action.data };

        case "LOAD_WITHDRAWALS_SUCCEEDED":
            return { ...state, deposits: action.data };

        case "LOAD_DEPOSIT_PRICE_SUCCEEDED":
            return { ...state, depositPrices: action.data };

        case "CALCULATE_DEPOSITS_DATA":
            return action.data;

        case "UPDATE_BTCUSD_PRICE_SUCCEEDED":
            return { ...state, btcUsdPrice: action.price };

        case "LOAD_COINMARKETCAP_INFO_SUCCEEDED":
            return { ...state, coinMarketCapData: action.data };

        case "LOAD_PORTFOLIO_HISTORY":
            return { ...state, portfolioHistory: action.data };

        default:
            break;
    }

    return state;
}
