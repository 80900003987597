import React, { Component } from "react";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import Button from "antd/lib/button";

import { LANGUAGE_NAMES } from "./../common/constants";
import i18n from "./../common/i18n";

class UserPreferencesForm extends Component {
    constructor(props) {
        super(props);
        this.state = props.data.userPreferences;
    }

    //TODO: review this code
    UNSAFE_componentWillMount() {
        this.setState(this.props.data.userPreferences);
    }

    updateState(key, value) {
        switch (key) {
            case "language":
                this.props.onLanguageUpdate(value);
                break;

            case "baseCoin":
                this.props.onBaseCoinUpdate(value);
                break;

            default:
                console.log("Unknown setting!");
                break;
        }
        this.setState({ ...this.state, [key]: value });
    }

    onSave() {
        this.props.saveUserPreferences(this.state);
    }

    render() {
        return (
            <Form
                layout="vertical"
                style={{ textAlign: "left" }}
                onFinish={() => {
                    this.onSave();
                }}
            >
                <Form.Item label={i18n.t("settings.userPreferences.fields.language")}>
                    <Select
                        value={this.state.language}
                        onChange={(newValue) => {
                            this.updateState("language", newValue);
                        }}
                        style={{ width: 185 }}
                    >
                        <Select.Option value="english">{LANGUAGE_NAMES.english}</Select.Option>
                        <Select.Option value="russian">{LANGUAGE_NAMES.russian}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={i18n.t("settings.userPreferences.fields.baseCoin")}>
                    <Select
                        value={this.state.baseCoin}
                        onChange={(newValue) => {
                            this.updateState("baseCoin", newValue);
                        }}
                        style={{ width: 185 }}
                    >
                        <Select.Option value="btc">{i18n.t("menu.btc")}</Select.Option>
                        <Select.Option value="usd">{i18n.t("menu.usd")}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: 185 }}>
                        {i18n.t("settings.userPreferences.saveButtonLabel")}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default UserPreferencesForm;
