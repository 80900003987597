import React, { Component } from "react";
import { connect } from "react-redux";
import Tabs from "antd/lib/tabs";

import PortfolioHeader from "./../components/PortfolioHeader";
import BalancesTable from "./../components/BalancesTable";
import PortfolioHistoryChart from "../components/PortfolioHistoryLineChart";

import { loadPortfolioHistory } from "./../actions/account";

import i18n from "./../common/i18n";

const getTimestampLabel = (timestamp, isNow) => {
    const dateTime = new Date(timestamp);

    if (isNow) {
        return dateTime.toLocaleTimeString();
    }

    return dateTime.toLocaleDateString();
};

class Portfolio extends Component {
    onTabChange(newTabId) {
        if (newTabId === "history" && this.props.data.portfolioHistory.length < 2) {
            this.props.loadPortfolioHistory(this.props.data.userId);
        }
    }

    getDataForChart() {
        return this.props.data.portfolioHistory.map((item) => ({
            name: getTimestampLabel(item.timestamp, item.isLastState),
            btc: parseFloat(item.totalInfo.valueInBtc.toFixed(8)),
            usd: parseFloat(item.totalInfo.valueInUsd.toFixed(2)),
        }));
    }

    render() {
        return (
            <Tabs defaultActiveKey="table" size="large" centered onChange={(newTabId) => this.onTabChange(newTabId)}>
                <Tabs.TabPane tab={i18n.t("portfolio.tabs.table")} key="table">
                    <PortfolioHeader data={this.props.data.totalInfo} isBtcBased={this.props.data.isBtcBased} />
                    <BalancesTable
                        data={this.props.data.balances}
                        datasources={this.props.data.availableDatasources}
                        isBtcBased={this.props.data.isBtcBased}
                        isLoading={this.props.data.isTableLoading}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("portfolio.tabs.history")} key="history">
                    <div className="flexBlockCenterJustified">
                        <PortfolioHistoryChart
                            data={this.getDataForChart()}
                            width={1000}
                            height={300}
                            isLoading={this.props.data.isTableLoading}
                            isBtcBased={this.props.data.isBtcBased}
                        />
                    </div>
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        isTableLoading: state.uiControl.portfolioTables.isLoading,
        balances: state.account.totalInfo === undefined ? null : state.account.balances,
        totalInfo: state.account.totalInfo === undefined ? undefined : state.account.totalInfo.get("total"),
        isBtcBased: state.uiControl.baseCoin === "btc",
        availableDatasources: state.datasources.list === undefined ? [] : state.datasources.list,
        portfolioHistory: state.account.portfolioHistory,
        userId: state.user.data.login,
    },
    ownProps,
});

const mapDispatchToProps = (dispatch) => ({
    loadPortfolioHistory: (userId) => {
        dispatch(loadPortfolioHistory(userId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Portfolio);
