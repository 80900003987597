import axios from "axios";

import { processData } from "./dataUtils";

import { BACKEND_URL } from "./../../common/settings";

export const loadInfo = () => (dispatch) => {
    return axios
        .get(BACKEND_URL + "/prices/coinmarketcap")
        .then((response) => {
            //console.log(response.data);
            
            if (response.data.status.error_code !== 0) {
                console.log(response.data.status.error_message);
                return Promise.reject(response.data.status.error_message);
            }

            dispatch({
                type: "LOAD_COINMARKETCAP_INFO_SUCCEEDED",
                data: processData(response.data.data),
            });
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
