import axios from "axios";

import { mapToObject, objectToMap } from "./../common/utils";

import { DEFAULT_USER_PREFERENCES, EMAIL_FROM_ADDRESS } from "./../common/constants";
import { BACKEND_URL } from "./../common/settings";

import i18n from "./../common/i18n";

export const login = (loginData) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/login", {
            params: {
                userId: loginData.login,
                password: loginData.password,
                authProvider: loginData.authProvider
            },
        })
        .then((response) => {
            //console.log(response);
            if (loginData.remember) {
                localStorage.setItem("userId", response.data.user);
            }
            dispatch({
                type: "UPDATE_USER_DATA",
                data: { login: response.data.user },
            });
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};

export const registerUser = (data) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/register", {
            params: {
                data: data,
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};

export const updateUserId = (userId) => (dispatch) => {
    dispatch({
        type: "UPDATE_USER_DATA",
        data: { login: userId },
    });
};

export const logout = () => (dispatch) => {
    localStorage.removeItem("userId");

    dispatch({
        type: "UPDATE_USER_DATA",
        data: {},
    });
};

export const deleteAccount = (userId) => (dispatch) => {
    return axios
        .delete(BACKEND_URL + "/deleteAccount", {
            params: {
                userId: userId,
            },
        })
        .then((response) => {
            console.log(response);

            localStorage.removeItem("userId");

            dispatch({
                type: "UPDATE_USER_DATA",
                data: {},
            });
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const loadUserPreferences = (userId) => (dispatch) => {
    return axios
        .get(BACKEND_URL + "/settings/userPreferences", {
            params: {
                userId: userId,
            },
        })
        .then((response) => {
            //console.log(response);

            let result = response.data;
            if (response.data.result === false) {
                result = DEFAULT_USER_PREFERENCES;
            }

            dispatch({
                type: "UPDATE_USER_PREFERENCES",
                data: result,
            });

            return result;
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};

export const saveUserPreferences = (userId, data) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/settings/userPreferences", {
            params: {
                userId: userId,
                data: data,
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const loadLastState = (userId) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/portfolio", {
            params: {
                userId: userId,
                selectedDatasources: Array.from(getState().uiControl.datasources),
            },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.result === true) {
                dispatch({
                    type: "UPDATE_STATE_WITH_THE_LAST_ONE",
                    balances: response.data.balances,
                    totalInfo: new Map([["total", response.data.totalInfo]]),
                    tradePrices: objectToMap(response.data.tradePrices),
                    timestamp: response.data.timestamp,
                });

                dispatch({
                    type: "UPDATE_UI_CONTROLS_WITH_THE_LAST_ONE",
                    selectedDatasources: new Set(response.data.selectedDatasources),
                    enableUpdatePrices: response.data.selectedDatasources.length > 0,
                });
            } else {
                return Promise.reject("Invalid Data.");
            }

            return response.data.result;
        })
        .catch((error) => {
            console.log(error, error.response);
            return Promise.reject(error.response === undefined ? error : error.response.data);
        });
};

export const saveCurrentState = (userId) => (dispatch, getState) => {
    let currentState = getState();
    //console.log("currentState", currentState);
    let data = {
        balances: currentState.account.balances,
        totalInfo: currentState.account.totalInfo.get("total"),
        tradePrices: mapToObject(currentState.account.tradePrices),
        selectedDatasources: Array.from(currentState.uiControl.datasources),
    };
    return axios
        .post(BACKEND_URL + "/lastState", {
            params: {
                userId: userId,
                data: data,
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const changePassword = (userId, data) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/settings/changePassword", {
            params: {
                userId: userId,
                password: data.currentPassword,
                newPassword: data.newPassword,
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const processForgotPassword = (userId) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/email/send/forgotPassword", {
            params: {
                userId: userId,
                from: EMAIL_FROM_ADDRESS,
                subject: i18n.t("resetPassword.email.subject"),
                text: i18n.t("resetPassword.email.plainText", { joinArrays: "\n" }),
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const processNewPassword = (newPasswordId, data) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/settings/newPassword", {
            params: {
                newPasswordId: newPasswordId,
                newPassword: data,
            },
        })
        .then((response) => {
            //console.log(response);
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
