import React, { Component } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { LockOutlined } from '@ant-design/icons';

import { hashPassword, isPasswordMediumValid } from "./../common/utils";
import { getLogoWithTitle } from "./../common/uiUtils";

import i18n from "./../common/i18n";

class ChangePasswordDialog extends Component {
    constructor(props) {
        super(props);
        this.state = this.getEmptyState();
    }

    getEmptyState() {
        return {
            currentPassword: "",
            newPassword: "",
            confirmation: "",
            error: "",
            errorField: "",
        };
    }

    updateState(key, value) {
        if (key === this.state.errorField) {
            this.setState({ ...this.state, [key]: value, errorField: "", error: "" });
        } else {
            this.setState({ ...this.state, [key]: value });
        }
    }

    onOk() {
        if (this.state.currentPassword === "") {
            this.setState({
                ...this.state,
                error: i18n.t("settings.changePasswordDialog.errors.oldPasswordEmpty"),
                errorField: "currentPassword",
            });
            return;
        }

        if (!isPasswordMediumValid(this.state.newPassword)) {
            this.setState({
                ...this.state,
                error: i18n.t("common.errors.invalidPassword"),
                errorField: "newPassword",
            });
            return;
        }

        if (this.state.newPassword !== this.state.confirmation) {
            this.setState({
                ...this.state,
                error: i18n.t("common.errors.confirmationFailed"),
                errorField: "confirmation",
            });
            return;
        }

        Promise.resolve(this.props.validateCurrentLogin(hashPassword(this.state.currentPassword)))
            .then(() => {
                this.props.hideDialog();

                this.props.onConfirmChangePassword({
                    currentPassword: hashPassword(this.state.currentPassword),
                    newPassword: hashPassword(this.state.newPassword),
                });

                this.setState(this.getEmptyState());
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    error: i18n.t("settings.changePasswordDialog.errors.oldPasswordInvalid"),
                    errorField: "currentPassword",
                });
            });
    }

    onCancel() {
        this.props.hideDialog();

        this.setState(this.getEmptyState());
    }

    render() {
        return (
            <div>
                <Modal
                    title={getLogoWithTitle(i18n.t("settings.changePasswordDialog.title"))}
                    visible={this.props.isVisible}
                    onOk={() => {
                        this.onOk();
                    }}
                    onCancel={() => {
                        this.onCancel();
                    }}
                    okText={i18n.t("common.okLabel")}
                    cancelText={i18n.t("common.cancelLabel")}
                    confirmLoading={this.props.isLoading}
                    maskClosable={false}
                    width={360}
                >
                    <Form layout="vertical">
                        <Form.Item
                            validateStatus={this.state.errorField === "currentPassword" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "currentPassword"}
                            help={this.state.errorField === "currentPassword" ? this.state.error : ""}
                        >
                            <Input
                                type="password"
                                value={this.state.currentPassword}
                                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("currentPassword", event.target.value);
                                }}
                                placeholder={i18n.t("settings.changePasswordDialog.fields.oldPasswordPlaceholder")}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={this.state.errorField === "newPassword" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "newPassword"}
                            help={this.state.errorField === "newPassword" ? this.state.error : ""}
                        >
                            <Input
                                type="password"
                                value={this.state.newPassword}
                                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("newPassword", event.target.value);
                                }}
                                placeholder={i18n.t("settings.changePasswordDialog.fields.newPasswordPlaceholder")}
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={this.state.errorField === "confirmation" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "confirmation"}
                            help={this.state.errorField === "confirmation" ? this.state.error : ""}
                        >
                            <Input
                                type="password"
                                value={this.state.confirmation}
                                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                onChange={(event) => {
                                    this.updateState("confirmation", event.target.value);
                                }}
                                placeholder={i18n.t("settings.changePasswordDialog.fields.confirmationPlaceholder")}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default ChangePasswordDialog;
