import axios from "axios";

import {
    prepareDepositPrice,
    prepareDepositsData,
} from "./../dataUtils";

import { BACKEND_URL } from "./../../common/settings";

export const loadDeposits = (userId) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/depositHistory/binance", {
            params: { userId: userId },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.success) {
                let deposits = response.data.depositList.map((item) => ({
                    coin: item.asset,
                    datasource: "binance",
                    amount: item.amount,
                    timestamp: item.insertTime,
                }));

                let depositsData = prepareDepositsData(getState().account.deposits, deposits, true);

                dispatch({
                    type: "LOAD_DEPOSITS_SUCCEEDED",
                    data: depositsData,
                });

                return dispatch(getDepositsPrices(response.data.depositList));
            } //TODO: process failures
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const loadWithdrawals = (userId) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/withdrawalHistory/binance", {
            params: { userId: userId },
        })
        .then((response) => {
            //console.log(response.data);

            if (response.data.success) {
                let withdrawals = response.data.withdrawList.map((item) => ({
                    coin: item.asset,
                    datasource: "binance",
                    amount: item.amount,
                    timestamp: item.applyTime,
                }));

                let withdrawalsData = prepareDepositsData(getState().account.deposits, withdrawals, false);

                dispatch({
                    type: "LOAD_WITHDRAWALS_SUCCEEDED",
                    data: withdrawalsData,
                });

                return dispatch(getDepositsPrices(response.data.withdrawList));
            } //TODO: process failures
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

const getDepositsPrices = (data) => (dispatch) => {
    let syncArr = [];
    for (let item of data) {
        let startTime = item.insertTime === undefined ? item.applyTime : item.insertTime;

        if (item.asset === "USDT") {
            syncArr.push(dispatch(getDepositPrice(item.asset, "BTC", "BTCUSDT", item.amount, startTime, 60000)));
        } else {
            syncArr.push(dispatch(getDepositPrice(item.asset, "USDT", "BTCUSDT", item.amount, startTime, 60000)));

            if (item.asset !== "BTC" && item.asset !== "USDT") {
                syncArr.push(
                    dispatch(getDepositPrice(item.asset, "BTC", item.asset + "BTC", item.amount, startTime, 3600000))
                );
            }
        }
    }

    return Promise.all(syncArr);
};

const getDepositPrice = (coin, coinPair, symbol, quantity, timestamp, interval) => (dispatch, getState) => {
    return axios
        .get(BACKEND_URL + "/price/history/binance/" + symbol, {
            params: {
                symbol: symbol,
                startTime: timestamp,
                endTime: interval === undefined ? timestamp : timestamp + interval,
                limit: 1,
            },
        })
        .then((response) => {
            //console.log(coin, coinPair, symbol, response, response.data[0]);
            if (response.data[0] === null) {
                console.log(coin, coinPair, symbol, "Price not found");
                return Promise.reject("Price not found");
            }

            let price = parseFloat(response.data[0].p);

            let updatedPrices = prepareDepositPrice(
                "binance",
                coin,
                coinPair,
                symbol,
                quantity,
                timestamp,
                price,
                getState().account.depositPrices
            );

            dispatch({
                type: "LOAD_DEPOSIT_PRICE_SUCCEEDED",
                data: updatedPrices,
            });
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
