import React, { Component } from "react";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";

import ChangePasswordDialog from "./../components/ChangePasswordDialog";

import i18n from "./../common/i18n";

class Security extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
        };
    }

    showChangePasswordDialog = () => {
        this.setState({ isVisible: true });
    };

    hideChangePasswordDialog = () => {
        this.setState({ isVisible: false });
    };

    onDeleteButtonClick() {
        Modal.confirm({
            title: i18n.t("settings.security.confirmDeleteAccount.title"),
            content: i18n.t("settings.security.confirmDeleteAccount.message"),
            onOk: this.props.onDeleteAccountButtonClick,
            okText: i18n.t("common.yesLabel"),
            cancelText: i18n.t("common.noLabel"),
        });
    }

    render() {
        return (
            <div style={{ textAlign: "left" }}>
                <Button
                    type="primary"
                    style={{ width: 200 }}
                    onClick={() => {
                        this.showChangePasswordDialog();
                    }}
                >
                    {i18n.t("settings.security.changePasswordButtonLabel")}
                </Button>
                <ChangePasswordDialog
                    isVisible={this.state.isVisible}
                    onConfirmChangePassword={this.props.onConfirmChangePassword}
                    hideDialog={this.hideChangePasswordDialog}
                    validateCurrentLogin={this.props.validateCurrentLogin}
                />
                <br />
                <Button
                    type="danger"
                    style={{ width: 200 }}
                    onClick={() => {
                        this.onDeleteButtonClick();
                    }}
                >
                    {i18n.t("settings.security.deleteAccountButtonLabel")}
                </Button>
            </div>
        );
    }
}

export default Security;
