export const RUSSIAN_TEXT = {
    languageName: "Русский",
    locale: "ru-RU",
    common: {
        noData: "Нет данных",
        yesLabel: "Да",
        noLabel: "Нет",
        okLabel: "OK",
        cancelLabel: "Отмена",
        loginLabel: "Войти",
        resetLabel: "Сбросить",
        submitLabel: "Регистрация",
        filterLabel: "Фильтровать",
        coinFilterPlaceholder: "Введите имя монеты",
        dataLoadFailed: {
            title: "Ошибка",
            message: "Непредвиденная ошибка при загрузке. Обратитесь в поддержку.",
        },
        errors: {
            invalidPassword: "Недостаточная сложность пароля.",
            confirmationFailed: "Введенные пароли не совпадают.",
        },
    },
    landing: {
        menu: {
            login: "Войти",
            register: "Регистрация",
        },
        gradientBlock: {
            text1: "Автоматический калькулятор Вашего криптопортфеля",
            text2: "Удобная аналитика по Вашим активам с разных бирж",
            registerLabel: "Регистрация",
        },
        whyBlock: {
            question: "Вы должны попробовать CryptoACE, если:",
            argument1: "Вы знаете что такое криптовалюта",
            argument2: "У Вас есть монеты на одной из криптобирж",
            argument3: "Вам нужна полная картина по Вашему портфелю",
        },
        featuresBlock: {
            title: "Точный и простой",
            description: "Никакого ручного ввода. Не нужно держать цифры в голове.",
            description2: "Автоматическая загрузка данных с бирж.",
            description3: "Данные с разных бирж в одном месте.",
            description4: "Удобный интерфейс.",
            features: {
                first: {
                    title: "Полностью автоматизированный анализ портфеля",
                    description: "Вы не вводите сделки вручную. Мы сделаем это за Вас!",
                },
                second: {
                    title: "Поддержка Bitcoin, Ethereum, Litecoin и многих других монет",
                    description: "Вы видите актуальную информацию о Ваших монетах на каждой бирже.",
                },
                third: {
                    title: "Подсчет ввода-вывода средств в одной валюте",
                    description:
                        "Помните ли Вы - сколько Вы ввели и вывели средств по каждой бирже? Мы можем показать Вам.",
                },
                fourth: {
                    title: "Анализ активных сделок",
                    description: "Вы видите информацию по каждой активной сделке для любой монеты.",
                },
                fifth: {
                    title: "Информативная статистика",
                    description: "Начните Ваш день с просмотра статистики монет и изменений за последние сутки.",
                },
                sixth: {
                    title: "Точные вычисления",
                    description: "Мы используем цены, взятые на момент совершения каждой сделки на каждой бирже.",
                },
            },
        },
        contacts: "НЕ ЗАБЫВАЙТЕ ПИСАТЬ НАМ:",
    },
    login: {
        title: "Войти в приложение CryptoACE Stats",
        fields: {
            loginPlaceholder: "Введите имя пользователя или почту",
            passwordPlaceholder: "Введите пароль",
            rememberMeLabel: "Запомнить меня",
            forgotPasswordLabel: "Восстановить пароль",
            registrationStartText: "Еще не зарегистрированы? ",
            registration: "Регистрируйтесь прямо сейчас!",
        },
        errors: {
            loginEmpty: "Введите имя пользователя.",
            passwordEmpty: "Введите пароль.",
            facebookFailed: "При входе с помощью Facebook произошла ошибка.",
        },
    },
    forgotPassword: {
        title: "Сброс пароля",
        fields: {
            loginLabel:
                "Введите имя пользователя или почту Вашей учетной записи. Вы получите подтверждающее письмо с дальнейшими инструкциями на Ваш почтовый адрес, связанный с этой учетной записью.",
        },
    },
    newPassword: {
        title: "Установить новый пароль",
    },
    registration: {
        title: "Регистрация нового пользователя",
        fields: {
            usernameLabel: "Имя пользователя",
            usernamePlaceholder: "Введите имя пользователя",
            nameLabel: "Имя",
            namePlaceholder: "Введите Ваше имя",
            emailLabel: "Почта",
            emailPlaceholder: "Введите Вашу почту",
            passwordLabel: "Пароль",
            passwordPlaceholder: "Введите пароль",
            confirmationLabel: "Подтвердите пароль",
            confirmationPlaceholder: "Подтвердите пароль",
            acceptLabel: "Я принимаю ",
            acceptTermsLinkLabel: "Условия использования продукта",
        },
        errors: {
            usernameInvalid: "Введите поддерживаемое имя пользователя.",
            nameEmpty: "Введите Ваше имя.",
            emailInvalid: "Введите правильный адрес почты.",
            termsWereNotAccepted: "Вы забыли принять условия использования продукта.",
            passwordIsUsername: "Пароль не должен быть именем пользователя.",
        },
    },
    menu: {
        general: "Обзор",
        portfolio: "Портфель",
        deposits: "Ввод/Вывод",
        trades: "Сделки",
        predictions: "Прогнозы",
        listedCoins: "Торгуемые монеты",
        settings: "Настройки",
        help: "Помощь",
        logout: "Выйти",
        confirmLogout: {
            title: "Подтвердите выход",
            message: "Вы действительно хотите выйти?",
        },
        btc: "Биткоин (BTC)",
        usd: "Доллар США (USD)",
        percentage: "% Портфеля",
    },
    overview: {
        tabs: {
            bars: "Портфель",
            top10: "Лучшие 10 монет",
            topGainers: "Наибольший рост",
            topLoosers: "Наибольшее падение",
        },
        cards: {
            top10Portfolio: "Лучшие 10 Монет в Портфеле по Стоимости",
            top10Gainers: "Наибольший Рост по Портфелю за Сутки",
            top10Loosers: "Наибольшее Падение по Портфелю за Сутки",
            top10CoinMarketCap: "Лучшие 10 Монет по Капитализации",
            top10CoinMarketCapGainers: "Наибольший Рост на Рынке за Сутки",
            top10CoinMarketCapLoosers: "Наибольшее Падение на Рынке за Сутки",
            barsByCoin: "Стоимость Монет в Портфеле",
            barsByDatasource: "Источники Данных в Портфеле",
        },
        table: {
            headers: {
                rank: "#",
                marketCap: "Капитализация",
                supply: "Предложение",
                volume: "Оборот (24ч)",
            },
            multiple: "НЕСКОЛЬКО",
        },
    },
    trades: {
        todo: "В разработке: Статистика сделок.",
    },
    help: {
        faqName: "FAQ_RUSSIAN",
        tabs: {
            general: "Общие вопросы по продукту",
            setup: "Настройка API ключей",
            privacy: "Приватность и безопасность",
            pricing: "Ценообразование",
            feedback: "Обратная связь",
            donate: "Поддержать проект",
        },
    },
    settings: {
        userPreferences: {
            fields: {
                language: "Язык интерфейса",
                baseCoin: "База расчета",
            },
            saveButtonLabel: "Сохранить изменения",
        },
        security: {
            changePasswordButtonLabel: "Изменить пароль",
            deleteAccountButtonLabel: "Удалить учетную запись",
            confirmDeleteAccount: {
                title: "Подтвердите удаление учетной записи",
                message:
                    "Вы действительно хотите удалить Вашу учетную запись? Обратите внимание, что это действие необратимо!",
            },
        },
        changePasswordDialog: {
            title: "Изменить пароль",
            fields: {
                oldPasswordPlaceholder: "Введите текущий пароль",
                newPasswordPlaceholder: "Введите новый пароль",
                confirmationPlaceholder: "Подтвердите новый пароль",
            },
            errors: {
                oldPasswordEmpty: "Введите свой текущий пароль.",
                oldPasswordInvalid: "Вы ввели неверный текущий пароль.",
            },
        },
        tabs: {
            datasources: "Источники Данных",
            userPreferences: "Персонализация",
            security: "Настройки безопасности",
        },
        noAvailableDatasources: {
            title: "Ошибка",
            message:
                "Вы уже ввели ключи для всех поддерживаемых источников данных. Мы уже работаем над поддержкой других. Следите за нашими новостями.",
        },
        buttons: {
            add: {
                label: "Добавить",
                tooltip: "Используйте эту кнопку чтобы добавить новый источник данных.",
            },
            edit: {
                label: "Редактировать",
            },
            delete: {
                label: "Удалить",
                confirm: {
                    title: "Подтвердите удаление",
                    message: "Вы собираетесь удалить источник данных. Вы уверены?",
                },
            },
        },
        table: {
            headers: {
                datasource: "Источник данных",
                type: "Тип",
                key: "Ключ",
                autoload: "Загружать автоматически",
                actions: "Действия",
            },
        },
        editDatasourceDialog: {
            titleAdd: "Добавить источник данных",
            titleEdit: "Редактировать источник данных",
            fields: {
                type: "Тип источника данных",
                name: "Имя источника данных",
                key: "API ключ",
                secret: "Секретный ключ",
                passphrase: "Ключевая фраза",
                autoload: "Автоматически загружать данные портфеля",
            },
            errors: {
                keyEmpty: "Введите API ключ.",
                secretEmpty: "Введите секретный ключ.",
                passphraseEmpty: "Введите ключевую фразу.",
            },
        },
    },
    deposits: {
        totalbalance: "Общий баланс ввода/вывода",
        table: {
            headers: {
                coin: "Монета",
                datasource: "Источник данных",
                deposits: "Введено",
                withdrawals: "Выведено",
                diff: "Баланс ввода/вывода",
                depositsInBtc: "Введено, BTC",
                withdrawalsInBtc: "Выведено, BTC",
                diffInBtc: "Баланс ввода/вывода, BTC",
                depositsInUsd: "Введено, USD",
                withdrawalsInUsd: "Выведено, USD",
                diffInUsd: "Баланс ввода/вывода, USD",
            },
            depositLabel: "ввод",
            withdrawalLabel: "вывод",
            numOperationsLabel_one: "перевод",
            numOperationsLabel_few: "перевода",
            numOperationsLabel_many: "переводов",
        },
    },
    portfolio: {
        tabs: {
            table: "Обзор",
            history: "История",
        },
        estimatedValue: "Текущая стоимость портфеля",
        profit: "Прибыль/убыток по портфелю",
        dailyProfit: "Прибыль/убыток за сутки",
        table: {
            headers: {
                coin: "Монета",
                datasource: "Источник",
                totalBalance: "Баланс",
                valueInBtc: "Стоимость",
                valueInUsd: "Стоимость",
                percentage: "% Портфеля",
                averageBuyPrice: "Ср. цена покупки",
                averageBuyPriceInUsd: "Ср. цена покупки",
                currentPrice: "Текущая цена (24ч)",
                currentPriceInUsd: "Текущая цена (24ч)",
                profit: "Прибыль/убыток",
                profitForUsd: "Прибыль/убыток",
                actions: "Действия",
            },
            tradeButtonLabel: "Торговать",
            tradeLabel: "торговля",
            depositLabel: "ввод средств",
            numTradesLabel_one: "сделка",
            numTradesLabel_few: "сделки",
            numTradesLabel_many: "сделок",
        },
    },
    header: {
        buttons: {
            loadAccount: {
                label: "Загрузить все данные",
                tooltip: "Используйте эту кнопку, чтобы загрузить все данные для выбранных источников.",
            },
            loadPortfolio: {
                label: "Загрузить данные портфеля",
                tooltip: "Используйте эту кнопку, чтобы загрузить данные портфеля для выбранных источников.",
            },
            updatePrices: {
                label: "Обновить цены",
                tooltip: "Используйте эту кнопку, чтобы обновить цены для выбранных источников данных.",
            },
            loadDeposits: {
                label: "Загрузить данные по вводу/выводу",
                tooltip:
                    "Используйте эту кнопку, чтобы загрузить данные ввода/вывода средств для выбранных источников.",
            },
            loadCoinsInfo: {
                label: "Загрузить данные по монетам",
                tooltip: "Используйте эту кнопку, чтобы загрузить данные по торгуемым монетам для выбранных бирж.",
            },
            updateBtcUsdPrice: {
                tooltip: "Вы можете нажать эту кнопку для получения последней цены на BTC/USD.",
            },
        },
        datasources: {
            noDatasources: {
                error: "Вам необходимо сначала добавить хоть один ключ API для ваших источников данных.",
                buttonLabel: "Перейти к странице настроек",
            },
            noSelection: "Выберите источник(и) данных",
            tooltip: "Здесь вы можете выбрать источники, с которых вы хотите загрузить данные.",
        },
    },
    notifications: {
        loginSuccess: {
            title: "Успешный вход",
            message: "Пользователь успешно зашел в систему.",
        },
        settingsLoadSuccess: {
            title: "Успешная загрузка",
            message: "Загрузка настроек пользователя прошла успешно.",
        },
        depositsLoadSuccess: {
            title: "Успешная загрузка",
            message: "Загрузка данных о вводе/выводе денежных средств прошла успешно.",
        },
        portfolioLoadSuccess: {
            title: "Успешная загрузка",
            message: "Загрузка данных об инвестиционном портфеле прошла успешно.",
        },
        pricesUpdateSuccess: {
            title: "Успешное обновление",
            message: "Цены для выбранных источников данных обновились успешно.",
        },
        changePasswordSuccess: {
            title: "Успешное изменение",
            message: "Ваш пароль был успешно изменен.",
        },
        changePasswordFailed: {
            title: "Ошибка",
            message: "Неизвестная ошибка при попытке изменить пароль. Попробуйте еще раз позже.",
        },
        registrationSuccess: {
            title: "Успешная регистрация",
            message: "Регистрация прошла успешно. Теперь Вы можете войти в систему.",
        },
        newPasswordSuccess: {
            title: "Успешное изменение",
            message: "Ваш пароль был успешно задан.",
        },
        newPasswordFailed: {
            title: "Ошибка",
            message: "Неизвестная ошибка при попытке задать новый пароль. Попробуйте еще раз позже.",
        },
    },
    progress: {
        settingsLoading: "Загружаются настройки пользователя...",
        depositsLoading: "Загружаются данные о вводе/выводе средств...",
        portfolioLoading: "Загружаются данные об инвестиционном портфеле...",
        pricesUpdating: "Обновляются цены...",
    },
    resetPassword: {
        email: {
            subject: "Сброс пароля для приложения Stats CryptoACE",
            plainText: [
                "Здравствуйте, _NAME_.",
                "",
                "Мы получили запрос на сброс пароля для учетной записи с именем пользователя: _USERNAME_.",
                "",
                "Если Вы действительно делали этот запрос, используйте ссылку ниже для сброса пароля:",
                "_URL_",
                "",
                "Если у Вас есть еще вопросы по процедуре сброса пароля, обратитесь в наш Центр Помощи.",
                "",
                "С уважением, команда CryptoACE.",
            ],
        },
    },
};
