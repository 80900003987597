import "./../styles/App.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import Layout from "antd/lib/layout";
import Spin from "antd/lib/spin";

import MainHeader from "./../components/MainHeader";
import CopyrightFooter from "./../components/CopyrightFooter";
import FAQ from "./../components/FAQ";

import Overview from "./../containers/Overview";
import Portfolio from "./../containers/Portfolio";
import Deposits from "./../containers/Deposits";
import Settings from "./../containers/Settings";
import Login from "./../containers/Login";

import { logout } from "./../actions/user";
import {
    updateBaseCoin,
    updateLanguage,
    updateDatasources,
    updateLoadAccountButton,
    updateUpdatePricesButton,
} from "./../actions/uiControl";
import { clearDatasourcesData } from "./../actions/datasources";
import { clearDeposits, clearPortfolio } from "./../actions/account";
import { updateBtcUsdPrice } from "./../actions/bittrex/account";
import {
    processDeposits,
    processPortfolio,
    processCalculationPortfolio,
    processLoadAllData,
} from "./../actions/processingHelper";

import { LOCATIONS } from "./../common/constants";
import i18n from "./../common/i18n";

class App extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    onBaseCoinUpdate = (newBaseCoin) => {
        this.props.updateBaseCoin(newBaseCoin);
    };

    onLanguageUpdate = (newLanguage) => {
        this.props.updateLanguage(newLanguage);
    };

    onDatasourcesUpdate = (values) => {
        this.props.updateDatasources(new Set(values));
    };

    onLoadAccountButtonClick = () => {
        this.props.loadPortfolioData(this.props.data.userId, this.props.data.selectedDatasources);
        this.props.loadDepositsData(this.props.data.userId, this.props.data.selectedDatasources);
    };

    onLoadPortfolioButtonClick = () => {
        this.props.loadPortfolioData(this.props.data.userId, this.props.data.selectedDatasources);
    };

    onUpdatePortfolioPricesButtonClick = () => {
        this.props.calculatePortfolio(this.props.data.userId, this.props.data.selectedDatasources);
    };

    onLoadDepositsButtonClick = () => {
        this.props.loadDepositsData(this.props.data.userId, this.props.data.selectedDatasources);
    };

    onLogOutButtonClick = () => {
        this.props.processLogout();
    };

    onBtcUsdPriceClick = () => {
        this.props.updateBtcUsdPrice();
    };

    //TODO: review this code
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data.isUserLoggedIn && nextProps.data.userId !== this.props.data.userId) {
            nextProps.loadAllData(nextProps.data.userId);
            nextProps.updateBtcUsdPrice();
        }
    }

    Trades = () => <h2>{i18n.t("trades.todo")}</h2>;

    UnknownPage = () => <h2>{i18n.t("common.noData")}</h2>;

    getMainContent = () => (
        <Layout.Content className="layoutContent">
            <Switch>
                <Route exact path={LOCATIONS.general} component={Overview} />
                <Route path={LOCATIONS.portfolio} component={Portfolio} />
                <Route path={LOCATIONS.deposits} component={Deposits} />
                <Route path={LOCATIONS.trades} component={this.Trades} />
                <Route path={LOCATIONS.settings} component={Settings} />
                <Route path={LOCATIONS.help} component={FAQ} />
                <Route component={this.UnknownPage} />
            </Switch>
        </Layout.Content>
    );

    navigateToInitialPage = () => {
        this.props.history.push(this.props.location.pathname);
    };

    render() {
        let newPasswordId = new URLSearchParams(this.props.location.search).get("id");

        if (newPasswordId !== null) {
            return <Login newPasswordId={newPasswordId} navigateBack={this.navigateToInitialPage} />;
        }

        if (!this.props.data.isUserLoggedIn) {
            return <Login />;
        }

        let formattedSpinnerText = (
            <div className="spinnerInfoText">
                <br />
                {this.props.data.globalSpinner.text}
            </div>
        );

        return (
            <Spin spinning={this.props.data.globalSpinner.isLoading} size="large" tip={formattedSpinnerText}>
                <Layout>
                    <MainHeader
                        data={this.props.data}
                        location={this.props.location.pathname}
                        onBaseCoinUpdate={this.onBaseCoinUpdate}
                        onLanguageUpdate={this.onLanguageUpdate}
                        onDatasourcesUpdate={this.onDatasourcesUpdate}
                        onLoadAccountButtonClick={this.onLoadAccountButtonClick}
                        onLoadPortfolioButtonClick={this.onLoadPortfolioButtonClick}
                        onUpdatePortfolioPricesButtonClick={this.onUpdatePortfolioPricesButtonClick}
                        onLoadDepositsButtonClick={this.onLoadDepositsButtonClick}
                        onLoadCoinsInfoButtonClick={this.onLoadCoinsInfoButtonClick}
                        onLogOutButtonClick={this.onLogOutButtonClick}
                        onBtcUsdPriceClick={this.onBtcUsdPriceClick}
                    />
                    {this.getMainContent()}
                    <CopyrightFooter />
                </Layout>
            </Spin>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        btcUsdPrice: state.account.btcUsdPrice,
        globalSpinner: state.uiControl.globalSpinner,
        baseCoin: state.uiControl.baseCoin,
        language: state.uiControl.language,
        availableDatasources:
            state.datasources.list === undefined ? [] : state.datasources.list.map((item) => item.name),
        selectedDatasources: state.uiControl.datasources === undefined ? new Set() : state.uiControl.datasources,
        datasourcesList: state.datasources.list === undefined ? null : state.datasources.list,
        controls: {
            loadAccount: state.uiControl.loadAccount,
            updatePrices: state.uiControl.updatePrices,
            datasources: state.uiControl.datasoucesSelect,
        },
        balances: state.account.totalInfo === undefined ? null : state.account.balances,
        isUserLoggedIn: state.user.data.login !== undefined,
        userId: state.user.data.login,
    },
    ownProps,
});

const mapDispatchToProps = (dispatch) => ({
    updateBtcUsdPrice: () => {
        dispatch(updateBtcUsdPrice());
    },
    updateBaseCoin: (value) => {
        dispatch(updateBaseCoin(value));
    },
    updateLanguage: (value) => {
        dispatch(updateLanguage(value));
    },
    updateDatasources: (data) => {
        dispatch(updateDatasources(data));
        dispatch(updateLoadAccountButton(false, data.size === 0));
        dispatch(updateUpdatePricesButton(false, true));
    },
    loadDepositsData: (userId, selectedDatasources) => {
        dispatch(processDeposits(userId, selectedDatasources));
    },
    loadPortfolioData: (userId, selectedDatasources) => {
        dispatch(processPortfolio(userId, selectedDatasources));
    },
    calculatePortfolio: (userId, selectedDatasources) => {
        dispatch(processCalculationPortfolio(userId, selectedDatasources));
    },
    loadAllData: (userId) => {
        dispatch(processLoadAllData(userId));
    },
    processLogout: () => {
        dispatch(updateDatasources([]));
        dispatch(clearPortfolio());
        dispatch(clearDeposits());
        dispatch(clearDatasourcesData());
        dispatch(logout());
    },
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
