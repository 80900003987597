export const ENGLISH_TEXT = {
    languageName: "English",
    locale: "en-US",
    common: {
        noData: "No data available",
        yesLabel: "Yes",
        noLabel: "No",
        okLabel: "OK",
        cancelLabel: "Cancel",
        loginLabel: "Login",
        resetLabel: "Reset",
        submitLabel: "Submit",
        filterLabel: "Filter",
        coinFilterPlaceholder: "Enter coin name",
        dataLoadFailed: {
            title: "Error",
            message: "Unexpected error while loading data. Please contact support.",
        },
        errors: {
            invalidPassword: "Password is not strong enough.",
            confirmationFailed: "Please enter the same password to confirm.",
        },
    },
    landing: {
        menu: {
            login: "Login",
            register: "Register New Account",
        },
        gradientBlock: {
            text1: "Automated cryptocurrency portfolio calculator",
            text2: "Manage your data easily from different exchanges",
            registerLabel: "Register Now",
        },
        whyBlock: {
            question: "Why do you need CryptoACE?",
            argument1: "You do know what cryptocurrency is",
            argument2: "You have account at least at one cryptocurrency exchange",
            argument3: "You desire to maintain your portfolio in one place",
        },
        featuresBlock: {
            title: "Accurate and intuitive",
            description: "Cryptocurrency exchanges are as close as possible.",
            description2: "No manual entry. No numbers in your head.",
            description3: "Automatically pull data from exchanges.",
            description4: "Enjoy the simplicity.",
            features: {
                first: {
                    title: "Fully Automated Crypto-Portfolio Tracking",
                    description: "You do not need to enter your trades manually. We will do it for you!",
                },
                second: {
                    title: "Bitcoin, Ethereum, Litecoin and Many Others Altcoins Calculation",
                    description:
                        "You can review up-to-date information about your coins for each cryptocurrency exchange.",
                },
                third: {
                    title: "Deposits and Withdrawals Tracking",
                    description:
                        "Do you remember the amount of your deposits and withdrawals on each of your exchanges? We can show it to you.",
                },
                fourth: {
                    title: "Active Trades Review",
                    description: "You can see information about each active trade per coin.",
                },
                fifth: {
                    title: "Advanced Statistics",
                    description:
                        "Start your day with the your coin stats and see what has happened during the last 24 hours.",
                },
                sixth: {
                    title: "Accurate Calculations",
                    description: "We use actual prices at time of transaction.",
                },
            },
        },
        contacts: "FEEL FREE TO CONTACT US:",
    },
    login: {
        title: "Login to CryptoACE Stats",
        fields: {
            loginPlaceholder: "Enter Username or E-mail",
            passwordPlaceholder: "Enter Password",
            rememberMeLabel: "Remember me",
            forgotPasswordLabel: "Forgot password",
            registrationStartText: "Don't have account? ",
            registration: "Register Now!",
        },
        errors: {
            loginEmpty: "Please enter username.",
            passwordEmpty: "Please enter password.",
            facebookFailed: "Login with Facebook failed.",
        },
    },
    forgotPassword: {
        title: "Reset password",
        fields: {
            loginLabel:
                "Enter username or e-mail for your registered account. You will receive confirmation e-mail to proceed with password reset.",
        },
    },
    newPassword: {
        title: "Set New Password",
    },
    registration: {
        title: "Register New Account",
        fields: {
            usernameLabel: "Username",
            usernamePlaceholder: "Enter Username",
            nameLabel: "Name",
            namePlaceholder: "Enter Name",
            emailLabel: "E-mail",
            emailPlaceholder: "Enter E-mail",
            passwordLabel: "Password",
            passwordPlaceholder: "Enter Password",
            confirmationLabel: "Confirm Password",
            confirmationPlaceholder: "Confirm Password",
            acceptLabel: "I agree and accept the ",
            acceptTermsLinkLabel: "Terms and Conditions",
        },
        errors: {
            usernameInvalid: "Please enter valid username.",
            nameEmpty: "Please enter name.",
            emailInvalid: "Please enter valid e-mail.",
            termsWereNotAccepted: "You should accept the Terms and Conditions to proceed.",
            passwordIsUsername: "Password cannot be equal to username.",
        },
    },
    menu: {
        general: "Overview",
        portfolio: "Portfolio",
        deposits: "Deposits",
        trades: "Trades",
        predictions: "Predictions",
        listedCoins: "Listed Coins",
        settings: "Settings",
        help: "Help",
        logout: "Log Out",
        confirmLogout: {
            title: "Confirm Log Out",
            message: "Do you want to log out?",
        },
        btc: "Bitcoin (BTC)",
        usd: "US Dollar (USD)",
        percentage: "% of Portfolio",
    },
    overview: {
        tabs: {
            bars: "Portfolio",
            top10: "Top 10",
            topGainers: "Top Gainers",
            topLoosers: "Top Loosers",
        },
        cards: {
            top10Portfolio: "Portfolio Top 10 by value",
            top10Gainers: "Portfolio Top 10 Price Gainers (24h)",
            top10Loosers: "Portfolio Top 10 Price Loosers (24h)",
            top10CoinMarketCap: "Market Top 10 by value",
            top10CoinMarketCapGainers: "Market Top 10 Price Gainers (24h)",
            top10CoinMarketCapLoosers: "Market Top 10 Price Loosers (24h)",
            barsByCoin: "Portfolio Cost by Coins",
            barsByDatasource: "Portfolio Cost by Datasources",
        },
        table: {
            headers: {
                rank: "Rank",
                marketCap: "Market Cap",
                supply: "Circulating Supply",
                volume: "Volume (24h)",
            },
            multiple: "MULTIPLE",
        },
    },
    trades: {
        todo: "TODO: Trades Statistics.",
    },
    help: {
        faqName: "FAQ_ENGLISH",
        tabs: {
            general: "General Product Questions",
            setup: "Setup API Keys",
            privacy: "Privacy and Security",
            pricing: "Pricing",
            feedback: "Feedback",
            donate: "Donate",
        },
    },
    settings: {
        userPreferences: {
            fields: {
                language: "Language",
                baseCoin: "Base Coin",
            },
            saveButtonLabel: "Save Changes",
        },
        security: {
            changePasswordButtonLabel: "Change Password",
            deleteAccountButtonLabel: "Delete Account",
            confirmDeleteAccount: {
                title: "Delete Account Confirmation",
                message: "Do you want to delete your account? NOTE: This action cannot be undone! Are you sure?",
            },
        },
        changePasswordDialog: {
            title: "Change Password",
            fields: {
                oldPasswordPlaceholder: "Enter Current Password",
                newPasswordPlaceholder: "Enter New Password",
                confirmationPlaceholder: "Confirm New Password",
            },
            errors: {
                oldPasswordEmpty: "Please enter current password.",
                oldPasswordInvalid: "Please enter valid current password.",
            },
        },
        tabs: {
            datasources: "Datasources",
            userPreferences: "User Preferences",
            security: "Security Settings",
        },
        noAvailableDatasources: {
            title: "Error",
            message:
                "You have already added keys for all supported datasources. We'll add support for more really very soon. Check for our updates.",
        },
        buttons: {
            add: {
                label: "Add",
                tooltip: "Use this button to add a new datasource.",
            },
            edit: {
                label: "Edit",
            },
            delete: {
                label: "Delete",
                confirm: {
                    title: "Confirm Delete",
                    message: "You're going to delete Datasource. Are you sure?",
                },
            },
        },
        table: {
            headers: {
                datasource: "Datasource",
                type: "Type",
                key: "Key",
                autoload: "Autoload Data",
                actions: "Actions",
            },
        },
        editDatasourceDialog: {
            titleAdd: "Add Datasource",
            titleEdit: "Edit Datasource",
            fields: {
                type: "Datasource Type",
                name: "Datasource Name",
                key: "API Key",
                secret: "Secret",
                passphrase: "Passphrase",
                autoload: "Automatically Load Portfolio Data",
            },
            errors: {
                keyEmpty: "Please enter API key.",
                secretEmpty: "Please enter API secret.",
                passphraseEmpty: "Please enter passphrase.",
            },
        },
    },
    deposits: {
        totalbalance: "Total Running Balance",
        table: {
            headers: {
                coin: "Coin",
                datasource: "Datasource",
                deposits: "Deposits",
                withdrawals: "Withdrawals",
                diff: "Running Balance",
                depositsInBtc: "Deposits, BTC",
                withdrawalsInBtc: "Withdrawals, BTC",
                diffInBtc: "Running Balance",
                depositsInUsd: "Deposits, USD",
                withdrawalsInUsd: "Withdrawals, USD",
                diffInUsd: "Running Balance",
            },
            depositLabel: "deposit",
            withdrawalLabel: "withdrawal",
            numOperationsLabel_one: "operation",
            numOperationsLabel_few: "operations",
            numOperationsLabel_many: "operations",
        },
    },
    portfolio: {
        tabs: {
            table: "Overview",
            history: "History",
        },
        estimatedValue: "Estimated Value",
        profit: "Profit/Loss",
        dailyProfit: "Daily Profit/Loss",
        table: {
            headers: {
                coin: "Coin",
                datasource: "Datasource",
                totalBalance: "Balance",
                valueInBtc: "Value",
                valueInUsd: "Value",
                percentage: "% of Portfolio",
                averageBuyPrice: "Av. Trade Price",
                averageBuyPriceInUsd: "Av. Trade Price",
                currentPrice: "Current Price (Daily Change)",
                currentPriceInUsd: "Current Price (Daily Change)",
                profit: "Profit/Loss",
                profitForUsd: "Profit/Loss",
                actions: "Actions",
            },
            tradeButtonLabel: "Trade",
            tradeLabel: "trade",
            depositLabel: "deposit",
            numTradesLabel_one: "trade",
            numTradesLabel_few: "trades",
            numTradesLabel_many: "trades",
        },
    },
    header: {
        buttons: {
            loadAccount: {
                label: "Load Account Data",
                tooltip: "Use this button to load all the data for selected datasources.",
            },
            loadPortfolio: {
                label: "Load Porfolio Data",
                tooltip: "Use this button to load Portfolio data for selected datasources.",
            },
            updatePrices: {
                label: "Update Prices",
                tooltip: "Use this button to update prices to the latest ones for each selected datasource.",
            },
            loadDeposits: {
                label: "Load Deposits Data",
                tooltip: "Use this button to load all Deposits/Withdrawals data for selected datasources.",
            },
            loadCoinsInfo: {
                label: "Load Listed Coins Info",
                tooltip: "Use this button to load Listed Coins Info for selected exchanges.",
            },
            updateBtcUsdPrice: {
                tooltip: "You can click this button to update BTC/USD price.",
            },
        },
        datasources: {
            noDatasources: {
                error: "Please add API keys for your Datasources.",
                buttonLabel: "Go to Settings Page",
            },
            noSelection: "Please select Datasources",
            tooltip: "Here you can select exchanges that you want to calculate data for.",
        },
    },
    notifications: {
        loginSuccess: {
            title: "Success",
            message: "User log in successfully.",
        },
        settingsLoadSuccess: {
            title: "Success",
            message: "User settings have been loaded successfully.",
        },
        depositsLoadSuccess: {
            title: "Success",
            message: "Deposit data has been loaded successfully.",
        },
        portfolioLoadSuccess: {
            title: "Success",
            message: "Portfolio data has been loaded successfully.",
        },
        pricesUpdateSuccess: {
            title: "Success",
            message: "Current prices have been updated successfully.",
        },
        changePasswordSuccess: {
            title: "Success",
            message: "Your password has been updated successfully.",
        },
        changePasswordFailed: {
            title: "Error",
            message: "Unexpected error occured while updating your password. Please try again later.",
        },
        registrationSuccess: {
            title: "Success",
            message: "Registration has been processed successfully. You are able to login now.",
        },
        newPasswordSuccess: {
            title: "Success",
            message: "Your password has been set successfully.",
        },
        newPasswordFailed: {
            title: "Error",
            message: "Unexpected error occured while setting your password. Please try again later.",
        },
    },
    progress: {
        settingsLoading: "User settings are loading...",
        depositsLoading: "Deposit data is loading...",
        portfolioLoading: "Portfolio data is loading...",
        pricesUpdating: "Portfolio prices are updating...",
    },
    resetPassword: {
        email: {
            subject: "Reset your Stats CryptoACE password",
            plainText: [
                "Hello, _NAME_.",
                "",
                "We received a request to reset the password for your account: _USERNAME_.",
                "",
                "If you made this request, use this link to reset your password:",
                "_URL_",
                "",
                "If you have more questions about password reset, please visit our Help Center.",
                "",
                "Best Regards, CryptoACE Team.",
            ],
        },
    },
};
