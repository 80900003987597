import notification from "antd/lib/notification";
import message from "antd/lib/message";

import { loadUserPreferences, loadLastState } from "./../actions/user";

import { clearDatasourcesData, loadDatasourcesData } from "./../actions/datasources";

import {
    clearPortfolio,
    clearDeposits,
    loadDeposits,
    calculateDeposits,
    loadPortfolioAndPrices,
} from "./../actions/account";

import { updateBtcUsdPrice } from "./../actions/bittrex/account";

import { loadInfo as loadCoinMarketCapInfo } from "./../actions/coinmarketcap/account";

import {
    updateBaseCoin,
    updateLanguage,
    updateLoadAccountButton,
    updateUpdatePricesButton,
    updateDatasourcesTableSpinner,
    updateDepositsTableSpinner,
    updatePortfolioTablesSpinner,
    updateCoinMarketCapTablesSpinner,
    updateDatasourcesSelect,
} from "./../actions/uiControl";

import i18n from "./../common/i18n";

export const processDeposits = (userId, selectedDatasources) => (dispatch) => {
    console.log("ACCOUNT DEPOSIT DATA LOAD STARTED!");
    dispatch(updateDatasourcesSelect(true));
    dispatch(updateLoadAccountButton(true, false));
    //dispatch(updateGlobalSpinner(true, i18n.t('progress.depositsLoading')));
    message.loading(i18n.t("progress.depositsLoading"), 0);
    dispatch(updateDepositsTableSpinner(true));

    dispatch(clearDeposits());

    let syncPromises = dispatch(loadDeposits(userId, selectedDatasources));

    syncPromises
        .then(() => {
            console.log("ACCOUNT DEPOSIT DATA LOAD FINISHED!");
            dispatch(calculateDeposits());
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            //dispatch(updateGlobalSpinner(false));
            message.destroy();
            dispatch(updateDepositsTableSpinner(false));
            notification["success"]({
                message: i18n.t("notifications.depositsLoadSuccess.title"),
                description: i18n.t("notifications.depositsLoadSuccess.message"),
                placement: "bottomRight",
            });
        })
        .catch((err) => {
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            //dispatch(updateGlobalSpinner(false));
            message.destroy();
            dispatch(updateDepositsTableSpinner(false));
            console.log(err);
            notification["error"]({
                message: i18n.t("common.dataLoadFailed.title"),
                description: i18n.t("common.dataLoadFailed.message"),
                placement: "bottomRight",
            });
        });
};

export const processPortfolio = (userId) => (dispatch) => {
    console.log("ACCOUNT PORTFOLIO DATA LOAD STARTED!");
    dispatch(updateDatasourcesSelect(true));
    dispatch(updateLoadAccountButton(true, false));
    dispatch(updateUpdatePricesButton(true, true));
    dispatch(updatePortfolioTablesSpinner(true));
    message.loading(i18n.t("progress.portfolioLoading"), 0);

    dispatch(clearPortfolio());

    let syncPromise = dispatch(loadPortfolioAndPrices(userId, true));

    Promise.resolve(syncPromise)
        .then(() => {
            console.log("ACCOUNT PORTFOLIO DATA LOAD FINISHED!");
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            dispatch(updateUpdatePricesButton(false, false));
            dispatch(updatePortfolioTablesSpinner(false));
            message.destroy();

            notification["success"]({
                message: i18n.t("notifications.portfolioLoadSuccess.title"),
                description: i18n.t("notifications.portfolioLoadSuccess.message"),
                placement: "bottomRight",
            });
        })
        .catch((err) => {
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            dispatch(updateUpdatePricesButton(false, false));
            message.destroy();
            dispatch(updatePortfolioTablesSpinner(false));
            console.log(err);
            notification["error"]({
                message: i18n.t("common.dataLoadFailed.title"),
                description: i18n.t("common.dataLoadFailed.message"),
                placement: "bottomRight",
            });
        });
};

export const processCalculationPortfolio = (userId) => (dispatch) => {
    console.log("PORTFOLIO PRICES LOAD STARTED!");
    dispatch(updateDatasourcesSelect(true));
    dispatch(updateLoadAccountButton(true, false));
    dispatch(updateUpdatePricesButton(true, false));
    message.loading(i18n.t("progress.pricesUpdating"), 0);
    dispatch(updatePortfolioTablesSpinner(true));

    dispatch(updateBtcUsdPrice());

    dispatch(processCoinMarketCap());

    let syncPromise = dispatch(loadPortfolioAndPrices(userId, false));

    Promise.resolve(syncPromise)
        .then(() => {
            console.log("PORTFOLIO PRICES LOAD FINISHED!");
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            dispatch(updateUpdatePricesButton(false, false));
            message.destroy();
            dispatch(updatePortfolioTablesSpinner(false));

            notification["success"]({
                message: i18n.t("notifications.pricesUpdateSuccess.title"),
                description: i18n.t("notifications.pricesUpdateSuccess.message"),
                placement: "bottomRight",
            });
        })
        .catch((err) => {
            dispatch(updateDatasourcesSelect(false));
            dispatch(updateLoadAccountButton(false, false));
            dispatch(updateUpdatePricesButton(false, false));
            message.destroy();
            dispatch(updatePortfolioTablesSpinner(false));
            console.log(err);
            notification["error"]({
                message: i18n.t("common.dataLoadFailed.title"),
                description: i18n.t("common.dataLoadFailed.message"),
                placement: "bottomRight",
            });
        });
};

export const processLoadAllData = (userId) => (dispatch) => {
    console.log("SETTINGS LOAD STARTED!");
    dispatch(updateDatasourcesTableSpinner(true));

    dispatch(clearDatasourcesData());

    let syncArr = [];
    syncArr.push(dispatch(loadUserPreferences(userId)));
    syncArr.push(dispatch(loadDatasourcesData(userId)));

    Promise.all(syncArr)
        .then((results) => {
            console.log("SETTINGS DATA LOAD FINISHED!");

            dispatch(updateDatasourcesTableSpinner(false));

            if (results !== undefined && results.length === 2 && results[0] !== undefined && results[1] !== undefined) {
                dispatch(updateLanguage(results[0].language));
                dispatch(updateBaseCoin(results[0].baseCoin));

                /*notification["success"]({
                    message: i18n.t("notifications.settingsLoadSuccess.title"),
                    description: i18n.t("notifications.settingsLoadSuccess.message"),
                    placement: "bottomRight",
                });*/

                dispatch(updateDatasourcesSelect(true));
                dispatch(updateLoadAccountButton(true, false));
                dispatch(updateUpdatePricesButton(true, true));
                dispatch(updatePortfolioTablesSpinner(true));
                message.loading(i18n.t("progress.portfolioLoading"), 0);

                let lastStatePromise = dispatch(loadLastState(userId));

                Promise.resolve(lastStatePromise)
                    .then(() => {
                        dispatch(updateDatasourcesSelect(false));
                        dispatch(updateLoadAccountButton(false, false));
                        dispatch(updateUpdatePricesButton(false, false));
                        dispatch(updatePortfolioTablesSpinner(false));
                        message.destroy();
                    })
                    .catch((err) => {
                        dispatch(updateDatasourcesSelect(false));
                        dispatch(updateLoadAccountButton(false, false));
                        dispatch(updateUpdatePricesButton(false, false));
                        dispatch(updatePortfolioTablesSpinner(false));
                        message.destroy();

                        console.log(err);
                        notification["error"]({
                            message: i18n.t("common.dataLoadFailed.title"),
                            description: i18n.t("common.dataLoadFailed.message"),
                            placement: "bottomRight",
                        });
                    });
            }
        })
        .catch((err) => {
            dispatch(updateDatasourcesTableSpinner(false));
            console.log(err);
            notification["error"]({
                message: i18n.t("common.dataLoadFailed.title"),
                description: i18n.t("common.dataLoadFailed.message"),
                placement: "bottomRight",
            });
        });
};

export const processCoinMarketCap = () => (dispatch) => {
    dispatch(updateCoinMarketCapTablesSpinner(true));

    return Promise.resolve(dispatch(loadCoinMarketCapInfo()))
        .then(() => {
            dispatch(updateCoinMarketCapTablesSpinner(false));
        })
        .catch((err) => {
            dispatch(updateCoinMarketCapTablesSpinner(false));
        });
};
