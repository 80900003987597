import React, { Component } from "react";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Tooltip from "antd/lib/tooltip";
import Spin from "antd/lib/spin";
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import EditDatasourceDialog from "./../components/EditDatasourceDialog";

import { DATASOURCE_NAMES_STYLED } from "./../common/constants";
import i18n from "./../common/i18n";

class DatasourcesTable extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();
    }

    getInitState() {
        let availableDatasources = [];
        if (this.props.data.datasources != null) {
            let supportedDatasources = ["binance", "kucoin", "bittrex"];
            let alreadyPresent = new Set(this.props.data.datasources.map((item) => item.name));
            for (let item of supportedDatasources) {
                if (alreadyPresent.has(item)) {
                    continue;
                }
                availableDatasources.push(item);
            }
        }

        let selectedValue = availableDatasources.length === 0 ? "" : availableDatasources[0];

        return {
            isVisible: false,
            isNewItem: true,
            availableDatasources: availableDatasources,
            datasourceData: {
                type: "exchange",
                name: selectedValue,
                key: "",
                secret: "",
                autoload: true,
            },
        };
    }

    onDeleteButtonClick(datasourceName) {
        Modal.confirm({
            title: i18n.t("settings.buttons.delete.confirm.title"),
            content: i18n.t("settings.buttons.delete.confirm.message"),
            onOk: () => {
                this.props.deleteDatasource(datasourceName);
            },
            onCancel() {},
            okText: i18n.t("common.yesLabel"),
            cancelText: i18n.t("common.noLabel"),
        });
    }

    showDialog(isNewItem, stateToEdit) {
        let datasourceData = stateToEdit;
        let availableDatasources;
        if (isNewItem) {
            let initState = this.getInitState();
            datasourceData = initState.datasourceData;
            if (datasourceData.autoload === undefined) {
                datasourceData.autoload = true;
            }
            availableDatasources = initState.availableDatasources;
        } else {
            availableDatasources = [datasourceData.name];
        }

        if (availableDatasources.length === 0) {
            Modal.error({
                title: i18n.t("settings.noAvailableDatasources.title"),
                content: i18n.t("settings.noAvailableDatasources.message"),
            });
        } else {
            this.setState({
                isVisible: true,
                isNewItem: isNewItem,
                availableDatasources: availableDatasources,
                datasourceData: datasourceData,
                autoload: true,
            });
        }
    }

    hideDialog() {
        this.setState({
            ...this.state,
            isVisible: false,
        });
    }

    getColumnsMetadata() {
        return [
            {
                title: i18n.t("settings.table.headers.datasource"),
                dataIndex: "name",
                render: (text, record, index) => DATASOURCE_NAMES_STYLED[record.name],
            },
            {
                title: i18n.t("settings.table.headers.type"),
                dataIndex: "type",
            },
            {
                title: i18n.t("settings.table.headers.autoload"),
                dataIndex: "autoload",
                render: (text, record, index) => i18n.t(record.autoload ? "common.yesLabel" : "common.noLabel"),
            },
            {
                title: i18n.t("settings.table.headers.actions"),
                dataIndex: "actions",
                render: (text, record, index) => {
                    return (
                        <div>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    this.showDialog(false, record);
                                }}
                            >
                                {i18n.t("settings.buttons.edit.label")}
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                icon={<DeleteOutlined />}
                                type="danger"
                                onClick={() => {
                                    this.onDeleteButtonClick(record.name);
                                }}
                            >
                                {i18n.t("settings.buttons.delete.label")}
                            </Button>
                        </div>
                    );
                },
            },
        ];
    }

    headerData() {
        return (
            <div style={{ textAlign: "left", padding: "10px 10px 10px 0px" }}>
                <Tooltip placement="top" title={i18n.t("settings.buttons.add.tooltip")}>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                            this.showDialog(true);
                        }}
                    >
                        {i18n.t("settings.buttons.add.label")}
                    </Button>
                </Tooltip>
                <EditDatasourceDialog
                    data={this.state}
                    onOk={(datasourceData) => {
                        this.hideDialog();
                        this.props.saveDatasource(datasourceData);
                    }}
                    onCancel={() => {
                        this.hideDialog();
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div>
                <Spin spinning={this.props.isLoading} size="large">
                    {this.headerData()}
                    <Table
                        rowKey="name"
                        columns={this.getColumnsMetadata()}
                        dataSource={this.props.data.datasources}
                        pagination={{ pageSize: 100, hideOnSinglePage: true }}
                        size="middle"
                        locale={{
                            filterReset: i18n.t("common.resetLabel"),
                            emptyText: i18n.t("common.noData"),
                        }}
                    />
                    {this.headerData()}
                </Spin>
            </div>
        );
    }
}

export default DatasourcesTable;
