import i18n from "./../common/i18n";

export const updateDatasources = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_DATASOURCES", data: data });
};

export const updateBaseCoin = (baseCoin) => (dispatch) => {
    dispatch({ type: "UPDATE_BASE_COIN", baseCoin: baseCoin });
};

export const updateLanguage = (language) => (dispatch) => {
    i18n.changeLanguage(language);
    dispatch({ type: "UPDATE_LANGUAGE", language: language });
};

export const updateLoadAccountButton = (isLoading, isDisabled) => (dispatch) => {
    dispatch({ type: "UPDATE_LOAD_ACCOUNT_BUTTON", data: { isLoading: isLoading, isDisabled: isDisabled } });
};

export const updateUpdatePricesButton = (isLoading, isDisabled) => (dispatch) => {
    dispatch({ type: "UPDATE_UPDATE_PRICES_BUTTON", data: { isLoading: isLoading, isDisabled: isDisabled } });
};

export const updateDatasourcesSelect = (isDisabled) => (dispatch) => {
    dispatch({ type: "UPDATE_DATASOURCES_SELECT", data: { isDisabled: isDisabled } });
};

export const updateGlobalSpinner = (isLoading, text) => (dispatch) => {
    dispatch({ type: "UPDATE_GLOBAL_SPINNER", data: { isLoading: isLoading, text: text } });
};

export const updateDatasourcesTableSpinner = (isLoading) => (dispatch) => {
    dispatch({ type: "UPDATE_DATASOURCES_TABLE_SPINNER", data: { isLoading: isLoading } });
};

export const updateDepositsTableSpinner = (isLoading) => (dispatch) => {
    dispatch({ type: "UPDATE_DEPOSITS_TABLE_SPINNER", data: { isLoading: isLoading } });
};

export const updatePortfolioTablesSpinner = (isLoading) => (dispatch) => {
    dispatch({ type: "UPDATE_PORTFOLIO_TABLES_SPINNER", data: { isLoading: isLoading } });
};

export const updateCoinMarketCapTablesSpinner = (isLoading) => (dispatch) => {
    dispatch({ type: "UPDATE_COINMARKETCAP_TABLES_SPINNER", data: { isLoading: isLoading } });
};
