const initialState = {};

export default function processDatasourcesEvents(state = initialState, action) {
    switch (action.type) {
        case "CLEAR_DATASOURCES_DATA":
            return { ...state, list: [] };

        case "LOAD_DATASOURCES_DATA_SUCCEEDED":
            return { ...state, list: action.data };

        case "SAVE_DATASOURCE_DATA_SUCCEEDED":
            let hasValueBeenFound = false;
            let updatedList = state.list.map((item) => {
                if (item.name === action.data.name) {
                    hasValueBeenFound = true;
                    return action.data;
                }

                return item;
            });

            if (!hasValueBeenFound) {
                updatedList.push(action.data);
            }

            return { ...state, list: updatedList };

        case "DELETE_DATASOURCE_DATA_SUCCEEDED":
            let listAfterDelete = state.list.filter((item) => item.name !== action.datasourceName);
            return { ...state, list: listAfterDelete };

        default:
            break;
    }

    return state;
}
