// @flow
import React, { PureComponent } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import { withTranslation } from "react-i18next";

import { formatBtc, formatUsd } from "./../common/utils";

import type { DepositsTotalInfo } from "./../common/types";

type Props = {
    data: DepositsTotalInfo,
    t: Function,
};
class DepositsHeader extends PureComponent<Props> {
    render() {
        const { data, t } = this.props;

        let depositedValue = <span className="selectedColor">{t("common.noData")}</span>;

        if (data !== undefined) {
            depositedValue = (
                <span className="mediumBlueText">
                    {formatBtc(data.depositedBalanceInBtc)}
                    &nbsp;/&nbsp;
                    {formatUsd(data.depositedBalanceInUsd)}
                </span>
            );
        }

        return (
            <Row type="flex" justify="center" style={{ paddingBottom: "10px" }}>
                <Col>
                    <Card>
                        <Card.Meta title={depositedValue} description={t("deposits.totalbalance")} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default withTranslation()(DepositsHeader);
