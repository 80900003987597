import React from "react";

export const LANGUAGES = ["english", "russian"];

export const LANGUAGE_NAMES = {
    [LANGUAGES[0]]: "English",
    [LANGUAGES[1]]: "Русский",
};

export const BASE_COINS = ["btc", "usd"];

export const DEFAULT_USER_PREFERENCES = {
    language: LANGUAGES[0],
    baseCoin: BASE_COINS[0],
};

export const TRADING_URLS = {
    binance: "https://www.binance.com/en/trade/",
    kucoin: "https://trade.kucoin.com/",
    bittrex: "https://bittrex.com/Market/Index?MarketName=",
};

export const DATASOURCE_COLORS = {
    binance: "rgb(240, 185, 11)",
    kucoin: "#23af91",
    bittrex: "rgb(51, 51, 51)",
    multiple: "darkblue",
};

export const PROFIT_COLORS = {
    profit: "#5cb85c",
    loss: "red",
};

export const LOCATIONS = {
    general: "/",
    portfolio: "/portfolio",
    deposits: "/deposits",
    trades: "/trades",
    listedCoins: "/listedCoins",
    settings: "/settings",
    help: "/help",
};

export const DATASOURCE_NAMES_STYLED = {
    binance: <span style={{ fontSize: 14, color: DATASOURCE_COLORS.binance }}>BINANCE</span>,
    kucoin: <span style={{ fontSize: 14, color: DATASOURCE_COLORS.kucoin }}>KuCoin</span>,
    bittrex: <span style={{ fontSize: 14, color: DATASOURCE_COLORS.bittrex }}>BITTREX</span>,
};

export const DATASOURCE_NAMES_STYLED_MINI = {
    binance: () => <span style={{ fontSize: 10, color: DATASOURCE_COLORS.binance }}>BINANCE</span>,
    kucoin: () => <span style={{ fontSize: 10, color: DATASOURCE_COLORS.kucoin }}>KuCoin</span>,
    bittrex: () => <span style={{ fontSize: 10, color: DATASOURCE_COLORS.bittrex }}>BITTREX</span>,
    multiple: (text) => <span style={{ fontSize: 10, color: DATASOURCE_COLORS.multiple }}>{text}</span>,
};

export const EMAIL_FROM_ADDRESS = "cryptoace.stats@gmail.com";

export const SUPPORTED_COIN_ICONS = new Set([
    "ADA",
    "ADC",
    "AEON",
    "AMP",
    "ANC",
    "ARCH",
    "ARDR",
    "ARK ",
    "AST",
    "AUR",
    "BANX",
    "BAT",
    "BAY",
    "BC",
    "BCN",
    "BCY",
    "BELA",
    "BFT",
    "BNB",
    "BLZ",
    "BRK",
    "BRX",
    "BSD",
    "BTA",
    "BTC",
    "BCH",
    "BTCD",
    "BTCP",
    "BTG",
    "BTM",
    "BTS",
    "BURST",
    "CLAM",
    "CLOAK",
    "CVC",
    "DAO",
    "DASH",
    "DCR",
    "DCT",
    "DGB",
    "DGD",
    "DGX",
    "DMD",
    "DOGE",
    "EMC",
    "EMC2",
    "EOS",
    "ERC",
    "ETC",
    "ETH",
    "EXP",
    "FC2",
    "FCT",
    "FLDC",
    "FLO",
    "FRK",
    "FTC",
    "FUN",
    "GAME",
    "GBYTE",
    "GDC",
    "GEMZ",
    "GLD",
    "GNO",
    "GNT",
    "GOLOS",
    "GRC",
    "GRS",
    "HEAT",
    "HSR",
    "HUC",
    "ICN",
    "ICX",
    "IFC",
    "INCNT",
    "IOC",
    "IOTA",
    "JBS",
    "KMD",
    "KOBO",
    "KORE",
    "LBC",
    "LDOGE",
    "LSK",
    "LTC",
    "MAID",
    "MARKS",
    "MCO",
    "MINT",
    "MONA",
    "MRC",
    "MSC",
    "MTL",
    "MTR",
    "MUE",
    "MYST",
    "NAV",
    "NBT",
    "NEO",
    "NEOS",
    "NEU",
    "NLG",
    "NMC",
    "NMR",
    "NOTE",
    "NVC",
    "NXT",
    "OK",
    "OMG",
    "OMNI",
    "OPAL",
    "PART",
    "PASC",
    "PIGGY",
    "PINK",
    "PIVX",
    "POT",
    "PPC",
    "PPT",
    "QRK",
    "QTUM",
    "RADS",
    "RBIES",
    "RBT",
    "RBY",
    "RDD",
    "REP",
    "RIC",
    "RHOC",
    "RISE",
    "SALT",
    "SAR",
    "SCOT",
    "SDC",
    "SIA",
    "SJCX",
    "SLG",
    "SLS",
    "SNRG",
    "SNT",
    "START",
    "STEEM",
    "STRAT",
    "SWIFT",
    "SYNC",
    "SYS",
    "TRIG",
    "TRX",
    "TX",
    "UBQ",
    "UNITY",
    "USDT",
    "VERI",
    "VET",
    "VIA",
    "VIOR",
    "VNL",
    "VPN",
    "VRC",
    "VTC",
    "WAVES",
    "XAI",
    "XBC",
    "XBS",
    "XCP",
    "XEM",
    "XLM",
    "XMR",
    "XMY",
    "XPM",
    "XRP",
    "XTZ",
    "XVC",
    "XVG",
    "XZC",
    "YBC",
    "ZEC",
    "ZEIT",
    "ZRX",
]);
