import React, { Component } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Alert from "antd/lib/alert";
import Checkbox from "antd/lib/checkbox";
import { UserOutlined, MailOutlined, LockOutlined  } from '@ant-design/icons';

import { getLogoWithTitle } from "./../common/uiUtils";
import { isEmailValid, hashPassword, isPasswordMediumValid, isUsernameValid } from "./../common/utils";

import i18n from "./../common/i18n";

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.getEmptyState();
    }

    getEmptyState() {
        return {
            username: "",
            email: "",
            password: "",
            confirmation: "",
            acceptTerms: false,
            error: "",
            errorField: "",
        };
    }

    updateState(key, value) {
        if (key === this.state.errorField) {
            this.setState({ ...this.state, [key]: value, errorField: "", error: "" });
        } else {
            this.setState({ ...this.state, [key]: value });
        }
    }

    onSubmit() {
        if (!isUsernameValid(this.state.username)) {
            this.setState({
                ...this.state,
                error: i18n.t("registration.errors.usernameInvalid"),
                errorField: "username",
            });
            return;
        }

        if (!isEmailValid(this.state.email)) {
            this.setState({
                ...this.state,
                error: i18n.t("registration.errors.emailInvalid"),
                errorField: "email",
            });
            return;
        }

        if (!isPasswordMediumValid(this.state.password)) {
            this.setState({
                ...this.state,
                error: i18n.t("common.errors.invalidPassword"),
                errorField: "password",
            });
            return;
        }

        if (this.state.password === this.state.username) {
            this.setState({
                ...this.state,
                error: i18n.t("registration.errors.passwordIsUsername"),
                errorField: "password",
            });
            return;
        }

        if (this.state.password !== this.state.confirmation) {
            this.setState({
                ...this.state,
                error: i18n.t("common.errors.confirmationFailed"),
                errorField: "confirmation",
            });
            return;
        }

        if (!this.state.acceptTerms) {
            this.setState({
                ...this.state,
                error: i18n.t("registration.errors.termsWereNotAccepted"),
                errorField: "acceptTerms",
            });
            return;
        }

        this.props.onSubmitButtonClick({
            user: this.state.username,
            email: this.state.email,
            password: hashPassword(this.state.password),
        });
    }

    onCancel() {
        this.props.onCancelButtonClick();
        this.setState(this.getEmptyState());
    }

    render() {
        let errorBlock;
        if (this.props.error !== "") {
            errorBlock = (
                <div>
                    <Alert message={this.props.error} type="error" />
                    <br />
                </div>
            );
        }

        return (
            <Modal
                title={getLogoWithTitle(i18n.t("registration.title"))}
                visible={this.props.isVisible}
                onOk={() => {
                    this.onSubmit();
                }}
                okText={i18n.t("common.submitLabel")}
                cancelText={i18n.t("common.cancelLabel")}
                confirmLoading={this.props.isLoading}
                onCancel={() => {
                    this.onCancel();
                }}
                maskClosable={false}
                width={480}
            >
                {errorBlock}
                <Form layout="horizontal">
                    <Form.Item
                        label={i18n.t("registration.fields.usernameLabel")}
                        validateStatus={this.state.errorField === "username" ? "error" : "success"}
                        hasFeedback={this.state.errorField === "username"}
                        help={this.state.errorField === "username" ? this.state.error : ""}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        required
                    >
                        <Input
                            value={this.state.username}
                            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            onChange={(event) => {
                                this.updateState("username", event.target.value);
                            }}
                            placeholder={i18n.t("registration.fields.usernamePlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("registration.fields.emailLabel")}
                        validateStatus={this.state.errorField === "email" ? "error" : "success"}
                        hasFeedback={this.state.errorField === "email"}
                        help={this.state.errorField === "email" ? this.state.error : ""}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        required
                    >
                        <Input
                            type="email"
                            value={this.state.email}
                            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            onChange={(event) => {
                                this.updateState("email", event.target.value);
                            }}
                            placeholder={i18n.t("registration.fields.emailPlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("registration.fields.passwordLabel")}
                        validateStatus={this.state.errorField === "password" ? "error" : "success"}
                        hasFeedback={this.state.errorField === "password"}
                        help={this.state.errorField === "password" ? this.state.error : ""}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        required
                    >
                        <Input
                            type="password"
                            value={this.state.password}
                            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            onChange={(event) => {
                                this.updateState("password", event.target.value);
                            }}
                            placeholder={i18n.t("registration.fields.passwordPlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item
                        label={i18n.t("registration.fields.confirmationLabel")}
                        validateStatus={this.state.errorField === "confirmation" ? "error" : "success"}
                        hasFeedback={this.state.errorField === "confirmation"}
                        help={this.state.errorField === "confirmation" ? this.state.error : ""}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        required
                    >
                        <Input
                            type="password"
                            value={this.state.confirmation}
                            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            onChange={(event) => {
                                this.updateState("confirmation", event.target.value);
                            }}
                            placeholder={i18n.t("registration.fields.confirmationPlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item
                        className="textAlignCenter"
                        validateStatus={this.state.errorField === "acceptTerms" ? "error" : "success"}
                        hasFeedback={this.state.errorField === "acceptTerms"}
                        help={this.state.errorField === "acceptTerms" ? this.state.error : ""}
                    >
                        <Checkbox
                            style={{ width: "100%" }}
                            checked={this.state.acceptTerms}
                            onChange={(event) => {
                                this.updateState("acceptTerms", event.target.checked);
                            }}
                        >
                            {i18n.t("registration.fields.acceptLabel")}
                            <a className="requiredMark" href="#accept">
                                {i18n.t("registration.fields.acceptTermsLinkLabel")}
                            </a>
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default RegistrationForm;
