import { combineReducers } from "redux";

import user from "./user";
import account from "./account";
import datasources from "./datasources";
import uiControl from "./uiControl";

export default combineReducers({
    user,
    account,
    datasources,
    uiControl,
});
