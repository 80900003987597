export const prepareDepositPrice = (datasource, coin, coinPair, symbol, quantity, timestamp, price, tradePrices) => {
    let pricesForDatasource = new Map(tradePrices.get(datasource));

    let pricesForCoin = pricesForDatasource.get(coin);
    if (pricesForCoin === undefined) {
        pricesForCoin = new Map();
    }

    let pricesPerPair = pricesForCoin.get(coinPair);

    if (pricesPerPair === undefined) {
        pricesPerPair = [];
    }

    pricesPerPair.push({
        symbol: symbol,
        price: price,
        quantity: quantity,
        timestamp: timestamp,
        isTrade: false,
        originalSymbol: coin,
    });

    pricesForCoin.set(coinPair, pricesPerPair);

    pricesForDatasource.set(coin, pricesForCoin);

    tradePrices.set(datasource, pricesForDatasource);

    return tradePrices;
};

export const prepareDepositsData = (dataToUpdate, data, isDeposit) => {
    let result = new Map(dataToUpdate);

    for (let item of data) {
        let uid = item.coin + item.datasource;
        let info = result.get(uid);
        if (info === undefined) {
            info = {
                uid: uid,
                coin: item.coin,
                datasource: item.datasource,
                depositsData: new Map(),
                withdrawalsData: new Map(),
                deposits: 0.0,
                withdrawals: 0.0,
            };
        }

        if (isDeposit) {
            info.deposits += item.amount;
            info.depositsData.set(item.timestamp, item);
        } else {
            info.withdrawals += item.amount;
            info.withdrawalsData.set(item.timestamp, item);
        }

        result.set(uid, info);
    }

    return result;
};

export const calculateDepositsData = (state) => {
    let result = new Map(state.deposits);

    let totalBalanceInBtc = 0.0;
    let totalBalanceInUsd = 0.0;

    result.forEach((value, key, map) => {
        let coinCalculatedData = {
            totalDepositsInBtc: 0.0,
            totalWithdrawalsInBtc: 0.0,
            totalDepositsInUsd: 0.0,
            totalWithdrawalsInUsd: 0.0,
        };

        coinCalculatedData = calculateDepositCoinData(value, state.depositPrices);

        let balanceInBtc = coinCalculatedData.totalDepositsInBtc - coinCalculatedData.totalWithdrawalsInBtc;
        totalBalanceInBtc += balanceInBtc;
        let balanceInUsd = coinCalculatedData.totalDepositsInUsd - coinCalculatedData.totalWithdrawalsInUsd;
        totalBalanceInUsd += balanceInUsd;

        // sort by date DESC:
        coinCalculatedData.inAndOuts.sort(function(a, b) {
            return b.timestamp - a.timestamp;
        });

        map.set(key, {
            ...value,
            deposits: value.deposits,
            withdrawals: value.withdrawals,
            diff: value.deposits - value.withdrawals,
            depositsInBtc: coinCalculatedData.totalDepositsInBtc,
            withdrawalsInBtc: coinCalculatedData.totalWithdrawalsInBtc,
            diffInBtc: balanceInBtc,
            depositsInUsd: coinCalculatedData.totalDepositsInUsd,
            withdrawalsInUsd: coinCalculatedData.totalWithdrawalsInUsd,
            diffInUsd: balanceInUsd,
            inAndOuts: coinCalculatedData.inAndOuts,
        });
    });

    //console.log(result, state.depositPrices, totalBalanceInBtc);

    state.deposits = result;
    state.totalDepositsData = { depositedBalanceInBtc: totalBalanceInBtc, depositedBalanceInUsd: totalBalanceInUsd };

    return state;
};

const calculateDepositCoinData = (currentCoinInfo, depositPrices) => {
    let result = {
        totalDepositsInBtc: 0.0,
        totalWithdrawalsInBtc: 0.0,
        totalDepositsInUsd: 0.0,
        totalWithdrawalsInUsd: 0.0,
        inAndOuts: [],
    };

    let foundDatasource = depositPrices.get(currentCoinInfo.datasource);
    if (foundDatasource === undefined) {
        return result;
    }

    let foundCoin = foundDatasource.get(currentCoinInfo.coin);
    if (foundCoin === undefined) {
        return result;
    }

    let foundBtcCoinPair = foundCoin.get("BTC");
    if (foundBtcCoinPair === undefined && currentCoinInfo.coin !== "BTC") {
        return result;
    }

    let foundUsdCoinPair = foundCoin.get("USDT");
    if (foundUsdCoinPair === undefined && currentCoinInfo.coin !== "USDT") {
        return result;
    }

    if (currentCoinInfo.coin === "USDT") {
        foundUsdCoinPair = foundBtcCoinPair;
    }

    let uidIndex = 0;
    for (let priceItem of foundUsdCoinPair) {
        let priceInBtc = -1;
        switch (currentCoinInfo.coin) {
            case "BTC":
                priceInBtc = 1.0;
                break;
            
            case "USDT":
                priceInBtc = 1.0 / getPriceForTimestamp(priceItem.timestamp, foundBtcCoinPair);
                break;

            default:
                priceInBtc = getPriceForTimestamp(priceItem.timestamp, foundBtcCoinPair);
                break;
        }
        
        if (priceInBtc !== -1) {
            let depositData = currentCoinInfo.depositsData.get(priceItem.timestamp);
            if (depositData !== undefined) {
                let depositPriceInBtc = depositData.amount * priceInBtc;
                result.totalDepositsInBtc += depositPriceInBtc;
                let depositPriceInUsd = currentCoinInfo.coin === "USDT" ? depositData.amount : depositPriceInBtc * priceItem.price;
                result.totalDepositsInUsd += depositPriceInUsd;

                result.inAndOuts.push({
                    uid: currentCoinInfo.coin + uidIndex++,
                    isDeposit: true,
                    quantity: depositData.amount,
                    timestamp: depositData.timestamp,
                    priceInBtc: depositPriceInBtc,
                    priceInUsd: depositPriceInUsd,
                    data: depositData,
                });
            }

            let withdrawalData = currentCoinInfo.withdrawalsData.get(priceItem.timestamp);
            if (withdrawalData !== undefined) {
                let withdrawalPriceInBtc = withdrawalData.amount * priceInBtc;
                result.totalWithdrawalsInBtc += withdrawalPriceInBtc;
                let withdrawalPriceInUsd = currentCoinInfo.coin === "USDT" ? withdrawalData.amount : withdrawalPriceInBtc * priceItem.price;
                result.totalWithdrawalsInUsd += withdrawalPriceInUsd;

                result.inAndOuts.push({
                    uid: currentCoinInfo.coin + uidIndex++,
                    isDeposit: false,
                    quantity: withdrawalData.amount,
                    timestamp: withdrawalData.timestamp,
                    priceInBtc: withdrawalPriceInBtc,
                    priceInUsd: withdrawalPriceInUsd,
                    data: withdrawalData,
                });
            }
        }
    }

    return result;
};

const getPriceForTimestamp = (timestamp, prices) => {
    for (let priceItem of prices) {
        if (priceItem.timestamp === timestamp) return priceItem.price;
    }

    return -1;
};
