import React, { Component } from "react";
import Spin from "antd/lib/spin";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"; //TODO: review import

import i18n from "../common/i18n";

class Chart extends Component {
    render() {
        let content = i18n.t("common.noData");
        let leftYAxisName = this.props.isBtcBased ? "btc" : "usd";
        let rightYAxisName = this.props.isBtcBased ? "usd" : "btc";
        let leftYAxis = (
            <Line
                yAxisId="left"
                dataKey={leftYAxisName}
                name={i18n.t("menu." + leftYAxisName)}
                stroke="rgba(85, 96, 123, 0.5)"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 6 }}
            />
        );
        let rightYAxis = (
            <Line
                yAxisId="right"
                dataKey={rightYAxisName}
                name={i18n.t("menu." + rightYAxisName)}
                stroke="rgba(54, 162, 235, 0.7)"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 6 }}
            />
        );
        if (this.props.data.length > 0) {
            content = (
                <LineChart
                    width={this.props.width}
                    height={this.props.height}
                    data={this.props.data}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" orientation="left" stroke="rgba(85, 96, 123, 0.7)" />
                    <YAxis yAxisId="right" orientation="right" stroke="rgba(54, 162, 235, 1)" />
                    <CartesianGrid stroke="rgba(85, 96, 123, 0.10)" />
                    <Tooltip />
                    <Legend />
                    {leftYAxis}
                    {rightYAxis}
                </LineChart>
            );
        }

        return (
            <Spin spinning={this.props.isLoading} size="large">
                {content}
            </Spin>
        );
    }
}

export default Chart;
