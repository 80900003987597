import "./styles/index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

//import { composeWithDevTools } from "redux-devtools-extension";

//import "./common/i18n";

import App from "./containers/App";

import registerServiceWorker from "./registerServiceWorker";

import reducers from "./reducers";

const mainStore = createStore(
    reducers,
    applyMiddleware(thunk)
    //composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={mainStore}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
registerServiceWorker();
