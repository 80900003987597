import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { ENGLISH_TEXT } from "./../localization/english";
import { RUSSIAN_TEXT } from "./../localization/russian";

i18next.use(initReactI18next).init({
    interpolation: {
        // React already does escaping
        escapeValue: false,
        prefix: "__",
        suffix: "__",
    },
    lng: "english",
    resources: {
        english: {
            translation: ENGLISH_TEXT,
        },
        russian: {
            translation: RUSSIAN_TEXT,
        },
    },
    //debug: true,
});

export default i18next;
