import axios from "axios";

import { BACKEND_URL } from "./../common/settings";

export const clearDatasourcesData = () => (dispatch) => {
    return dispatch({ type: "CLEAR_DATASOURCES_DATA" });
};

export const loadDatasourcesData = (userId) => (dispatch) => {
    return axios
        .get(BACKEND_URL + "/settings/datasources", {
            params: {
                userId: userId,
            },
        })
        .then((response) => {
            //console.log(response);

            dispatch({
                type: "LOAD_DATASOURCES_DATA_SUCCEEDED",
                data: response.data.map((item) => item.data),
            });

            dispatch({
                type: "UPDATE_DATASOURCES",
                data: new Set(
                    response.data.filter((item) => item.data.autoload === true).map((item) => item.data.name)
                ),
            });

            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const saveDatasourceData = (userId, datasourceData) => (dispatch) => {
    return axios
        .post(BACKEND_URL + "/settings/datasources/" + datasourceData.name, {
            params: {
                userId: userId,
                data: datasourceData,
            },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: "SAVE_DATASOURCE_DATA_SUCCEEDED",
                data: datasourceData,
            });
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};

export const deleteDatasourceData = (userId, datasourceName) => (dispatch) => {
    return axios
        .delete(BACKEND_URL + "/settings/datasources/" + datasourceName, {
            params: { userId: userId },
        })
        .then((response) => {
            //console.log(response);
            dispatch({
                type: "DELETE_DATASOURCE_DATA_SUCCEEDED",
                datasourceName: datasourceName,
            });
        })
        .catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
};
