import React, { Component } from "react";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import Checkbox from "antd/lib/checkbox";

import { DATASOURCE_NAMES_STYLED } from "./../common/constants";
import i18n from "./../common/i18n";

class EditDatasourceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = props.data.datasourceData;
    }

    updateState(key, value) {
        if (key === this.state.errorField) {
            this.setState({ ...this.state, [key]: value, errorField: "", error: "" });
        } else {
            this.setState({ ...this.state, [key]: value });
        }
    }

    onOk() {
        if (this.state.key === "") {
            this.setState({
                ...this.state,
                error: i18n.t("settings.editDatasourceDialog.errors.keyEmpty"),
                errorField: "key",
            });
            return;
        }

        if (this.state.secret === "") {
            this.setState({
                ...this.state,
                error: i18n.t("settings.editDatasourceDialog.errors.secretEmpty"),
                errorField: "secret",
            });
            return;
        }

        if (this.state.name === "kucoin" && (this.state.passphrase === "" || this.state.passphrase === undefined)) {
            this.setState({
                ...this.state,
                error: i18n.t("settings.editDatasourceDialog.errors.passphraseEmpty"),
                errorField: "passphrase",
            });
            return;
        }

        this.props.onOk({
            type: this.state.type,
            name: this.state.name,
            key: this.state.key,
            secret: this.state.secret,
            passphrase: this.state.passphrase,
            autoload: this.state.autoload,
        });

        this.setState(this.props.data.datasourceData);
    }

    onCancel() {
        this.setState({ ...this.props.data.datasourceData, errorField: "", error: "" });
        this.props.onCancel();
    }

    //TODO: review this code
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(nextProps.data.datasourceData);
    }

    generateDatasourcesSelect() {
        if (this.props.data.availableDatasources == null || this.props.data.availableDatasources.length === 0) {
            return "";
        }

        let availableOptions = [];
        for (let item of this.props.data.availableDatasources) {
            availableOptions.push(
                <Select.Option key={item + "option"} value={item}>
                    {DATASOURCE_NAMES_STYLED[item]}
                </Select.Option>
            );
        }

        if (availableOptions.length === 0) {
            return "";
        }

        return (
            <Select
                value={this.state.name}
                onChange={(newValue) => {
                    this.updateState("name", newValue);
                }}
                style={{ width: 120 }}
            >
                {availableOptions}
            </Select>
        );
    }

    render() {
        if (!this.props.data.isVisible) {
            return "";
        }

        let title = this.props.data.isNewItem
            ? i18n.t("settings.editDatasourceDialog.titleAdd")
            : i18n.t("settings.editDatasourceDialog.titleEdit");

        return (
            <div>
                <Modal
                    title={title}
                    visible={this.props.data.isVisible}
                    onOk={() => {
                        this.onOk();
                    }}
                    onCancel={() => {
                        this.onCancel();
                    }}
                    maskClosable={false}
                >
                    <Form layout="vertical">
                        <Form.Item label={i18n.t("settings.editDatasourceDialog.fields.type")}>
                            <Select
                                value={this.state.type}
                                onChange={(newValue) => {
                                    this.updateState("type", newValue);
                                }}
                                style={{ width: 120 }}
                            >
                                <Select.Option value="exchange">Exchange</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label={i18n.t("settings.editDatasourceDialog.fields.name")}>
                            {this.generateDatasourcesSelect()}
                        </Form.Item>
                        <Form.Item
                            label={i18n.t("settings.editDatasourceDialog.fields.key")}
                            validateStatus={this.state.errorField === "key" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "key"}
                            help={this.state.errorField === "key" ? this.state.error : ""}
                        >
                            <Input
                                value={this.state.key}
                                onChange={(event) => {
                                    this.updateState("key", event.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={i18n.t("settings.editDatasourceDialog.fields.secret")}
                            validateStatus={this.state.errorField === "secret" ? "error" : "success"}
                            hasFeedback={this.state.errorField === "secret"}
                            help={this.state.errorField === "secret" ? this.state.error : ""}
                        >
                            <Input
                                type="password"
                                value={this.state.secret}
                                onChange={(event) => {
                                    this.updateState("secret", event.target.value);
                                }}
                            />
                        </Form.Item>
                        {this.state.name === "kucoin" && (
                            <Form.Item
                                label={i18n.t("settings.editDatasourceDialog.fields.passphrase")}
                                validateStatus={this.state.errorField === "passphrase" ? "error" : "success"}
                                hasFeedback={this.state.errorField === "passphrase"}
                                help={this.state.errorField === "passphrase" ? this.state.error : ""}
                            >
                                <Input
                                    value={this.state.passphrase}
                                    onChange={(event) => {
                                        this.updateState("passphrase", event.target.value);
                                    }}
                                />
                            </Form.Item>
                        )}
                        <Form.Item>
                            <Checkbox
                                checked={this.state.autoload}
                                onChange={(event) => {
                                    this.updateState("autoload", event.target.checked);
                                }}
                            >
                                {i18n.t("settings.editDatasourceDialog.fields.autoload")}
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default EditDatasourceDialog;
