import React, { Component } from "react";
import { connect } from "react-redux";
import Tabs from "antd/lib/tabs";
import notification from "antd/lib/notification";

import { saveUserPreferences, login, changePassword, deleteAccount } from "./../actions/user";
import { saveDatasourceData, deleteDatasourceData } from "./../actions/datasources";
import { updateDatasources, updateLoadAccountButton, updateBaseCoin, updateLanguage } from "./../actions/uiControl";

import { processLoadAllData } from "./../actions/processingHelper";

import DatasourcesTable from "./../components/DatasourcesTable";
import UserPreferencesForm from "./../components/UserPreferencesForm";
import Security from "./../components/Security";

import i18n from "./../common/i18n";

class Settings extends Component {
    onSaveDatasourceDataButtonClick = (datasourceData) => {
        this.props.data.selectedDatasources.add(datasourceData.name);
        this.props.saveDatasourceData(this.props.data.userId, datasourceData, this.props.data.selectedDatasources);
    };

    onBaseCoinUpdate = (newBaseCoin) => {
        this.props.updateBaseCoin(newBaseCoin);
    };

    onLanguageUpdate = (newLanguage) => {
        this.props.updateLanguage(newLanguage);
    };

    onSaveUserPreferencesButtonClick = (data) => {
        this.props.saveUserPreferences(this.props.data.userId, data);
    };

    onDeleteDatasourceDataButtonClick = (datasourceName) => {
        this.props.data.selectedDatasources.delete(datasourceName);
        this.props.deleteDatasourceData(
            this.props.data.userId,
            datasourceName,
            this.props.data.selectedDatasources,
            this.props.data.selectedDatasources
        );
    };

    onConfirmChangePassword = (data) => {
        this.props.changePassword(this.props.data.userId, data);
    };

    validateCurrentLogin = (data) => {
        return this.props.validateCurrentLogin(this.props.data.userId, data);
    };

    onDeleteAccountButtonClick = () => {
        this.props.deleteAccount(this.props.data.userId);
    };

    render() {
        return (
            <Tabs defaultActiveKey="datasources" size="large" tabPosition="left">
                <Tabs.TabPane tab={i18n.t("settings.tabs.datasources")} key="datasources">
                    <DatasourcesTable
                        data={this.props.data}
                        saveDatasource={this.onSaveDatasourceDataButtonClick}
                        deleteDatasource={this.onDeleteDatasourceDataButtonClick}
                        isLoading={this.props.data.isDatasourcesTableLoading}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("settings.tabs.userPreferences")} key="userPreferences">
                    <UserPreferencesForm
                        data={this.props.data}
                        saveUserPreferences={this.onSaveUserPreferencesButtonClick}
                        onLanguageUpdate={this.onLanguageUpdate}
                        onBaseCoinUpdate={this.onBaseCoinUpdate}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("settings.tabs.security")} key="security">
                    <Security
                        onConfirmChangePassword={this.onConfirmChangePassword}
                        validateCurrentLogin={this.validateCurrentLogin}
                        onDeleteAccountButtonClick={this.onDeleteAccountButtonClick}
                    />
                </Tabs.TabPane>
            </Tabs>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: {
        isDatasourcesTableLoading: state.uiControl.datasourcesTable.isLoading,
        datasources: state.datasources.list === undefined ? null : state.datasources.list,
        selectedDatasources: state.uiControl.datasources === undefined ? new Set() : state.uiControl.datasources,
        userPreferences: state.user.preferences,
        userId: state.user.data.login,
    },
    ownProps,
});

const mapDispatchToProps = (dispatch) => ({
    saveDatasourceData: (userId, datasourceData, updatedSelectedDatasources) => {
        Promise.resolve(dispatch(saveDatasourceData(userId, datasourceData)))
            .then(() => {
                dispatch(processLoadAllData(userId));
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(updateDatasources(updatedSelectedDatasources));
        dispatch(updateLoadAccountButton(false, false));
    },
    deleteDatasourceData: (userId, datasourceName, updatedSelectedDatasources) => {
        Promise.resolve(dispatch(deleteDatasourceData(userId, datasourceName)))
            .then(() => {
                dispatch(processLoadAllData(userId));
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(updateDatasources(updatedSelectedDatasources));
        dispatch(updateLoadAccountButton(false, updatedSelectedDatasources.size === 0));
    },
    saveUserPreferences: (userId, data) => {
        dispatch(saveUserPreferences(userId, data));
        dispatch(updateBaseCoin(data.baseCoin));
        dispatch(updateLanguage(data.language));
    },
    validateCurrentLogin: (userId, data) => {
        return dispatch(login({ login: userId, password: data }));
    },
    deleteAccount: (userId) => {
        dispatch(deleteAccount(userId));
    },
    changePassword: (userId, data) => {
        Promise.resolve(dispatch(changePassword(userId, data)))
            .then(() => {
                notification["success"]({
                    message: i18n.t("notifications.changePasswordSuccess.title"),
                    description: i18n.t("notifications.changePasswordSuccess.message"),
                    placement: "bottomRight",
                });
            })
            .catch((error) => {
                notification["error"]({
                    message: i18n.t("notifications.changePasswordFailed.title"),
                    description: i18n.t("notifications.changePasswordFailed.message"),
                    placement: "bottomRight",
                });
            });
    },
    updateBaseCoin: (value) => {
        dispatch(updateBaseCoin(value));
    },
    updateLanguage: (value) => {
        dispatch(updateLanguage(value));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
