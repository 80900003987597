import React from "react";

export const FAQ_ENGLISH = {
    GENERAL: (
        <div>
            <h3>General Product Questions</h3>
            <br />
            <h4>Why do I need CryptoACE?</h4>
            <p>To begin we need to figure out several things:</p>
            <ul>
                <li>Do you know about cryptocurrency existence?</li>
                <li>Do you have registered to any of cryptocurrency exchanges?</li>
                <li>
                    Would you like to review the most accurate information on your portfolio for each or all
                    cryptocurrency exchanges?
                </li>
            </ul>
            <p>If all the answers are “Yes”, then you are very welcome!</p>
            <br />
            <h4>How can you help me?</h4>
            <p>CryptoACE for sure will be interesting for you into the following factors:</p>
            <ul>
                <li>
                    You DO NOT NEED to manually insert all your balances, trades, deposits/withdrawals in or out of the
                    cryptocurrency exchange. All these things are implemented automatically.
                </li>
                <li>
                    You can maintain all information for each of your coins in terms of all or any of your set up
                    cryptocurrency exchanges.
                </li>
                <li>
                    You have possibility to review all deposits and withdrawals for each coin and cryptocurrency
                    exchange.
                </li>
                <li>We have tried to make our service easy to use.</li>
                <li>We are constantly improving the product by adding new features</li>
            </ul>
            <p>By the way, there is the whole month of free use! Just give a try and you will not be disappointed.</p>
            <br />
            <h4>How does syncing work?</h4>
            <p>
                To get information about your trades on the cryptocurrency exchange we need the access. To get the
                access we need API keys. Such keys you can create on any exchange you are using.
                <br />
                For your convenience on the tab "Setup API Keys" you can find the instructions how to create these keys
                on the particular exchange and necessary settings in CryptoACE.
                <br />
                Each cryptocurrency exchange has API and Secret keys. You should create them at each of supported by
                CryptoACE cryptocurrency exchanges and specify them in CryptoACE User Preferences.
                <br />
                It should be noted, to start working with our application we require keys with read-only keys. Having
                keys of such level of access we cannot make any actions with your exchange account. Therefore, your
                coins are safe.
            </p>
            <br />
            <h4>Can I change interface language and set it by default?</h4>
            <p>
                Click the account icon and select “Settings” menu. User preferences allow you to set your language and
                currency by default. Select preferable and click “Save changes” button.{" "}
            </p>
            <br />
            <h4>I want to change my password. How can I do this?</h4>
            <ol>
                <li>Click the account icon and select “Settings” menu.</li>
                <li>Go to “User preferences”.</li>
                <li>Click “Change password” button and specify your current and new password twice.</li>
                <li>If all information is correct, then all your enterings will be saved successfully.</li>
            </ol>
            <br />
            <h4>How do you refresh the data?</h4>
            <p>
                All information is refreshing at your request. You need to pass to any necessary page, select one or
                several cryptocurrency exchanges and click “Load the data” button.{" "}
            </p>
            <br />
            <h4>Where do you take rate information from?</h4>
            <p>
                Bitcoin rate to US dollar we receive from one of your set up cryptocurrency exchanges. To be specific,
                from the last that has been uploaded during the request. All trading deals rates we receive from
                cryptocurrency exchanges, where it was made at the moment of their performance.
            </p>
            <br />
            <h4>
                Why is there a discrepancy between your listed price for Bitcoin in CryptoACE and the price on my
                exchange?{" "}
            </h4>
            <p>
                For better or worse, there are differences in the price of Bitcoin across exchanges. Part of the reason
                for this is that there are different levels of liquidity and risk associated with different exchanges,
                leading to different amounts of demand and therefore pricing on those exchanges. So, basically, we need
                to show just one price value. We have chosen Bittrex exchange as a long established and the most robust.
                In the future we plan to add an option to select exchange by default.
            </p>
            <br />
            <h4>Do you have native mobile apps?</h4>
            <p>
                Not yet. We have it planned. We also understand how important it is for the today’s world. But we have
                hurried up to release the first version and carefully have polished it up, so that mobile version has
                been deferred till the next version. Follow our news.{" "}
            </p>
            <br />
            <h4>Why don’t you have XYZ feature?</h4>
            <p>
                Simply because it is in development or we do not know that you need it!
                <br />
                Email us at <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>! We love any review. Good ones we
                love more:)
            </p>
        </div>
    ),
    SETUP: (
        <div>
            <h3>Setup API Keys</h3>
            <br />
            <h4>Add new datasource (exchange API key)</h4>
            <ol>
                <li>To add new exchange click “Add” button.</li>
                <li>“Add new datasource” pop-up window appears on the screen.</li>
                <li>
                    Please specify exchange name, API and Secret key and click “OK” button. How to get API and Secret
                    key read here.
                </li>
                <li>
                    If all information is correct, then all your enterings will be saved successfully and new line will
                    be added to the list.
                </li>
                <li>
                    Now you can go to Overview, Portfolio or Deposits and after clicking “Load Account Data” enjoy your
                    stats.
                </li>
            </ol>
            <b>Binance:</b>
            <br />
            Where can I get these keys?
            <ol>
                <li>
                    Go to{" "}
                    <a href="https://www.binance.com/userCenter/createApi.html" rel="external">
                        {" "}
                        Binance API{" "}
                    </a>{" "}
                    page.
                </li>
                <li>
                    Enter the name of your key set in the field located in the header of the page and click “Create New
                    Key”.
                </li>
                <li>Follow Binance instructions for the creation.</li>
                <li>When the key set is created, pay attention to “Read Info” checkbox is clicked. </li>
                <li>Copy API and Secret keys.</li>
                <li>Go back to CryptoACE and insert them to the appropriate fields.</li>
            </ol>
            <b>Bittrex:</b>
            <br />
            Where can I get these keys ?
            <ol>
                <li>
                    Go to{" "}
                    <a href="https://bittrex.com/Manage#sectionApi" rel="external">
                        {" "}
                        Bittrex API{" "}
                    </a>{" "}
                    page.
                </li>
                <li>Click the “Add New Key” button.</li>
                <li>Enable the “Read Info” permission for the new key.</li>
                <li>If applicable, enter your two-factor authentication code.</li>
                <li>Click the “Update Keys” button.</li>
                <li>Copy the Key and Secret below.</li>
                <li>Go back to CryptoACE and insert them to the appropriate fields.</li>
            </ol>
            <b>KuCoin:</b>
            <br />
            Where can I get these keys ?
            <ol>
                <li>
                    Go to{" "}
                    <a href="https://www.kucoin.com/#/user/setting/api" rel="external">
                        {" "}
                        Kucoin API{" "}
                    </a>
                    page.
                </li>
                <li>Go to Kucoin API https://www.kucoin.com/#/user/setting/api page.</li>
                <li>Click the “Create” button.</li>
                <li>Copy the Key and Secret below.</li>
                <li>Go back to CryptoACE and insert them to the appropriate fields.</li>
            </ol>
            <br />
            <h4>Edit datasource</h4>
            <ol>
                <li>
                    To edit setting for the existing exchange you need to click “Edit” button next to necessary
                    exchange.
                </li>
                <li>Enter your corrections and click “OK” button. </li>
                <li>If all information is correct, then all your enterings will be saved successfully.</li>
            </ol>
            <br />
            <h4>Delete datasource</h4>
            <p>To delete datasource you need to click “Delete” button next to necessary exchange.</p>
        </div>
    ),
    PRIVACY: (
        <div>
            <h3>Privacy and Security</h3>
            <br />
            <h4>What about security of my financial data?</h4>
            <p>We agree that security is first of all. And here is why:</p>
            <ul>
                <li>
                    We do not have access to your cryptocurrency exchanges accounts. It is because we are using keys
                    with read-only access.
                </li>
                <li>Therefore, we cannot sell or provide your financial data to third party persons. </li>
                <li>Our website traffic runs entirely over encrypted SSL (https).</li>
                <li>
                    We use SQL injection filters and verify the authenticity of all requests to prevent CSRF attacks.
                </li>
                <li>
                    We do not store your passwords in our database. We hash all passwords with a special hash function.
                </li>
                <li>You can delete all your information in your account at any time and for any reason.</li>
            </ul>
            <br />
            <h4>Is it scam? Do you want to steal my crypto?</h4>
            <p>No. We do not have access to neither any of your private keys, nor crypto withdrawals.</p>
            <br />
            <h4>How can I delete my account? </h4>
            <p>
                You can do it by accessing Settings menu. Select "Security Settings" tab and press "Delete Account"
                button.
            </p>
        </div>
    ),
    PRICING: (
        <div>
            <h3>Pricing</h3>
            <br />
            <h4>Is it free?</h4>
            <p>
                Currently CryptoACE is in open beta-test and absolutely free to use!
                <br />
                Also you can support development (go to "Donate" tab for details).
            </p>
        </div>
    ),
    FEEDBACK: (
        <div>
            <h3>Feedback</h3>
            <br />
            <h4>Your website doesn’t work correctly.</h4>
            <p>
                We’d happy to know about it. Please email us at <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>
                .
            </p>
            <br />
            <h4>I would like to have XYZ feature.</h4>
            <p>
                Please let us know about it. Email us at <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>.
            </p>
            <br />
            <h4>Your service is unbelievable! I want to tell about it to everybody.</h4>
            <p>
                That’s great! You can make it on <a href="https://fb.me/cryptoace.stats">Facebook</a>,{" "}
                <a href="https://twitter.com/cryptoace_stats">Twitter</a>,{" "}
                <a href="https://vk.com/cryptoace">Vkontakte</a>. You can also add our <b>#cryptoacestats</b> hashtag to
                any post with us.
            </p>
            <br />
            <h4>I still have a question, what should I do?</h4>
            <p>
                Email us at <a href="mailto:admin@cryptoace.io">admin@cryptoace.io</a>.
            </p>
        </div>
    ),
    DONATE: (
        <div>
            <h3>Donate</h3>
            <h4>I like CryptoACE and I would like to help you with the development. Can I make a donation?</h4>
            <p>
                We are pleased to hear that. If you'd like to make a monetary contribution, our wallet addresses are:
                <br />
                <br />
                <b>Bitcoin (BTC) address:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>1Ak8FcfHXLDjqMj3T6SmxfRe49BrnUt84i</h3>
                <br />
                <b>Ethereum (ETH) address:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>0x883596978ffe5af007ac725cf7a176e6dfb4a3e1</h3>
                <br />
                <b>Litecoin (LTC) address:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>Lc21cZ8ePtDYs7cmGf3TqT5WKTHTfrYUU3</h3>
                <br />
                <b>Ethereum Classic (ETC) address:</b>
                <br />
                <h3 style={{ color: "mediumblue" }}>0x883596978ffe5af007ac725cf7a176e6dfb4a3e1</h3>
            </p>
        </div>
    ),
};
