// @flow
import React, { PureComponent } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import { withTranslation } from "react-i18next";

import { formatBtc, formatBtcWithSign, formatUsd, formatUsdWithSign, formatPercent } from "./../common/utils";

import type { PortfolioTotalInfo } from "./../common/types";

type Props = {
    data: PortfolioTotalInfo,
    isBtcBased: boolean,
    t: Function,
};

class PortfolioHeader extends PureComponent<Props> {
    estimatedValue() {
        const { data, t } = this.props;

        let estimatedValueText =
            data === undefined ? (
                <span className="selectedColor">{t("common.noData")}</span>
            ) : (
                <span className="mediumBlueText">
                    {formatBtc(data.valueInBtc)}
                    &nbsp;/&nbsp;
                    {formatUsd(data.valueInUsd)}
                </span>
            );

        return (
            <Card>
                <Card.Meta title={estimatedValueText} description={t("portfolio.estimatedValue")} />
            </Card>
        );
    }

    totalProfit() {
        const { data, isBtcBased, t } = this.props;

        let totalProfitText = <span className="selectedColor">{t("common.noData")}</span>;

        if (data !== undefined) {
            let profit = data.profitForBtc;
            let profitFormatted = formatBtcWithSign(profit);
            let profitPercentageFormatted = formatPercent(data.profitForBtcPercentage);

            if (!isBtcBased) {
                profit = data.profitForUsd;
                profitFormatted = formatUsdWithSign(profit);
                profitPercentageFormatted = formatPercent(data.profitForUsdPercentage);
            }

            let profitClassName = profit > 0 ? "profitText" : "lossText";

            totalProfitText = (
                <span className={profitClassName}>
                    {profitPercentageFormatted}
                    &nbsp;(
                    {profitFormatted})
                </span>
            );
        }

        return (
            <Card>
                <Card.Meta title={totalProfitText} description={t("portfolio.profit")} />
            </Card>
        );
    }

    profit24h() {
        const { data, isBtcBased, t } = this.props;

        let profit24hText = <span className="selectedColor">{t("common.noData")}</span>;

        if (data !== undefined) {
            let profit24h = data.profit24hForBtc;
            let profit24hFormatted = formatBtcWithSign(profit24h);
            let profit24hPercentageFormatted = formatPercent(data.profit24hForBtcPercentage);

            if (!isBtcBased) {
                profit24h = data.profit24hForUsd;
                profit24hFormatted = formatUsdWithSign(profit24h);
                profit24hPercentageFormatted = formatPercent(data.profit24hForUsdPercentage);
            }

            let profit24hClassName = profit24h > 0 ? "profitText" : "lossText";

            profit24hText = (
                <span className={profit24hClassName}>
                    {profit24hPercentageFormatted}
                    &nbsp;(
                    {profit24hFormatted})
                </span>
            );
        }

        return (
            <Card>
                <Card.Meta title={profit24hText} description={t("portfolio.dailyProfit")} />
            </Card>
        );
    }

    render() {
        return (
            <Row type="flex" justify="center" style={{ paddingBottom: "10px" }}>
                <Col>{this.estimatedValue()}</Col>
                <Col offset={1}>{this.totalProfit()}</Col>
                <Col offset={1}>{this.profit24h()}</Col>
            </Row>
        );
    }
}

export default withTranslation()(PortfolioHeader);
