import React, { PureComponent } from "react";
import Layout from "antd/lib/layout";

class CopyrightFooter extends PureComponent {
    render() {
        return (
            <Layout.Footer>
                <div className="centerAlignedLine paddingBottom30">
                    <a href="mailto:admin@cryptoace.io" target="_top">
                        <div className="roundWhiteContact">
                            <svg
                                id="email"
                                width="24px"
                                height="24px"
                                viewBox="0 0 128 128"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                            >
                                <circle fill="rgba(85, 96, 123, 0.70)" cx="64" cy="64" r="64" />
                                <path
                                    fill="#ffffff"
                                    d="M64,72.4l38.2-32.7c-0.6-0.4-1.4-0.7-2.2-0.7H28c-0.8,0-1.6,0.3-2.2,0.7L64,72.4z"
                                />
                                <path
                                    fill="#ffffff"
                                    d="M66.6,75.4c-1.5,1.3-3.7,1.3-5.2,0L24,43.5V85c0,2.2,1.8,4,4,4h72c2.2,0,4-1.8,4-4V43.4L66.6,75.4z"
                                />
                            </svg>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/cryptoace.stats" target="_blank" rel="noopener noreferrer">
                        <div className="roundWhiteContact">
                            <svg
                                id="facebook"
                                width="24px"
                                height="24px"
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                            >
                                <path
                                    fill="rgba(85, 96, 123, 0.70)"
                                    d="M47.761,24c0,13.121-10.638,23.76-23.758,23.76C10.877,47.76,0.239,37.121,0.239,24c0-13.124,10.638-23.76,23.764-23.76C37.123,0.24,47.761,10.876,47.761,24 M20.033,38.85H26.2V24.01h4.163l0.539-5.242H26.2v-3.083c0-1.156,0.769-1.427,1.308-1.427h3.318V9.168L26.258,9.15c-5.072,0-6.225,3.796-6.225,6.224v3.394H17.1v5.242h2.933V38.85z"
                                />
                            </svg>
                        </div>
                    </a>
                    <a href="https://twitter.com/cryptoace_stats" target="_blank" rel="noopener noreferrer">
                        <div className="roundWhiteContact">
                            <svg
                                id="twitter"
                                width="24px"
                                height="24px"
                                viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                            >
                                <path
                                    fill="rgba(85, 96, 123, 0.70)"
                                    d="M47.762,24c0,13.121-10.639,23.76-23.761,23.76S0.24,37.121,0.24,24c0-13.124,10.639-23.76,23.761-23.76 S47.762,10.876,47.762,24 M38.031,12.375c-1.177,0.7-2.481,1.208-3.87,1.481c-1.11-1.186-2.694-1.926-4.455-1.926 c-3.364,0-6.093,2.729-6.093,6.095c0,0.478,0.054,0.941,0.156,1.388c-5.063-0.255-9.554-2.68-12.559-6.367 c-0.524,0.898-0.825,1.947-0.825,3.064c0,2.113,1.076,3.978,2.711,5.07c-0.998-0.031-1.939-0.306-2.761-0.762v0.077 c0,2.951,2.1,5.414,4.889,5.975c-0.512,0.14-1.05,0.215-1.606,0.215c-0.393,0-0.775-0.039-1.146-0.109 c0.777,2.42,3.026,4.182,5.692,4.232c-2.086,1.634-4.712,2.607-7.567,2.607c-0.492,0-0.977-0.027-1.453-0.084 c2.696,1.729,5.899,2.736,9.34,2.736c11.209,0,17.337-9.283,17.337-17.337c0-0.263-0.004-0.527-0.017-0.789 c1.19-0.858,2.224-1.932,3.039-3.152c-1.091,0.485-2.266,0.811-3.498,0.958C36.609,14.994,37.576,13.8,38.031,12.375"
                                />
                            </svg>
                        </div>
                    </a>
                    <a href="https://vk.com/cryptoace" target="_blank" rel="noopener noreferrer">
                        <div className="roundWhiteContact">
                            <svg
                                id="vkontakte"
                                width="24px"
                                height="24px"
                                viewBox="0 0 96 96"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                            >
                                <circle fill="rgba(85, 96, 123, 0.70)" cx="48" cy="48" r="48" />
                                <path
                                    fill="#ffffff"
                                    transform="translate(-10, -10)"
                                    d="M53.979,80.702h4.403c0,0,1.33-0.146,2.009-0.878   c0.625-0.672,0.605-1.934,0.605-1.934s-0.086-5.908,2.656-6.778c2.703-0.857,6.174,5.71,9.853,8.235   c2.782,1.911,4.896,1.492,4.896,1.492l9.837-0.137c0,0,5.146-0.317,2.706-4.363c-0.2-0.331-1.421-2.993-7.314-8.463   c-6.168-5.725-5.342-4.799,2.088-14.702c4.525-6.031,6.334-9.713,5.769-11.29c-0.539-1.502-3.867-1.105-3.867-1.105l-11.076,0.069   c0,0-0.821-0.112-1.43,0.252c-0.595,0.357-0.978,1.189-0.978,1.189s-1.753,4.667-4.091,8.636c-4.932,8.375-6.904,8.817-7.71,8.297   c-1.875-1.212-1.407-4.869-1.407-7.467c0-8.116,1.231-11.5-2.397-12.376c-1.204-0.291-2.09-0.483-5.169-0.514   c-3.952-0.041-7.297,0.012-9.191,0.94c-1.26,0.617-2.232,1.992-1.64,2.071c0.732,0.098,2.39,0.447,3.269,1.644   c1.135,1.544,1.095,5.012,1.095,5.012s0.652,9.554-1.523,10.741c-1.493,0.814-3.541-0.848-7.938-8.446   c-2.253-3.892-3.954-8.194-3.954-8.194s-0.328-0.804-0.913-1.234c-0.71-0.521-1.702-0.687-1.702-0.687l-10.525,0.069   c0,0-1.58,0.044-2.16,0.731c-0.516,0.611-0.041,1.875-0.041,1.875s8.24,19.278,17.57,28.993   C44.264,81.287,53.979,80.702,53.979,80.702L53.979,80.702z"
                                />
                            </svg>
                        </div>
                    </a>
                    <a href="https://t.me/cryptoacestats" target="_blank" rel="noopener noreferrer">
                        <div className="roundWhiteContact">
                            <svg
                                id="telegram"
                                width="24px"
                                height="24px"
                                viewBox="0 0 60 60"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                            >
                                <path
                                    fill="rgba(85, 96, 123, 0.70)"
                                    d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zm16.9 13.9l-6.7 31.5c-.1.6-.8.9-1.4.6l-10.3-6.9-5.5 5.2c-.5.4-1.2.2-1.4-.4L18 32.7l-9.5-3.9c-.7-.3-.7-1.5 0-1.8l37.1-14.1c.7-.2 1.4.3 1.3 1z"
                                />
                                <path fill="rgba(85, 96, 123, 0.70)" d="M22.7 40.6l.6-5.8 16.8-16.3-20.2 13.3" />
                            </svg>
                        </div>
                    </a>
                </div>
                <div className="textAlignCenter">© 2018-2021 CryptoACE</div>
            </Layout.Footer>
        );
    }
}

export default CopyrightFooter;
